export const PROJECT_META_KEYS: { [key: string]: 'string' | 'number' | 'json' } = {
   description: 'string',
   // image: "string",
   capex: 'number',
   revenue: 'number',
   NPV: 'number',
   IRR: 'number',
   // cashflow: "string",
   // capital_allocation: "string",
   // capital_raised: "string",
   map_id: 'string',
   map_snapshots: 'json',
};

export const PROJECT_STATUS = {
   INIT: {
      value: 'INIT',
      label: 'Initialized',
   },
   PROGRESS: {
      value: 'PROGRESS',
      label: 'In Progress',
   },
   REVIEW: {
      value: 'REVIEW',
      label: 'In Review',
   },
   DONE: {
      value: 'DONE',
      label: 'Done',
   },
};

export const FEASIBILITY_STUDY_STATUS = {
   RUNNING: 'RUNNING',
   PUBLISHING: 'PUBLISHING',
   COMPLETED: 'COMPLETED',
   APPROVED: 'APPROVED',
   UNAPPROVED: 'UNAPPROVED',
   SERVER_FAILED: 'SERVER_FAILED',
   ERROR: 'ERROR',
};

export const CHART_TYPE: {
   [key: string]: {
      value: string;
      label: string;
   };
} = {
   PASSINGS_BY_HEXBIN_VALUE: {
      value: 'PASSINGS_BY_HEXBIN_VALUE',
      label: 'Passings by Hexbin Value',
   },
   PASSINGS_BY_COMPETITIVE_SCORE: {
      value: 'PASSINGS_BY_COMPETITIVE_SCORE',
      label: 'Passings by Competitive Score',
   },
   PASSINGS_BY_TAKE_RATE: {
      value: 'PASSINGS_BY_TAKE_RATE',
      label: 'Passings by Take Rate',
   },
   PASSINGS_BY_COST_PER_PASSING: {
      value: 'PASSINGS_BY_COST_PER_PASSING',
      label: 'Passings by Cost per Passing',
   },
   PASSINGS_BY_FEET_PER_PASSING: {
      value: 'PASSINGS_BY_FEET_PER_PASSING',
      label: 'Passings by Feet per Passing',
   },
   PASSINGS_BY_FTTC_MILE: {
      value: 'PASSINGS_BY_FTTC_MILE',
      label: 'Passings by FTTC Mile',
   },
   NET_VALUE_PER_AREA: {
      value: 'NET_VALUE_PER_AREA',
      label: 'Net Value per Area',
   },
   DISTRIBUTION_BY_HEXBIN_VALUE: {
      value: 'DISTRIBUTION_BY_HEXBIN_VALUE',
      label: 'Distribution by Hexbin Value',
   },
};
