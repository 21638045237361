import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, duration } from '@mui/material';
import { useEffect, useState } from 'react';
import { FoursquareToken } from '../../../../models/token.model';
import { saveFoursquareToken } from '../../../../services/token.service';

import { useSnackBar } from '../../../../contexts/SnackbarContext';

export function SaveFoursquareTokensForm(props: { open: boolean; setOpen: CallableFunction; token: FoursquareToken | null; setToken: React.Dispatch<React.SetStateAction<FoursquareToken | null>>; readonly?: boolean }) {
   const [foursquareTokenForm, setFoursquareTokenForm] = useState<FoursquareToken>({
      token: props.token?.token || '',
      refreshToken: props.token?.refreshToken || '',
   });

   const [refreshChanged, setRefreshChanged] = useState(false);

   const snackbar = useSnackBar();

   useEffect(() => {
      if (!props.token) return;
      setFoursquareTokenForm({
         token: props.token?.token || '',
         refreshToken: props.token?.refreshToken || '',
      });
   }, [props.token]);

   const handleClose = () => {
      props.setOpen(false);
   };

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!foursquareTokenForm?.refreshToken) return;

      var response = await saveFoursquareToken(foursquareTokenForm.refreshToken);
      if (!response.error) {
         props.setToken(response.token);
         props.setOpen(false);
         snackbar.success('Foursquare Token Saved');
      } else {
         snackbar.error(response.error);
         setFoursquareTokenForm({
            token: '',
            refreshToken: '',
         });
      }
   };

   return (
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="lg">
         <DialogTitle>My Foursquare Tokens</DialogTitle>
         <form onSubmit={handleSubmit}>
            <DialogContent sx={{ minWidth: '60vw', paddingTop: 0 }}>
               <div className="flex column gap">
                  <div className="mb-4">
                     <p>
                        Get your tokens from{' '}
                        {/* <a href="https://studio.foursquare.com/home/tokens" target="_blank"> */}
                        {/* studio.foursquare.com/home/tokens */}
                        {/* </a> */}
                        <a href="https://studio.foursquare.com/map/tokens.html" target="_blank">
                           studio.foursquare.com/map/tokens
                        </a>
                     </p>
                  </div>
                  {/* <Typography
                                variant="h4"
                                color="error"
                                sx={{ fontSize: "0.8rem" }}
                            >
                                <b>Important:</b> You only need to provide your <b>REFRESH TOKEN</b>.
                            </Typography> */}

                  <TextField
                     className="w-100"
                     label="Access Token"
                     type="text"
                     value={foursquareTokenForm.token?.length > 0 ? foursquareTokenForm.token : 'Not needed yet'}
                     variant="outlined"
                     onChange={(event) =>
                        setFoursquareTokenForm((prevState) => ({
                           ...prevState,
                           token: event.target.value,
                        }))
                     }
                     required={false}
                     disabled={true}
                     InputLabelProps={{
                        shrink: true,
                     }}
                  />

                  <TextField
                     className="w-100"
                     label="Refresh Token"
                     type="text"
                     value={foursquareTokenForm.refreshToken}
                     variant="outlined"
                     onChange={(event) => {
                        setRefreshChanged(true);
                        setFoursquareTokenForm((prevState) => ({
                           ...prevState,
                           refreshToken: event.target.value,
                        }));
                     }}
                     required={true}
                     // disabled={props.readonly}
                     InputLabelProps={{
                        shrink: true,
                     }}
                  />

                  <div className="flex column">
                     <Typography variant="h4" sx={{ fontSize: '0.8rem' }}>
                        {!props.readonly ? (
                           <>
                              You only need to provide your <b>REFRESH TOKEN</b>.
                           </>
                        ) : (
                           <>
                              You can update your <b>REFRESH TOKEN</b>.
                           </>
                        )}
                     </Typography>
                     <Typography variant="h4" color="error" sx={{ fontSize: '0.7rem' }}>
                        Make sure to never use the same <strong>REFRESH TOKEN</strong> twice.
                     </Typography>
                  </div>
               </div>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Cancel</Button>
               <Button type="submit" variant="contained" color="primary" disabled={!foursquareTokenForm?.refreshToken || props.token?.refreshToken === foursquareTokenForm?.refreshToken || foursquareTokenForm?.refreshToken.length < 10}>
                  Save
               </Button>
            </DialogActions>
         </form>
      </Dialog>
   );
}
