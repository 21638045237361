import { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as foursquare from '@foursquare/map-sdk';
import { FinancialCharts, FinancialModel, MarketAssumptionsModel, MarketProfileModel, Project, ScenarioComparison } from '../models/project.model';
import { getAllFinancialModelCharts, getMarketAssumptionsById, getMarketProfileById, getProject, getScenarioComparison, getScenarios } from '../services/project.service';
import { ProjectNav } from '../components/projects/ProjectNav';
import { Summary } from '../components/projects/dashboard/Summary';
import { Bottom } from '../components/Bottom';
import { ProfileMenu } from '../components/profile/ProfileMenu';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ProjectMap } from '../components/projects/ProjectMap';
import { FormControl, MenuItem, Select } from '@mui/material';
import { formatCategoryPath } from '../helpers/helper';

export function ProjectPage() {
   const navigate = useNavigate();
   const location = useLocation();
   const currentPath = location.pathname;
   const [project, setProject] = useState<Project>();
   const { id, scenarioId } = useParams();
   const [map, setMap] = useState<foursquare.MapApi | null>(null);
   const [scenarios, setScenarios] = useState<FinancialModel[] | null>(null);
   const [scenariosComparison, setScenariosComparison] = useState<ScenarioComparison[] | null>(null);
   const [currentScenario, setCurrentScenario] = useState<FinancialModel | null | undefined>(undefined);
   const [chartData, setChartData] = useState<FinancialCharts>({
      passingsByHexbinValue: [],
      passingsByCompetitiveScore: [],
      passingsByTakeRate: [],
      passingsByFTTCMile: [],
      passingsByCostPerPassing: [],
      passingsByFeetPerPassing: [],
      netValuePerArea: {
         perCustomer: [],
         total: [],
      },
      distributionByHexbinValue: [],
   });
   const [marketAssumptions, setMarketAssumptions] = useState<MarketAssumptionsModel | null>();
   const [marketProfile, setMarketProfile] = useState<MarketProfileModel | undefined>();
   const categoryPath = currentPath.split('/')[currentScenario !== undefined ? 5 : 3] || '';

   useEffect(() => {
      retrieveProject();
   }, []);

   useEffect(() => {
      if (!project || !scenarioId || !scenarios) return;
      const search = scenarios.find((scenario: any) => scenario.id === scenarioId);
      if (search) setCurrentScenario(search);
      else {
         setCurrentScenario(scenarios[0]);
         window.history.replaceState(null, '', `/project/${id}/scenario/${scenarios[0].id}`);
      }
   }, [scenarioId]);

   useEffect(() => {
      if (currentScenario === undefined) return;

      setMap(null);
      setChartData({
         passingsByHexbinValue: [],
         passingsByCompetitiveScore: [],
         passingsByTakeRate: [],
         passingsByFTTCMile: [],
         passingsByCostPerPassing: [],
         passingsByFeetPerPassing: [],
         netValuePerArea: {
            perCustomer: [],
            total: [],
         },
         distributionByHexbinValue: [],
      });
      setMarketAssumptions(null);
      setMarketProfile(undefined);

      retrieveMarketAssumptions();
      retrieveMarketProfile();
      retrieveChartData();
      retrieveScenariosComparison();
   }, [currentScenario]);

   useEffect(() => {
      if (!scenarios) return;

      if (!currentScenario && scenarios.length === 0) {
         setCurrentScenario(null);
         return;
      }

      if (!scenarioId) {
         setCurrentScenario(scenarios[0]);
         window.history.replaceState(null, '', `/project/${id}/scenario/${scenarios[0].id}`);
         return;
      }
      const search = scenarios.find((scenario: any) => scenario.id === scenarioId);
      if (search) setCurrentScenario(search);
      else {
         setCurrentScenario(scenarios[0]);
         window.history.replaceState(null, '', `/project/${id}/scenario/${scenarios[0].id}`);
      }
   }, [scenarios]);

   useEffect(() => {
      if (project?.name) document.title = 'Hexvarium | ' + project?.name ?? 'Project';
      if (project) {
         retrieveScenarios();
         retrieveScenariosComparison();
      }
   }, [project]);

   useEffect(() => {
      return () => {
         if (map) setMap(null);
      };
   }, [map]);

   const retrieveProject = async () => {
      if (!id || project?.id === id) return;
      try {
         const response = await getProject(id);
         if (!response.error) {
            setProject(response.project);
         }
      } catch (e) {
         return navigate('/');
      }
   };

   const retrieveChartData = async () => {
      if (!project) return;
      try {
         const response = await getAllFinancialModelCharts(project.id, currentScenario?.id);
         if (!response.error) {
            setChartData({
               passingsByHexbinValue: response.passingsByHexbinValue && response.passingsByHexbinValue.length > 0 ? response.passingsByHexbinValue : null,
               passingsByCompetitiveScore: response.passingsByCompetitiveScore && response.passingsByCompetitiveScore.length > 0 ? response.passingsByCompetitiveScore : null,
               netValuePerArea: response.netValuePerArea && response.netValuePerArea.perCustomer.length > 0 && response.netValuePerArea.total.length > 0 ? response.netValuePerArea : null,
               distributionByHexbinValue: response.distributionByHexbinValue && response.distributionByHexbinValue.length > 0 ? response.distributionByHexbinValue : null,
               passingsByTakeRate: response.passingsByTakeRate && response.passingsByTakeRate.length > 0 ? response.passingsByTakeRate : null,
               passingsByCostPerPassing: response.passingsByCostPerPassing && response.passingsByCostPerPassing.length > 0 ? response.passingsByCostPerPassing : null,
               passingsByFeetPerPassing: response.passingsByFeetPerPassing && response.passingsByFeetPerPassing.length > 0 ? response.passingsByFeetPerPassing : null,
               passingsByFTTCMile: response.passingsByFTTCMile && response.passingsByFTTCMile.length > 0 ? response.passingsByFTTCMile : null,
            });
         } else {
            setChartData({
               passingsByHexbinValue: null,
               passingsByCompetitiveScore: null,
               netValuePerArea: null,
               distributionByHexbinValue: null,
               passingsByTakeRate: null,
               passingsByCostPerPassing: null,
               passingsByFeetPerPassing: null,
               passingsByFTTCMile: null,
            });
         }
      } catch (error) {
         console.log(error);
      }
   };

   const retrieveScenarios = async () => {
      if (!id) {
         setCurrentScenario(null);
         return;
      }
      try {
         const response = await getScenarios(id);
         if (!response.error) {
            const localScenarios: FinancialModel[] = response.financialModels.map((scenario: FinancialModel, index: number) => {
               return {
                  ...scenario,
                  scenarioName: scenario.scenarioName ?? 'Scenario ' + scenario.modelId,
               } as FinancialModel;
            });
            setScenarios(localScenarios);
         }
      } catch (e) {
         return navigate('/');
      }
   };

   const retrieveMarketAssumptions = async () => {
      if (!currentScenario) return;
      try {
         const response = await getMarketAssumptionsById(currentScenario.id);
         if (!response.error) {
            setMarketAssumptions(response.marketAssumptions);
         }
      } catch (e) {}
   };

   const retrieveMarketProfile = async () => {
      if (!currentScenario) return;
      try {
         const response = await getMarketProfileById(currentScenario.id);
         if (!response.error) {
            setMarketProfile(response.marketProfile);
         }
      } catch (e) {}
   };

   const retrieveScenariosComparison = async () => {
      if (!currentScenario || scenariosComparison || !project) return;
      try {
         const response = await getScenarioComparison(project.id);
         if (!response.error) {
            setScenariosComparison(response.comparisonData);
         }
      } catch (e) {}
   };

   return (
      <div className="project-single h-100 flex items-start">
         {project && project?.id === id && (
            <>
               <ProjectNav project={project} scenarios={scenarios} scenario={currentScenario} />
               <div className="project-single-content h-100 w-100">
                  <div className="flex between column ">
                     <div className="flex between items-center mb-4">
                        <div className="flex items-center project-breadcrumbs gap-small">
                           <Link to="/">HexMAPP</Link>
                           <ArrowForwardIosIcon sx={{ fontSize: '13px' }} />
                           <Link to="/">{project.accountProjects[0].account?.name}</Link>
                           <ArrowForwardIosIcon sx={{ fontSize: '13px' }} />
                           <Link to={`/project/${project?.id}`}>{project?.name}</Link>
                           {currentScenario && (
                              <>
                                 <ArrowForwardIosIcon sx={{ fontSize: '13px' }} />
                                 <span>{currentScenario.scenarioName}</span>
                              </>
                           )}
                           {categoryPath && (
                              <>
                                 <ArrowForwardIosIcon sx={{ fontSize: '13px' }} />
                                 <span>{formatCategoryPath(categoryPath)}</span>
                              </>
                           )}
                        </div>
                        <div className="flex items-center end gap">
                           {scenarios && scenarios.length > 1 && currentScenario && (
                              <FormControl variant="standard" sx={{ minWidth: '150px' }}>
                                 <Select
                                    value={currentScenario.id}
                                    label="Account"
                                    onChange={(event) => {
                                       const selectedId = event.target.value;
                                       const selectedScenario = scenarios.find((s: any) => s.id === selectedId);
                                       if (!selectedScenario) return;
                                       setCurrentScenario(selectedScenario);

                                       if (location.pathname === `/project/${project?.id}/scenario/${currentScenario.id}/map` && selectedScenario.publishedMapId) {
                                          navigate(`/project/${project?.id}/scenario/${selectedScenario.id}/map`);
                                       } else {
                                          navigate(`/project/${project?.id}/scenario/${selectedScenario.id}`);
                                       }
                                    }}
                                    sx={{
                                       color: '#749074', // Change the text color when idle
                                       '&:before': {
                                          borderColor: '#749074', // Change the border color when idle
                                       },
                                       '&:hover:not(.Mui-disabled):before': {
                                          borderColor: '#FFFFFF', // Change the border color on hover
                                       },
                                       '&:after': {
                                          borderColor: '#FFFFFF', // Change the border color when focused
                                       },
                                       svg: {
                                          color: '#749074', // Change the icon color when idle
                                       },
                                    }}>
                                    {scenarios.map((scenario: any) => (
                                       <MenuItem className="selector-item" key={scenario.id} value={scenario.id}>
                                          {scenario.scenarioName}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </FormControl>
                           )}
                           <ProfileMenu />
                        </div>
                     </div>
                     <Routes>
                        <Route path="/" element={<Summary project={project} scenariosComparison={scenariosComparison} scenario={currentScenario} chartData={chartData} marketProfile={marketProfile} marketAssumptions={marketAssumptions} />} />
                        <Route path="/map" element={<ProjectMap project={project} map={map} mapId={currentScenario?.publishedMapId} setMap={setMap} />} />
                        <Route path="*" element={<Navigate to={`/project/${project.id}/`} />} />
                     </Routes>
                     <Bottom small />
                  </div>
               </div>
            </>
         )}
      </div>
   );
}
