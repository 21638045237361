import { CloseFullscreen } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip as TooltipMaterial } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import * as htmlToImage from 'html-to-image';
import { FinancialModel, Project } from '../../../../../models/project.model';
import { useEffect, useState } from 'react';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';

export function ChartEvents(props: {
   project: Project;
   financialModel?: FinancialModel;
   values: any[];
   chartType: {
      value: string;
      label: string;
   };
   isFullscreen: boolean;
   setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
   const [isDownloadingPNG, setIsDownloadingPNG] = useState(false);
   const snackbar = useSnackBar();

   useEffect(() => {
      const handleFullscreenChange = () => {
         props.setIsFullScreen(!!document.fullscreenElement);
      };

      document.addEventListener('fullscreenchange', handleFullscreenChange);

      return () => {
         document.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
   }, []);

   const handleFullscreen = () => {
      const chartContainer = document.getElementById(props.chartType.value);
      if (chartContainer) {
         if (!props.isFullscreen) {
            if (chartContainer.requestFullscreen) {
               chartContainer.requestFullscreen();
            }
         } else {
            if (document.exitFullscreen) {
               document.exitFullscreen();
            }
         }
         props.setIsFullScreen(!props.isFullscreen);
      }
   };

   const handleDownloadExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet([...props.values]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Chart Data');
      const excelBuffer = XLSX.write(workbook, {
         bookType: 'xlsx',
         type: 'array',
      });
      const data = new Blob([excelBuffer], {
         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(data, `${props.project.name} - Financial Model ${props.financialModel?.modelId} - ${props.chartType.label}.xlsx`);
   };

   const handleDownloadPNG = async () => {
      setIsDownloadingPNG(true);
      const container = document.getElementById(props.chartType.value);
      if (!container) return;
      const dataPdf = await htmlToImage.toPng(container);
      saveAs(dataPdf, `${props.project.name} - Financial Model ${props.financialModel?.modelId} - ${props.chartType.label}.png`);
      setIsDownloadingPNG(false);
   };

   const handleCopyPNG = async () => {
      setIsDownloadingPNG(true);
      const container = document.getElementById(props.chartType.value);
      if (!container) return;

      try {
         const dataUrl = await htmlToImage.toPng(container);
         const blob = await (await fetch(dataUrl)).blob();
         const items = [new ClipboardItem({ 'image/png': blob })];
         await navigator.clipboard.write(items);
         snackbar.success('Chart copied to clipboard');
      } catch (error) {
         console.error('Failed to copy image to clipboard:', error);
      }
      setIsDownloadingPNG(false);
   };

   return (
      <div className="flex items-center" style={{ opacity: !isDownloadingPNG ? 1 : 0 }}>
         <TooltipMaterial title={`Copy as PNG`}>
            <IconButton
               size="small"
               onClick={() => {
                  handleCopyPNG();
               }}
            >
               <ContentCopyIcon fontSize="medium" />
            </IconButton>
         </TooltipMaterial>
         <TooltipMaterial title={`Download chart data`}>
            <IconButton
               size="small"
               onClick={() => {
                  handleDownloadPNG();
               }}
            >
               <DownloadIcon fontSize="medium" />
            </IconButton>
         </TooltipMaterial>
         {!props.isFullscreen ? (
            <TooltipMaterial title={`Fullscreen`}>
               <IconButton
                  size="small"
                  onClick={() => {
                     handleFullscreen();
                  }}
               >
                  <OpenInFullIcon fontSize="medium" />
               </IconButton>
            </TooltipMaterial>
         ) : (
            <TooltipMaterial title={`Fullscreen`}>
               <IconButton
                  size="small"
                  onClick={() => {
                     handleFullscreen();
                  }}
               >
                  <CloseFullscreen fontSize="medium" />
               </IconButton>
            </TooltipMaterial>
         )}
      </div>
   );
}
