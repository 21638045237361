import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ConnectModal } from '../components/login/ConnectModal';
import { Bottom } from '../components/Bottom';

export function CreateAccount() {
   const { setAuthenticated } = useContext(AuthContext);

   useEffect(() => {
      setAuthenticated(false);
   }, []);

   return (
      <div>
         <ConnectModal open={true} signIn={false} />
         <Bottom small />
      </div>
   );
}
