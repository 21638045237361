import { Avatar, Tooltip } from '@mui/material';
import { stringAvatar } from '../../../helpers/helper';

export function AuthorAvatar(props: { firstname?: string; lastname?: string }) {
   if (!props?.firstname && !props?.lastname) {
      return (
         <Tooltip placement="left" title={'Hexvarium'}>
            <Avatar {...stringAvatar()}>H</Avatar>
         </Tooltip>
      );
   }

   const fullname = `${props.firstname} ${props.lastname}`;
   return (
      <Tooltip placement="left" title={fullname}>
         <Avatar {...stringAvatar(fullname)} />
      </Tooltip>
   );
}
