import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export function ConfirmationPopUp(props: { openMsg: boolean; setOpenMsg: CallableFunction; msg: string; title: string; handleConfirm: CallableFunction; warning: boolean }) {
   return (
      <Dialog open={props.openMsg}>
         <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.msg}</DialogContentText>
         </DialogContent>
         <DialogActions className="mb-2">
            <Button onClick={() => props.setOpenMsg(false)} variant="outlined" color="primary">
               Cancel
            </Button>
            <Button
               variant="contained"
               onClick={() => {
                  props.handleConfirm();
                  props.setOpenMsg(false);
               }}
               color={props.warning ? 'error' : 'primary'}
               autoFocus>
               Confirm
            </Button>
         </DialogActions>
      </Dialog>
   );
}
