import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { FinancialModel } from '../../../../../models/project.model';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { updateFinancialModel } from '../../../../../services/project.service';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export function FinancialModelDetails(props: { financialModel: FinancialModel; setFinancialModels: CallableFunction; open: boolean; setOpen: (open: boolean) => void }) {
   const { financialModel, open, setOpen } = props;
   const [form, setForm] = useState<{
      scenarioName: string;
      mapId: string;
      notes: string;
   }>({
      scenarioName: financialModel.scenarioName ?? 'Model ' + financialModel.modelId,
      mapId: financialModel.publishedMapId ?? '',
      notes: financialModel.notes ?? '',
   } as any);
   const snackbar = useSnackBar();
   const [hasChanged, setHasChanged] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [tabIndex, setTabIndex] = useState('1');

   useEffect(() => {
      setForm({
         scenarioName: financialModel.scenarioName ?? 'Model ' + financialModel.modelId,
         mapId: financialModel.publishedMapId ?? '',
         notes: financialModel.notes ?? '',
      } as any);
      setHasChanged(false);
   }, [props.financialModel]);

   const handleEditFinancialModel = async () => {
      try {
         setIsLoading(true);
         var response = await updateFinancialModel(props.financialModel.id, props.financialModel.status, form.scenarioName.length > 0 ? form.scenarioName : null, form.mapId.length > 0 ? form.mapId : null, form.notes.length > 0 ? form.notes : null);
         if (!response.error) {
            snackbar.success('Financial model updated');
            setIsLoading(false);
            setHasChanged(false);

            props.setFinancialModels((financialModels: FinancialModel[]) => {
               const index = financialModels.findIndex((financialModel: FinancialModel) => financialModel.id === props.financialModel.id);
               financialModels[index].scenarioName = form.scenarioName;
               financialModels[index].publishedMapId = form.mapId.length > 0 ? form.mapId : null;
               financialModels[index].notes = form.notes.length > 0 ? form.notes : null;
               return financialModels;
            });
            financialModel.scenarioName = form.scenarioName;
         } else {
            snackbar.error('Error updating the financial model');
         }
         setIsLoading(false);
      } catch (e) {}
   };

   return (
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg" scroll="paper">
         <DialogTitle>
            <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
               <CloseIcon />
            </IconButton>
         </DialogTitle>
         <DialogContent>
            <form className="mb-5" onChange={() => setHasChanged(true)}>
               <TableContainer>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell sx={{ minWidth: '300px' }}>
                              <div className="flex gap-small items-center">
                                 <Typography variant="h6">
                                    <div className="flex items-center gap-small">
                                       <TextField value={form.scenarioName} variant="standard" placeholder="Add a custom name" onChange={(event: any) => setForm({ ...form, scenarioName: event.target.value })} />
                                       {/* <IconButton onClick={handleEditName}>
                                       <SaveIcon />
                                    </IconButton> */}
                                    </div>
                                    {/* ) : (
                                    <div className="flex items-center gap-small">
                                       {financialModel.scenarioName !== undefined && financialModel?.scenarioName?.length > 0 ? financialModel.scenarioName : `Model ${financialModel.modelId}`}
                                       <IconButton
                                          onClick={() => {
                                             setNewName(financialModel.scenarioName ?? 'Model ' + financialModel.modelId);
                                             setIsEditingName(true);
                                          }}>
                                          <EditIcon />
                                       </IconButton>
                                    </div>
                                 )} */}
                                 </Typography>
                                 {/* {!isEditingName && financialModel.instanceId && (
                                 <Typography
                                    sx={{
                                       backgroundColor: 'grey',
                                       borderRadius: '20px',
                                       padding: '2px 8px',
                                       fontSize: '10px',
                                       color: 'white',
                                       height: 'fit-content',
                                    }}>
                                    INSTANCE {financialModel.instanceId}
                                 </Typography>
                              )} */}
                              </div>
                           </TableCell>
                           <TableCell>
                              <div className="flex end mt-3">
                                 <Button disabled={!hasChanged} variant="contained" onClick={handleEditFinancialModel}>
                                    {isLoading ? <CircularProgress color="secondary" size={20} /> : 'Save'}
                                 </Button>
                              </div>
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <TableRow>
                           <TableCell>Map ID</TableCell>
                           <TableCell>
                              <TextField value={form.mapId} variant="standard" placeholder="Add a map ID" onChange={(event: any) => setForm({ ...form, mapId: event.target.value })} />
                              {/* {financialModel.publishedMapId} */}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>Notes</TableCell>
                           <TableCell>
                              <TextField sx={{ width: form.notes.length > 20 ? '400px' : '100%' }} placeholder="Add a note" value={form.notes} variant="standard" multiline onChange={(event: any) => setForm({ ...form, notes: event.target.value })} />
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               </TableContainer>
            </form>
            {financialModel.inputs && (
               <TabContext value={tabIndex}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <TabList
                        onChange={(event: React.SyntheticEvent, newValue: string) => {
                           setTabIndex(newValue);
                        }}>
                        <Tab label="Revenue" value="1" />
                        <Tab label="Engineering / Construction" value="2" />
                        <Tab label="Customer" value="3" />
                        <Tab label="Financing" value="4" />
                     </TabList>
                  </Box>
                  <div className="pt-3 pr-1" style={{ height: '50vh', overflowY: 'auto' }}>
                     <TabPanel value="1">
                        <h4>Pricing / ARPU</h4>
                        <TableContainer>
                           <Table>
                              <TableBody>
                                 <TableRow>
                                    <TableCell>Inferior ARPU</TableCell>
                                    <TableCell>{financialModel.inputs.arpu?.inferior}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Value ARPU</TableCell>
                                    <TableCell>{financialModel.inputs.arpu?.value}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Direct ARPU</TableCell>
                                    <TableCell>{financialModel.inputs.arpu?.direct}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Superlative ARPU</TableCell>
                                    <TableCell>{financialModel.inputs.arpu?.superlative}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Gig +? (Y/N)</TableCell>
                                    <TableCell>{financialModel.inputs.arpu?.include_gigplus ? 'Y' : 'N'}</TableCell>
                                 </TableRow>
                                 {financialModel.inputs.arpu?.include_gigplus && (
                                    <TableRow>
                                       <TableCell>Gig + ARPU</TableCell>
                                       <TableCell>{financialModel.inputs.arpu?.gig}</TableCell>
                                    </TableRow>
                                 )}
                                 <TableRow>
                                    <TableCell>Business Multiplier</TableCell>
                                    <TableCell>{financialModel.inputs.arpu?.business_multiplier}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Revenue Lag (months)</TableCell>
                                    <TableCell>{financialModel.inputs.revenue_lag}</TableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </TabPanel>
                     <TabPanel value="2">
                        <h4>Build</h4>

                        <TableContainer>
                           <Table>
                              <TableBody>
                                 <TableRow>
                                    <TableCell>Aerial $/mile</TableCell>
                                    <TableCell>{financialModel.inputs.construction_cost_per_mile_aerial}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Underground $/mile</TableCell>
                                    <TableCell>{financialModel.inputs.construction_cost_per_mile_underground}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Aerial % of mile</TableCell>
                                    <TableCell>{financialModel.inputs.aerial_pct_fttc}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Contingency</TableCell>
                                    <TableCell>{financialModel.inputs.construction_contingency}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Baseline Drop Footage</TableCell>
                                    <TableCell>{financialModel.inputs.baseline_drop_footage}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Maintenance Capex</TableCell>
                                    <TableCell>{financialModel.inputs.maintenance_capex}</TableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </TabPanel>
                     <TabPanel value="3">
                        <TableContainer>
                           <Table>
                              <TableBody>
                                 <TableRow>
                                    <TableCell>Take Rate Res. Override</TableCell>
                                    <TableCell>{financialModel.inputs.take_rate?.residential_override}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Take Rate Bus. Override</TableCell>
                                    <TableCell>{financialModel.inputs.take_rate?.business_override}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Fiber Points</TableCell>
                                    <TableCell>{financialModel.inputs.take_rate?.fiber_points}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Period Points</TableCell>
                                    <TableCell>{financialModel.inputs.internet_penetration?.period_end}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Voluntary Move Own</TableCell>
                                    <TableCell>{financialModel.inputs.churn?.voluntary_move_own}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Voluntary Move Rent</TableCell>
                                    <TableCell>{financialModel.inputs.churn?.voluntary_move_rent}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Voluntary Nonmove Baseline</TableCell>
                                    <TableCell>{financialModel.inputs.churn?.voluntary_nonmove_baseline}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Involuntary Receiving SNAP</TableCell>
                                    <TableCell>{financialModel.inputs.churn?.involuntary_receiving_snap}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Construction Cost Subscriber Drop</TableCell>
                                    <TableCell>{financialModel.inputs.construction_cost_subscriber_drop_residential}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Construction Cost Subscriber ONT</TableCell>
                                    <TableCell>{financialModel.inputs.construction_cost_subscriber_ont_residential}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Construction Cost Subscriber Install</TableCell>
                                    <TableCell>{financialModel.inputs.construction_cost_subscriber_install_residential}</TableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </TabPanel>
                     <TabPanel value="4">
                        <TableContainer>
                           <Table>
                              <TableBody>
                                 <TableRow>
                                    <TableCell>Discount Rate</TableCell>
                                    <TableCell>{financialModel.inputs.discount_rate}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Tax Rate</TableCell>
                                    <TableCell>{financialModel.inputs.tax_rate}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>ARPU Growth Annual</TableCell>
                                    <TableCell>{financialModel.inputs.arpu_growth_annual}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Expense Growth Annual</TableCell>
                                    <TableCell>{financialModel.inputs.expense_growth_annual}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Depreciable Life Years</TableCell>
                                    <TableCell>{financialModel.inputs.depreciable_life_years}</TableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </TabPanel>
                  </div>
               </TabContext>
            )}
         </DialogContent>
      </Dialog>
   );
}
