import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Account, AccountProject } from '../../../../../models/account.model';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { PatchModel } from '../../../../../models/path.model';

export function RenderRemoveProject(props: {
   params: GridRenderCellParams<Account>;
   account: Account | undefined;
   updateAccountPatch: (params: PatchModel) => Promise<void>;
   setAccount: (value: React.SetStateAction<Account | undefined>) => void;
}) {
   return (
      <div className="flex gap-small">
         <IconButton
            aria-label="edit"
            component="label"
            color="warning"
            onClick={() => {
               if (!props.account) return;

               const newAccountProjects = props.account?.accountProjects?.filter((accountProject: AccountProject) => accountProject.projectId !== props.params.row.id);
               props.updateAccountPatch({
                  id: props.account?.id,
                  field: 'accountProjects',
                  value: newAccountProjects,
               });
               props.setAccount({
                  ...props.account,
                  accountProjects: newAccountProjects,
               });
            }}
         >
            <RemoveCircleIcon />
         </IconButton>
      </div>
   );
}
