import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { FeasibilityInstance } from '../../../../../models/project.model';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { updateFeasibilityInstance } from '../../../../../services/project.service';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';

export function FeasibilityInstanceDetails(props: { projectId: string; instance: FeasibilityInstance; setFeasibilityInstances: CallableFunction; open: boolean; setOpen: (open: boolean) => void }) {
   const { instance, open, setOpen } = props;
   const snackbar = useSnackBar();

   const [isLoading, setIsLoading] = useState(false);
   const [hasChanged, setHasChanged] = useState(false);
   const [form, setForm] = useState<{
      notes: string;
   }>({
      notes: props.instance.notes || '',
   });

   useEffect(() => {
      setForm({
         notes: props.instance.notes || '',
      });
   }, [props.instance]);

   const downloadGeojson = () => {
      const element = document.createElement('a');
      const file = new Blob([Buffer.from(instance.boundaryContent, 'base64')], {
         type: 'text/plain',
      });
      element.href = URL.createObjectURL(file);
      element.download = `${instance.boundaryName}.${instance.boundaryType}`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
   };

   const handleEditFeasibilityInstance = async () => {
      try {
         setIsLoading(true);
         var response = await updateFeasibilityInstance(props.projectId, props.instance.instanceId, props.instance.status, form.notes.length > 0 ? form.notes : null);
         if (!response.error) {
            snackbar.success('Financial model updated');
            setIsLoading(false);
            setHasChanged(false);

            props.setFeasibilityInstances((instances: FeasibilityInstance[]) => {
               const index = instances.findIndex((instance: FeasibilityInstance) => instance.instanceId === props.instance.instanceId);
               instances[index].notes = form.notes;
               return instances;
            });
         } else {
            snackbar.error('Error updating the financial model');
         }
         setIsLoading(false);
      } catch (e) {}
   };

   return (
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" scroll="paper">
         <DialogTitle>
            <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
               <CloseIcon />
            </IconButton>
         </DialogTitle>
         <DialogContent onChange={() => setHasChanged(true)}>
            <TableContainer>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>
                           <Typography variant="h6" gutterBottom>
                              Instance {instance.instanceId}
                           </Typography>
                        </TableCell>
                        <TableCell>
                           <div className="flex end ">
                              <Button disabled={!hasChanged} variant="contained" onClick={handleEditFeasibilityInstance}>
                                 {isLoading ? <CircularProgress color="secondary" size={20} /> : 'Save'}
                              </Button>
                           </div>
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     <TableRow>
                        <TableCell>Notes</TableCell>
                        <TableCell>
                           <TextField sx={{ width: form.notes.length > 20 ? '400px' : '100%' }} placeholder="Add a note" value={form.notes} variant="standard" multiline onChange={(event: any) => setForm({ ...form, notes: event.target.value })} />
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>H3 Resolution</TableCell>
                        <TableCell>{instance.configJSON.h3_resolution}</TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>Maximum length of drops</TableCell>
                        <TableCell>{instance.configJSON.max_drop_length_m}</TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>Maximum length of extended drop</TableCell>
                        <TableCell>{instance.configJSON.max_extended_drop_length_m ?? 0}</TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>BSL Data Source</TableCell>
                        <TableCell>{instance.configJSON.bsl_spec?.bsl_type}</TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>POI (business) data source</TableCell>
                        <TableCell>{instance.configJSON.poi_spec?.poi_type}</TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>Parcel and property assessment data source</TableCell>
                        <TableCell>{instance.configJSON.parcel_spec?.parcel_type}</TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>Map name</TableCell>
                        <TableCell>{instance.configJSON.map_name}</TableCell>
                     </TableRow>
                  </TableBody>

                  <TableHead>
                     <TableRow>
                        <TableCell>
                           <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                              Boundary Specification
                           </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell>{instance.configJSON.boundary_spec.state_code}</TableCell>
                     </TableRow>

                     <TableRow>
                        <TableCell>Boundary Type</TableCell>
                        <TableCell>{instance.configJSON.boundary_spec.boundary_type}</TableCell>
                     </TableRow>
                     {instance.configJSON.boundary_spec.boundary_type === 'county' && (
                        <TableRow>
                           <TableCell>County</TableCell>
                           <TableCell>{instance.configJSON.boundary_spec.county_name}</TableCell>
                        </TableRow>
                     )}
                     {instance.configJSON.boundary_spec.boundary_type === 'city' && (
                        <TableRow>
                           <TableCell>City</TableCell>
                           <TableCell>{instance.configJSON.boundary_spec.city_name}</TableCell>
                        </TableRow>
                     )}
                     {instance.configJSON.boundary_spec.boundary_type === 'custom' && (
                        <TableRow>
                           <TableCell>Boundary File</TableCell>
                           <TableCell>
                              <a className="flex items-center" color="primary" onClick={downloadGeojson}>
                                 boundary.{props.instance.boundaryType} <AttachFileIcon fontSize="small" />
                              </a>
                           </TableCell>
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         </DialogContent>
      </Dialog>
   );
}
