import { SimpleModal } from '../shared/ui/modals/SimpleModal';

export function MySettings(props: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
   return (
      <SimpleModal open={props.open} setOpen={props.setOpen}>
         <h2>Settings</h2>
         <div className="mt-4"></div>
      </SimpleModal>
   );
}
