import { Autocomplete, Button, Card, CardContent, CircularProgress, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import { FeasibilityInstance, FinancialModel, Project, ProjectMeta } from '../../../../../models/project.model';
import { Add, Check, Undo, Wysiwyg } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { RunFinancialModelForm } from './RunFinancialModelForm';
import { FEASIBILITY_STUDY_STATUS } from '../../../../../constants/project';
import { getFinancialModelById, putProject, runFinancialModel, updateFinancialModel } from '../../../../../services/project.service';
import { convertArrayToCSV, getStatusColor } from '../../../../../helpers/helper';
import { FinancialModelDetails } from './FinancialModelDetails';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import MapIcon from '@mui/icons-material/Map';
import { SaveFoursquareTokensForm } from '../SaveFoursquareTokensForm';
import { FoursquareToken } from '../../../../../models/token.model';
import WarningIcon from '@mui/icons-material/Warning';
import { AuthorAvatar } from '../../../../shared/ui/AuthorAvatar';
import { FinancialModelLogs } from './FinancialModelLogs';
import LaunchIcon from '@mui/icons-material/Launch';
import BarChartIcon from '@mui/icons-material/BarChart';
import { FinancialModelChartsModal } from './FinancialModelChartsModal';
import { MapDetailsModal } from './MapDetailsModal';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';

export function FinancialsModels(props: {
   parentHeight: number;
   project: Project;
   setProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
   feasibilityInstances: FeasibilityInstance[];
   selectedInstance: FeasibilityInstance | undefined;
   financialModels: FinancialModel[];
   setFinancialModels: CallableFunction;
   setSelectedInstance: CallableFunction;
   foursquareToken: FoursquareToken | null;
   setFoursquareToken: React.Dispatch<React.SetStateAction<FoursquareToken | null>>;
}) {
   const [openRunForm, setOpenRunForm] = useState(props.selectedInstance !== undefined);
   const [instanceIdFilter, setInstanceIdFilter] = useState<number>(0); // 0 means no filter
   const [selectedModel, setSelectedModel] = useState<FinancialModel | undefined>(undefined);

   const [configOpen, setConfigOpen] = useState(false);
   const [logsOpen, setLogsOpen] = useState(false);
   const [foursquareTokenOpen, setFoursquareTokenOpen] = useState(false);

   const [chartsOpen, setChartsOpen] = useState(false);
   const [mapDetailsOpen, setMapDetailsOpen] = useState(false);

   const [gridHeight, setGridHeight] = useState<number>(0);
   const snackbar = useSnackBar();

   const [loadingDownload, setLoadingDownload] = useState([] as string[]);

   useEffect(() => {
      function updateGridHeight() {
         setGridHeight(props.parentHeight - 53);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, [props.financialModels]);

   useEffect(() => {
      if (!openRunForm) props.setSelectedInstance(undefined);
   }, [openRunForm]);

   const runFormOnSubmit = async (data: FinancialModel) => {
      if (!props.selectedInstance) return;

      const financialModel = {
         ...data,
         projectId: props.project.id,
         instanceId: props.selectedInstance?.instanceId,
      };
      try {
         var response = await runFinancialModel(financialModel);
         if (!response.error) {
            snackbar.success('New Financial Model is running');
         } else {
            snackbar.error('Error running new Financial Model');
         }
      } catch (e) {
         console.log(e);
      }
   };

   const changeModelStatus = async (id: string, status: string) => {
      if (!props.project || id === undefined) return;

      try {
         var response = await updateFinancialModel(id, status, null, null, null);
         if (!response.error) {
            snackbar.success('Financial Model status updated');

            props.setFinancialModels((financialModels: FinancialModel[]) => {
               const index = financialModels.findIndex((financialModel: FinancialModel) => financialModel.id === id);
               financialModels[index].status = status;
               return financialModels;
            });

            if (response.newThumbnail) {
               props.setProject({ ...props.project, thumbnail: response.newThumbnail });
            }
         } else {
            snackbar.error('Error: ' + response.error);
         }
      } catch (e) {
         console.log(e);
      }
   };

   const downloadCSV = async (id: string, filename: string): Promise<void> => {
      setLoadingDownload([...loadingDownload, id]);
      try {
         const response = await getFinancialModelById(id);
         if (!response?.financialModel) {
            snackbar.error('Error downloading the CSV');
            return;
         }
         const csvString = convertArrayToCSV(response.financialModel.foursquare);

         const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
         saveAs(blob, filename);
      } catch (e) {}
      setLoadingDownload(loadingDownload.filter((element) => element !== id));
   };

   const showLogs = async (id: string) => {
      try {
         const response = await getFinancialModelById(id);
         if (!response?.financialModel) {
            snackbar.error('Error downloading the CSV');
            return;
         }
         setSelectedModel(response.financialModel);
         setLogsOpen(true);
      } catch (e) {}
   };

   return (
      <>
         <div
            style={{
               maxHeight: gridHeight,
               minHeight: gridHeight,
               height: gridHeight,
               overflowY: 'auto',
            }}>
            <div className="flex between items-center p-3 pb-0">
               <div className="flex gap items-center">
                  <Button variant="outlined" size="medium" color="primary" startIcon={<Add />} disabled={!props.foursquareToken} onClick={() => setOpenRunForm(true)}>
                     New Model
                  </Button>

                  {!props.foursquareToken ? (
                     <div className="flex items-center" style={{ color: '#ff8178' }}>
                        <WarningIcon />
                        <span style={{ marginLeft: '10px' }}>
                           <Link sx={{ color: '#ff8178' }} onClick={() => setFoursquareTokenOpen(true)}>
                              Add a Foursquare token
                           </Link>{' '}
                           in order to run a financial model
                        </span>
                     </div>
                  ) : (
                     <Link onClick={() => setFoursquareTokenOpen(true)}>My Foursquare tokens</Link>
                  )}
               </div>
               <Autocomplete
                  id="filter-instance"
                  options={props.financialModels.sort((a: FinancialModel, b: FinancialModel) => (b.instanceId || 0) - (a.instanceId || 0)).filter((model: FinancialModel, index: number, self: FinancialModel[]) => index === self.findIndex((m: FinancialModel) => m.instanceId === model.instanceId))}
                  getOptionLabel={(option) => option.instanceId?.toString() || ''}
                  filterOptions={(options, { inputValue }) => options.filter((option) => option.instanceId?.toString().includes(inputValue))}
                  renderInput={(params) => <TextField {...params} label="Filter by Instance ID" />}
                  style={{ marginRight: '10px', minWidth: '250px' }}
                  onChange={(event, value) => setInstanceIdFilter(value?.instanceId || 0)}
               />
            </div>
            <div className="instances p-3">
               {props.financialModels
                  .filter((filter: FinancialModel) => instanceIdFilter === 0 || instanceIdFilter === filter.instanceId)
                  .sort((a: FinancialModel, b: FinancialModel) => {
                     return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                  })
                  .map((element) => (
                     <Card
                        key={element.modelId}
                        className={`instance-card mb-4 ${[FEASIBILITY_STUDY_STATUS.ERROR, FEASIBILITY_STUDY_STATUS.UNAPPROVED].includes(element.status) ? 'hidden' : ''} ${
                           props.project.projectMeta.find((meta: ProjectMeta) => meta.key === 'map_id')?.value === element.publishedMapId ? 'published-map' : ''
                        } ${element.status === FEASIBILITY_STUDY_STATUS.APPROVED ? 'approved' : ''}`}
                        sx={{
                           borderColor: `${getStatusColor(element.status)}`,
                        }}>
                        <CardContent className="instance-card-content">
                           <div className="flex column between items-start">
                              <div className="flex between items-center w-100">
                                 <div className="flex gap-small items-center">
                                    <Typography color="primary" variant="h5" component="div">
                                       <Link
                                          className="flex items-center gap-small"
                                          underline="hover"
                                          onClick={() => {
                                             setSelectedModel(element);
                                             setConfigOpen(true);
                                          }}>
                                          <span>{element.scenarioName !== undefined && element?.scenarioName?.length > 0 ? element.scenarioName : `Scenario ${element.modelId}`}</span>
                                          <LaunchIcon fontSize="small" />
                                       </Link>
                                    </Typography>
                                    {element.instanceId && (
                                       <Typography
                                          sx={{
                                             backgroundColor: 'grey',
                                             borderRadius: '20px',
                                             padding: '2px 8px',
                                             fontSize: '10px',
                                             color: 'white',
                                          }}>
                                          DMA {element.instanceId}
                                       </Typography>
                                    )}
                                 </div>
                                 <AuthorAvatar firstname={element.user?.firstname} lastname={element.user?.lastname} />
                              </div>
                              <Typography sx={{ fontSize: '12px' }}>{`${new Date(element.createdAt).toLocaleDateString()} ${new Date(element.createdAt).toLocaleTimeString()}`}</Typography>

                              {element.publishedMapId && (
                                 <Typography
                                    sx={{
                                       fontSize: '12px',
                                       opacity: element.publishedMapId ? 1 : 0,
                                    }}>
                                    Map:
                                    <a
                                       href={`https://studio.foursquare.com/map/${element.publishedMapId}`}
                                       target="_blank"
                                       style={{
                                          textDecoration: 'none',
                                          color: 'inherit',
                                       }}>
                                       {element.publishedMapId}
                                    </a>
                                 </Typography>
                              )}

                              <div className="notes-container">
                                 {element?.notes?.length && element?.notes?.length > 0 ? (
                                    <Tooltip title={element?.notes?.length && element.notes?.length > 120 && element?.notes}>
                                       <div className={`notes`}>{element.notes.substring(0, 120) + (element.notes?.length > 120 ? '...' : '')}</div>
                                    </Tooltip>
                                 ) : (
                                    <Link
                                       className="flex items-center add-note"
                                       underline="hover"
                                       onClick={() => {
                                          setSelectedModel(element);
                                          setConfigOpen(true);
                                       }}>
                                       <span>Add a note</span>
                                    </Link>
                                 )}
                              </div>

                              <div className="mt-3 flex between items-center w-100">
                                 {/* {props.project.projectMeta.find((meta: ProjectMeta) => meta.key === 'map_id')?.value === element.publishedMapId ? (
                                    <div
                                       key={element.modelId}
                                       className="status"
                                       style={{
                                          color: getStatusColor('APPROVED'),
                                          borderColor: getStatusColor('APPROVED'),
                                       }}>
                                       PUBLISHED
                                    </div>
                                 ) : (
                                    <div
                                       key={element.modelId}
                                       className="status"
                                       style={{
                                          color: getStatusColor(element.status),
                                          borderColor: getStatusColor(element.status),
                                       }}>
                                       {element.status}
                                    </div>
                                 )} */}
                                 <div
                                    key={element.modelId}
                                    className="status"
                                    style={{
                                       color: getStatusColor(element.status),
                                       borderColor: getStatusColor(element.status),
                                    }}>
                                    {element.status}
                                 </div>
                                 <div className="flex end items-center w-100">
                                    {element.status !== FEASIBILITY_STUDY_STATUS.ERROR && (
                                       <Tooltip title="Charts">
                                          <IconButton
                                             disabled={element.status !== FEASIBILITY_STUDY_STATUS.COMPLETED && element.status !== FEASIBILITY_STUDY_STATUS.APPROVED && element.status !== FEASIBILITY_STUDY_STATUS.PUBLISHING}
                                             size="small"
                                             onClick={() => {
                                                setSelectedModel(element);
                                                setChartsOpen(true);
                                             }}>
                                             <BarChartIcon fontSize="medium" />
                                          </IconButton>
                                       </Tooltip>
                                    )}

                                    <Tooltip title="Show Logs">
                                       <IconButton
                                          size="small"
                                          onClick={() => {
                                             showLogs(element.id);
                                          }}>
                                          <Wysiwyg fontSize="medium" />
                                       </IconButton>
                                    </Tooltip>

                                    {loadingDownload.findIndex((e) => e === element.id) === -1 ? (
                                       <Tooltip title="Download Financial Output">
                                          <IconButton
                                             disabled={element.status !== FEASIBILITY_STUDY_STATUS.COMPLETED && element.status !== FEASIBILITY_STUDY_STATUS.APPROVED && element.status !== FEASIBILITY_STUDY_STATUS.PUBLISHING}
                                             size="small"
                                             onClick={() => {
                                                downloadCSV(element.id, `Output - ${props.project.name} - ${element.scenarioName ?? 'Scenario ' + element.modelId}`);
                                             }}>
                                             <DownloadIcon fontSize="medium" />
                                          </IconButton>
                                       </Tooltip>
                                    ) : (
                                       <CircularProgress className="px-2" size={18} />
                                    )}

                                    {element.publishedMapId && (
                                       <Tooltip title="Show Map/Datasets">
                                          <IconButton
                                             disabled={element.status !== FEASIBILITY_STUDY_STATUS.COMPLETED && element.status !== FEASIBILITY_STUDY_STATUS.APPROVED}
                                             size="small"
                                             onClick={() => {
                                                setSelectedModel(element);
                                                setMapDetailsOpen(true);
                                             }}>
                                             <MapIcon fontSize="medium" />
                                          </IconButton>
                                       </Tooltip>
                                    )}

                                    {element.status === FEASIBILITY_STUDY_STATUS.COMPLETED && props.project.projectMeta.find((meta: ProjectMeta) => meta.key === 'map_id')?.value !== element.publishedMapId && (
                                       <Tooltip title="Approve Scenario">
                                          <IconButton
                                             className=""
                                             size="small"
                                             onClick={() => {
                                                changeModelStatus(element.id, FEASIBILITY_STUDY_STATUS.APPROVED);
                                             }}>
                                             <Check fontSize="medium" />
                                          </IconButton>
                                       </Tooltip>
                                    )}

                                    {element.status === FEASIBILITY_STUDY_STATUS.APPROVED && (
                                       <Tooltip title="Undo">
                                          <IconButton
                                             className="ml-2"
                                             size="small"
                                             onClick={() => {
                                                changeModelStatus(element.id, FEASIBILITY_STUDY_STATUS.COMPLETED);
                                             }}>
                                             <Undo fontSize="small" />
                                          </IconButton>
                                       </Tooltip>
                                    )}
                                    {(element.status === FEASIBILITY_STUDY_STATUS.RUNNING || element.status === FEASIBILITY_STUDY_STATUS.PUBLISHING) && <CircularProgress className="ml-2" size={'18px'} color="primary" disableShrink />}
                                 </div>
                              </div>
                           </div>
                        </CardContent>
                     </Card>
                  ))}
            </div>
         </div>
         <RunFinancialModelForm
            open={openRunForm}
            setOpen={setOpenRunForm}
            approvedInstances={props.feasibilityInstances.filter((instance: FeasibilityInstance) => instance.status === FEASIBILITY_STUDY_STATUS.APPROVED)}
            selectedInstance={props.selectedInstance}
            setSelectedInstance={props.setSelectedInstance}
            onSubmit={runFormOnSubmit}
            project={props.project}
         />
         <SaveFoursquareTokensForm open={foursquareTokenOpen} setOpen={setFoursquareTokenOpen} token={props.foursquareToken} setToken={props.setFoursquareToken} readonly={props.foursquareToken !== null} />

         {selectedModel && (
            <>
               <FinancialModelDetails financialModel={selectedModel} setFinancialModels={props.setFinancialModels} open={configOpen} setOpen={setConfigOpen} />
               <FinancialModelLogs
                  instance={{
                     ...selectedModel,
                     logs: selectedModel.logs,
                  }}
                  open={logsOpen}
                  setOpen={setLogsOpen}
               />
               <FinancialModelChartsModal project={props.project} financialModel={selectedModel} open={chartsOpen} setOpen={setChartsOpen} />
               <MapDetailsModal financialModel={selectedModel} open={mapDetailsOpen} setOpen={setMapDetailsOpen} />
            </>
         )}
      </>
   );
}
