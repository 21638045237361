import { useEffect, useState } from 'react';
import { getAllFinancialModelCharts } from '../../../../../services/project.service';
import { FinancialModel, Project, FinancialCharts } from '../../../../../models/project.model';
import { CHART_TYPE } from '../../../../../constants/project';
import { ChartEvents } from '../charts/ChartEvents';
import { HistogramChart2 } from '../charts/HistogramChart2';
import { NetValuePerAreaChart2 } from '../charts/NetValuePerAreaChart2';
import { DistributionByHexbinValue2 } from '../charts/DistributionByHexbinValue2';

export function FinancialModelCharts(props: { project: Project; financialModel?: FinancialModel; setHideCharts?: (hideCharts: boolean) => void }) {
   const [chartData, setChartData] = useState<FinancialCharts>({
      passingsByHexbinValue: [],
      passingsByCompetitiveScore: [],
      passingsByTakeRate: [],
      passingsByCostPerPassing: [],
      passingsByFeetPerPassing: [],
      passingsByFTTCMile: [],
      netValuePerArea: {
         perCustomer: [],
         total: [],
      },
      distributionByHexbinValue: [],
   });
   const [isFullscreen, setIsFullscreen] = useState(false);
   const [chartContainerWidth, setChartContainerWidth] = useState<number>(0);

   useEffect(() => {
      function updateGridHeight() {
         const width = document.getElementById('charts-container')?.clientWidth;
         setChartContainerWidth(width ? width - 200 : 0);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, []);

   useEffect(() => {
      retrieveChartData();
   }, [props.project]);

   useEffect(() => {
      if (Object.values(chartData).every((chart) => chart === null)) {
         props.setHideCharts && props.setHideCharts(true);
      }
   }, [chartData]);

   const retrieveChartData = async () => {
      if (!props.project.id) return;
      try {
         const response = await getAllFinancialModelCharts(props.project.id, props.financialModel?.id ?? '');
         if (!response.error) {
            setChartData({
               passingsByHexbinValue: response.passingsByHexbinValue.length > 0 ? response.passingsByHexbinValue : null,
               passingsByCompetitiveScore: response.passingsByCompetitiveScore.length > 0 ? response.passingsByCompetitiveScore : null,
               netValuePerArea: response.netValuePerArea && response.netValuePerArea.perCustomer.length > 0 && response.netValuePerArea.total.length > 0 ? response.netValuePerArea : null,
               distributionByHexbinValue: response.distributionByHexbinValue.length > 0 ? response.distributionByHexbinValue : null,
               passingsByTakeRate: response.passingsByTakeRate.length > 0 ? response.passingsByTakeRate : null,
               passingsByCostPerPassing: response.passingsByCostPerPassing.length > 0 ? response.passingsByCostPerPassing : null,
               passingsByFeetPerPassing: response.passingsByFeetPerPassing.length > 0 ? response.passingsByFeetPerPassing : null,
               passingsByFTTCMile: response.passingsByFTTCMile.length > 0 ? response.passingsByFTTCMile : null,
            });
         } else {
            setChartData({
               passingsByHexbinValue: null,
               passingsByCompetitiveScore: null,
               netValuePerArea: null,
               distributionByHexbinValue: null,
               passingsByTakeRate: null,
               passingsByCostPerPassing: null,
               passingsByFeetPerPassing: null,
               passingsByFTTCMile: null,
            });
         }
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div id="charts-container" className="flex column gap">
         {chartData.passingsByHexbinValue !== null && (
            <div id={`${CHART_TYPE.PASSINGS_BY_HEXBIN_VALUE.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Passings by Hexbin Value (CLV)</h2>
                  <ChartEvents chartType={CHART_TYPE.PASSINGS_BY_HEXBIN_VALUE} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.passingsByHexbinValue} />
               </div>

               <div className="flex column pr-3">
                  <HistogramChart2 values={chartData.passingsByHexbinValue} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.passingsByCompetitiveScore !== null && (
            <div id={`${CHART_TYPE.PASSINGS_BY_COMPETITIVE_SCORE.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Passings by Competitive Score</h2>
                  <ChartEvents chartType={CHART_TYPE.PASSINGS_BY_COMPETITIVE_SCORE} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.passingsByCompetitiveScore} />
               </div>

               <div className="flex column pr-3">
                  <HistogramChart2 values={chartData.passingsByCompetitiveScore} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.passingsByTakeRate !== null && (
            <div id={`${CHART_TYPE.PASSINGS_BY_TAKE_RATE.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Passings by Take Rate</h2>
                  <ChartEvents chartType={CHART_TYPE.PASSINGS_BY_TAKE_RATE} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.passingsByTakeRate} />
               </div>

               <div className="flex column pr-3">
                  <HistogramChart2 values={chartData.passingsByTakeRate} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.passingsByCostPerPassing !== null && (
            <div id={`${CHART_TYPE.PASSINGS_BY_COST_PER_PASSING.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Passings by Cost per Passing</h2>
                  <ChartEvents chartType={CHART_TYPE.PASSINGS_BY_COST_PER_PASSING} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.passingsByCostPerPassing} />
               </div>

               <div className="flex column pr-3">
                  <HistogramChart2 values={chartData.passingsByCostPerPassing} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.passingsByFeetPerPassing !== null && (
            <div id={`${CHART_TYPE.PASSINGS_BY_FEET_PER_PASSING.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Passings by Feet per Passing</h2>
                  <ChartEvents chartType={CHART_TYPE.PASSINGS_BY_FEET_PER_PASSING} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.passingsByFeetPerPassing} />
               </div>

               <div className="flex column pr-3">
                  <HistogramChart2 values={chartData.passingsByFeetPerPassing} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.passingsByFTTCMile !== null && (
            <div id={`${CHART_TYPE.PASSINGS_BY_FTTC_MILE.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Passings by FTTC Mile</h2>
                  <ChartEvents chartType={CHART_TYPE.PASSINGS_BY_FTTC_MILE} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.passingsByFTTCMile} />
               </div>

               <div className="flex column pr-3">
                  <HistogramChart2 values={chartData.passingsByFTTCMile} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.netValuePerArea !== null && chartData.netValuePerArea?.total.length > 0 && (
            <div id={`${CHART_TYPE.NET_VALUE_PER_AREA.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Net Value per Area (Total)</h2>
                  <ChartEvents chartType={CHART_TYPE.NET_VALUE_PER_AREA} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.netValuePerArea.total} />
               </div>

               <div className="flex column pr-3">
                  <NetValuePerAreaChart2 values={chartData.netValuePerArea.total} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.netValuePerArea !== null && chartData.netValuePerArea?.perCustomer.length > 0 && (
            <div id={`${CHART_TYPE.NET_VALUE_PER_AREA.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Net Value per Area (Per Customer)</h2>
                  <ChartEvents chartType={CHART_TYPE.NET_VALUE_PER_AREA} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.netValuePerArea.perCustomer} />
               </div>

               <div className="flex column pr-3">
                  <NetValuePerAreaChart2 values={chartData.netValuePerArea.perCustomer} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} labeled={true} />
               </div>
            </div>
         )}

         {chartData.distributionByHexbinValue !== null && (
            <div id={`${CHART_TYPE.DISTRIBUTION_BY_HEXBIN_VALUE.value}`} className="chart-section">
               <div className="flex between items-center mb-3">
                  <h2 className="">Distribution by Hexbin Value</h2>
                  <ChartEvents chartType={CHART_TYPE.DISTRIBUTION_BY_HEXBIN_VALUE} project={props.project} financialModel={props.financialModel} isFullscreen={isFullscreen} setIsFullScreen={setIsFullscreen} values={chartData.distributionByHexbinValue} />
               </div>

               <div className="flex column pr-3">
                  <DistributionByHexbinValue2 values={chartData.distributionByHexbinValue} isFullscreen={isFullscreen} parentWidth={chartContainerWidth} />
               </div>
            </div>
         )}
      </div>
   );
}
