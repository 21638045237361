import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { FIConfig } from '../../../../../models/project.model';
import { useState } from 'react';
import { Clear, East, FileUpload } from '@mui/icons-material';
import { USStateCodes } from '../../../../../constants/feasibilityInstance';
import { Buffer } from 'buffer';
import JSZip from 'jszip';

export function RunFeasibilityInstanceForm(props: { open: boolean; setOpen: CallableFunction; onSubmit: CallableFunction; projectName: string }) {
   const [selectedFileLabel, setSelectedFileLabel] = useState('');
   const [selectedFile, setSelectedFile] = useState<any>(null);
   const [filesFromZip, setFilesFromZip] = useState<any>(null);
   const [selectedFileFromZip, setSelectedFileFromZip] = useState<any>(null);

   const [selectedFileLabelBsl, setSelectedFileLabelBsl] = useState('');
   const [selectedFileBsl, setSelectedFileBsl] = useState<any>(null);

   const defaultValues: FIConfig = {
      h3_resolution: 8,
      bsl_spec: {
         bsl_type: 'precisely_v1',
      },
      parcel_spec: {
         parcel_type: 'precisely_v0',
      },
      poi_spec: {
         poi_type: 'precisely_v0',
      },
      boundary_spec: {
         boundary_type: 'custom',
         state_code: '',
         city_name: undefined,
         county_name: undefined,
      },
      competition_spec: {
         competition_source: 'latest',
      },
      map_name: props.projectName,
      max_drop_length_m: 5000,
      max_extended_drop_length_m: 91.44,
   };
   const [config, setConfig] = useState<FIConfig>(defaultValues);

   const handleClose = () => {
      setConfig(defaultValues);
      setSelectedFileLabel('');
      setSelectedFile(null);
      setSelectedFileFromZip(null);
      setFilesFromZip(null);
      props.setOpen(false);
   };

   const handleSubmit = (e: any) => {
      e.preventDefault();

      const isCustomBoundary = config.boundary_spec.boundary_type === 'custom';
      const isZipFile = selectedFileLabel.split('.').pop() === 'zip';

      const isBslCustom = config.bsl_spec.bsl_type === 'custom';

      let boundaryPath = isCustomBoundary ? (isZipFile ? `${selectedFileFromZip}` : `boundary.${selectedFileLabel.split('.').pop()}`) : '';

      let bslPath = isBslCustom && selectedFileBsl ? `custom_bsl.${selectedFileLabelBsl.split('.').pop()}` : '';

      let configObj = null;

      if (isCustomBoundary) {
         configObj = {
            ...config,
            boundary_spec: {
               ...config.boundary_spec,
               boundary_path: boundaryPath,
            },
            bsl_spec: {
               ...config.bsl_spec,
            },
         };
      } else {
         configObj = {
            ...config,
            boundary_spec: {
               ...config.boundary_spec,
            },
            bsl_spec: {
               ...config.bsl_spec,
            },
         };
      }

      if (isBslCustom && selectedFileBsl) {
         configObj = {
            ...configObj,
            bsl_spec: {
               ...configObj.bsl_spec,
               bsl_path: bslPath,
            },
         };
      }

      props.onSubmit(configObj, isCustomBoundary ? selectedFileLabel.split('.').pop() : null, isCustomBoundary ? selectedFileLabel : null, isCustomBoundary ? selectedFile : null, isBslCustom ? selectedFileBsl : null, isBslCustom ? selectedFileLabelBsl?.split('.').pop() : null);
      handleClose();
   };

   const handleFileChange = async (event: any) => {
      if (event.target.files.length === 0) return;
      var file = event.target.files[0];
      setSelectedFileFromZip(null);
      setFilesFromZip(null);
      if (file) {
         const reader = new FileReader();
         reader.onload = async (e) => {
            try {
               setSelectedFile(Buffer.from(e.target?.result as ArrayBuffer).toString('base64')); //encoding to base64
               setSelectedFileLabel(event.target.files[0].name);

               if (event.target.files[0].name.split('.').pop() === 'zip') {
                  const zip = new JSZip();
                  const zipData = await zip.loadAsync(file);
                  const fileNames = Object.keys(zipData.files);
                  setFilesFromZip(fileNames);
               }
            } catch (error) {
               console.error('Error parsing JSON file:', error);
               setSelectedFileLabel('Error: wrong file format');
            }
         };
         reader.readAsArrayBuffer(file);
      }
   };

   const handleFileChangeBsl = async (event: any) => {
      if (event.target.files.length === 0) return;
      var file = event.target.files[0];
      if (file) {
         const reader = new FileReader();
         reader.onload = async (e) => {
            try {
               setSelectedFileBsl(Buffer.from(e.target?.result as ArrayBuffer).toString('base64')); //encoding to base64
               setSelectedFileLabelBsl(event.target.files[0].name);
            } catch (error) {
               console.error('Error parsing JSON file:', error);
               setSelectedFileLabelBsl('Error: wrong file format');
            }
         };
         reader.readAsArrayBuffer(file);
      }
   };

   return (
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="lg" sx={{ minWidth: '80vw' }}>
         <DialogTitle>New DMA Instance</DialogTitle>
         <form onSubmit={handleSubmit}>
            <DialogContent>
               <div className="flex column gap">
                  <div className="between flex gap">
                     <TextField
                        className="w-100"
                        label="max length of drops"
                        type="number"
                        variant="outlined"
                        value={config.max_drop_length_m}
                        onChange={(event: any) =>
                           setConfig({
                              ...config,
                              max_drop_length_m: Number(event.target.value),
                           })
                        }
                        error={config.max_drop_length_m === 0}
                        helperText={config.max_drop_length_m === 0 ? 'Please provide a valid number.' : ''}
                        required={true}
                        InputLabelProps={{
                           shrink: true,
                        }}
                     />
                     <TextField
                        className="w-100"
                        label="max length of an extended drop"
                        type="number"
                        placeholder="Optional"
                        variant="outlined"
                        value={config.max_extended_drop_length_m}
                        onChange={(event: any) =>
                           setConfig({
                              ...config,
                              max_extended_drop_length_m: Number(event.target.value),
                           })
                        }
                        error={(config.max_extended_drop_length_m as number) < 0}
                        helperText={config.max_extended_drop_length_m && config.max_extended_drop_length_m <= 0 ? 'Please provide a valid.' : ''}
                        InputLabelProps={{
                           shrink: true,
                        }}
                     />
                  </div>
                  <div className="between flex gap items-center">
                     <FormControl variant="outlined" className="w-100">
                        <InputLabel shrink htmlFor="bsl-data-source">
                           BSL Data Source
                        </InputLabel>
                        <Select
                           id="bsl-data-source"
                           value={config.bsl_spec.bsl_type}
                           onChange={(event) => {
                              setConfig({
                                 ...config,
                                 bsl_spec: {
                                    ...config.bsl_spec,
                                    bsl_type: event.target.value,
                                 },
                              });
                              setSelectedFileBsl(null);
                              setSelectedFileLabelBsl('');
                           }}
                           required
                           displayEmpty
                           label="BSL Data Source">
                           <MenuItem value="lightbox_v1">lightbox_v1</MenuItem>
                           <MenuItem value="precisely_v0">precisely_v0</MenuItem>
                           <MenuItem value="precisely_v1">precisely_v1</MenuItem>
                           <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                     </FormControl>
                     {config.bsl_spec.bsl_type === 'custom' && (
                        <div className="w-50 flex items-center gap">
                           <East />
                           <div className="flex column ">
                              <Tooltip title="Custom BSL format">
                                 <Button className="w-100" variant="contained" component="label" startIcon={<FileUpload style={{ color: 'white' }} />} color="primary">
                                    BSL FILE
                                    <input hidden accept="*/**" type="file" onChange={handleFileChangeBsl} />
                                 </Button>
                              </Tooltip>
                              <div className="flex center items-center column">
                                 <span
                                    className="flex items-center mt-2"
                                    style={{
                                       maxWidth: '200px',
                                       overflow: 'hidden',
                                       textOverflow: 'ellipsis',
                                       whiteSpace: 'nowrap',
                                    }}>
                                    {selectedFileBsl ? (
                                       <>
                                          <p>{selectedFileLabelBsl.length > 15 ? `${selectedFileLabelBsl.substring(0, 12)}...${selectedFileLabelBsl.substring(selectedFileLabelBsl.lastIndexOf('.'))}` : selectedFileLabelBsl}</p>
                                          <IconButton
                                             onClick={() => {
                                                setSelectedFileBsl(null);
                                                setSelectedFileLabelBsl('');
                                             }}>
                                             <Clear fontSize="small" />
                                          </IconButton>
                                       </>
                                    ) : (
                                       <p style={{ color: 'red' }}> {selectedFileLabelBsl.length > 10 ? `${selectedFileLabelBsl.substring(0, 5)}...${selectedFileLabelBsl.substring(selectedFileLabelBsl.lastIndexOf('.'))}` : selectedFileLabelBsl} </p>
                                    )}
                                 </span>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
                  <div className="between flex gap">
                     <FormControl variant="outlined" className="w-100">
                        <InputLabel id="parcel">Parcel and property assessment data source</InputLabel>
                        <Select
                           id="parcel-data-source"
                           value={config.parcel_spec.parcel_type}
                           onChange={(event) =>
                              setConfig({
                                 ...config,
                                 parcel_spec: {
                                    ...config.parcel_spec,
                                    parcel_type: event.target.value,
                                 },
                              })
                           }
                           required
                           displayEmpty
                           labelId="parcel"
                           label="Parcel and property assessment data source">
                           <MenuItem value="lightbox_v1">lightbox_v1</MenuItem>
                           <MenuItem value="precisely_v0">precisely_v0</MenuItem>
                        </Select>
                     </FormControl>
                  </div>
                  <div className="between flex gap">
                     <FormControl variant="outlined" className="w-100">
                        <InputLabel shrink htmlFor="poi-data-source">
                           POI (business) data source
                        </InputLabel>
                        <Select
                           id="poi-data-source"
                           value={config.poi_spec.poi_type}
                           onChange={(event) =>
                              setConfig({
                                 ...config,
                                 poi_spec: {
                                    ...config.poi_spec,
                                    poi_type: event.target.value,
                                 },
                              })
                           }
                           required
                           displayEmpty
                           label="POI (business) data source">
                           <MenuItem value="lightbox_v1">lightbox_v1</MenuItem>
                           <MenuItem value="precisely_v0">precisely_v0</MenuItem>
                        </Select>
                     </FormControl>
                     <FormControl sx={{ minWidth: 140 }}>
                        <InputLabel id="h3_resolution">H3 Resolution</InputLabel>
                        <Select
                           value={config.h3_resolution}
                           required
                           labelId="h3_resolution"
                           label="h3_resolution"
                           onChange={(event: any) => {
                              setConfig({
                                 ...config,
                                 h3_resolution: event.target.value,
                              });
                           }}>
                           <MenuItem value={7}>7</MenuItem>
                           <MenuItem value={8}>8</MenuItem>
                           <MenuItem value={9}>9</MenuItem>
                        </Select>
                     </FormControl>
                     <TextField
                        className="w-100"
                        label="Map name"
                        type="text"
                        value={config.map_name}
                        variant="outlined"
                        onChange={(event: any) =>
                           setConfig({
                              ...config,
                              map_name: event.target.value,
                           })
                        }
                        error={config.map_name === ''}
                        helperText={config.map_name === '' ? 'Please provide a valid name.' : ''}
                        required={true}
                        InputLabelProps={{
                           shrink: true,
                        }}
                     />
                  </div>
                  <div className="between flex gap">
                     <FormControl variant="outlined" className="w-100">
                        <InputLabel id="parcel">Competitive Data Source</InputLabel>
                        <Select
                           id="competition-source"
                           value={config.competition_spec.competition_source}
                           onChange={(event) =>
                              setConfig({
                                 ...config,
                                 competition_spec: {
                                    ...config.competition_spec,
                                    competition_source: event.target.value,
                                 },
                              })
                           }
                           required
                           displayEmpty
                           labelId="parcel"
                           label="Competitive Data Source">
                           <MenuItem value="latest">latest</MenuItem>
                           <MenuItem value="HexCS 477 v0.0.2021_06">HexCS 477 v0.0.2021_06</MenuItem>
                           <MenuItem value="HexCS BDC v1.0.2022_12">HexCS BDC v1.0.2022_12</MenuItem>
                           <MenuItem value="HexCS BDC v1.0.2023_06">HexCS BDC v1.0.2023_06</MenuItem>
                           <MenuItem value="477">477 (deprecated legacy value)</MenuItem>
                           <MenuItem value="bdc">bdc (deprecated legacy value)</MenuItem>
                        </Select>
                     </FormControl>
                  </div>
                  <Divider>Boundary specs</Divider>
                  <div className="between flex gap items-center">
                     <FormControl style={{ width: '100%', minWidth: '20%' }}>
                        <InputLabel id="state-code-select-label">State</InputLabel>
                        <Select
                           required
                           labelId="state-code-select-label"
                           id="state-code-select"
                           value={config.boundary_spec.state_code}
                           label="State"
                           onChange={(event: any) =>
                              setConfig({
                                 ...config,
                                 boundary_spec: {
                                    ...config.boundary_spec,
                                    state_code: event.target.value,
                                 },
                              })
                           }>
                           {USStateCodes.map((stateCode) => (
                              <MenuItem key={stateCode} value={stateCode}>
                                 {stateCode}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <FormControl sx={{ width: '100%', minWidth: '20%' }}>
                        <InputLabel id="boundary_type">Boundary type</InputLabel>
                        <Select
                           value={config.boundary_spec.boundary_type}
                           required
                           labelId="boundary_type"
                           label="boundary_type"
                           onChange={(event: any) =>
                              setConfig({
                                 ...config,
                                 boundary_spec: {
                                    ...config.boundary_spec,
                                    boundary_type: event.target.value,
                                 },
                              })
                           }>
                           <MenuItem value={'city'}>City</MenuItem>
                           <MenuItem value={'county'}>County</MenuItem>
                           <MenuItem value={'state'}>State</MenuItem>
                           <MenuItem value={'custom'}>Custom</MenuItem>
                        </Select>
                     </FormControl>
                     {config.boundary_spec.boundary_type !== 'state' && <East />}
                     {config.boundary_spec.boundary_type === 'city' && (
                        <TextField
                           className="w-100"
                           label="City"
                           type="text"
                           sx={{ maxWidth: '20%' }}
                           value={config.boundary_spec.city_name}
                           variant="outlined"
                           onChange={(event: any) =>
                              setConfig({
                                 ...config,
                                 boundary_spec: {
                                    ...config.boundary_spec,
                                    city_name: event.target.value,
                                 },
                              })
                           }
                           error={config.boundary_spec.city_name === ''}
                           required={true}
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />
                     )}
                     {config.boundary_spec.boundary_type === 'county' && (
                        <TextField
                           className="w-100"
                           label="County"
                           type="text"
                           value={config.boundary_spec.county_name}
                           variant="outlined"
                           onChange={(event: any) =>
                              setConfig({
                                 ...config,
                                 boundary_spec: {
                                    ...config.boundary_spec,
                                    county_name: event.target.value,
                                 },
                              })
                           }
                           error={config.boundary_spec.county_name === ''}
                           required={true}
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />
                     )}
                     {config.boundary_spec.boundary_type === 'custom' && (
                        <div className="flex column ">
                           <Tooltip title="GeoJSON, GeoPackage (gpkg), FlatGeobuf (fgb), ESRI Shapefile (shp), etc.">
                              <Button className="w-100" variant="contained" component="label" startIcon={<FileUpload style={{ color: 'white' }} />} color="primary">
                                 Boundary
                                 <input hidden accept="*/**" type="file" onChange={handleFileChange} />
                              </Button>
                           </Tooltip>
                           <div className="flex center items-center column">
                              <span
                                 className="flex items-center mt-2"
                                 style={{
                                    maxWidth: '200px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                 }}>
                                 {selectedFile ? (
                                    <>
                                       <p>{selectedFileLabel.length > 15 ? `${selectedFileLabel.substring(0, 12)}...${selectedFileLabel.substring(selectedFileLabel.lastIndexOf('.'))}` : selectedFileLabel}</p>
                                       <IconButton
                                          onClick={() => {
                                             setSelectedFile(null);
                                             setSelectedFileLabel('');
                                             setSelectedFileFromZip(null);
                                             setFilesFromZip(null);
                                          }}>
                                          <Clear fontSize="small" />
                                       </IconButton>
                                    </>
                                 ) : (
                                    <p style={{ color: 'red' }}> {selectedFileLabel.length > 10 ? `${selectedFileLabel.substring(0, 5)}...${selectedFileLabel.substring(selectedFileLabel.lastIndexOf('.'))}` : selectedFileLabel} </p>
                                 )}
                              </span>
                           </div>
                        </div>
                     )}
                  </div>
                  {filesFromZip !== null && filesFromZip.length > 0 && (
                     <div className="flex column">
                        <FormControl sx={{ minWidth: 140, width: '100%' }}>
                           <InputLabel id="main_file" sx={{ backgroundColor: 'white' }}>
                              Main file of the zip
                           </InputLabel>
                           <Select value={selectedFileFromZip} required labelId="main_file" label="main_file" onChange={(event: any) => setSelectedFileFromZip(event.target.value)}>
                              {filesFromZip.map((file: any, index: number) => (
                                 <MenuItem key={index} value={file}>
                                    {file}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                     </div>
                  )}
               </div>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Cancel</Button>
               <Button
                  disabled={
                     config.max_drop_length_m < 0 ||
                     config.max_extended_drop_length_m < 0 ||
                     config.map_name === '' ||
                     config.map_name === undefined ||
                     config.boundary_spec.state_code === '' ||
                     config.boundary_spec.state_code === undefined ||
                     (config.boundary_spec.boundary_type === 'custom' && ((selectedFileLabel.split('.').pop() !== 'zip' && !selectedFile) || (selectedFileLabel.split('.').pop() === 'zip' && !selectedFileFromZip))) ||
                     (config.boundary_spec.boundary_type === 'city' && (config.boundary_spec.city_name === '' || config.boundary_spec.city_name === undefined)) ||
                     (config.boundary_spec.boundary_type === 'county' && (config.boundary_spec.county_name === '' || config.boundary_spec.county_name === undefined)) ||
                     (config.bsl_spec.bsl_type === 'custom' && !selectedFileBsl)
                  }
                  type="submit"
                  variant="contained"
                  color="primary">
                  Run
               </Button>
            </DialogActions>
         </form>
      </Dialog>
   );
}
