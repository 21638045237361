import { sendForgotPwdEmail } from '../../services/auth.service';
import { Alert, Button, TextField } from '@mui/material';
import { useState } from 'react';

import { isEmail } from '../../helpers/helper';
import { Link, useNavigate } from 'react-router-dom';
import { SimpleModal } from '../shared/ui/modals/SimpleModal';
import { useSnackBar } from '../../contexts/SnackbarContext';
import { Bottom } from '../Bottom';

export function PasswordReset() {
   const [forgotPwdform, setForgotPwdform] = useState({ email: '' });
   const [topMessage, setTopMessage] = useState({
      error: false,
      message: null,
   } as any);
   const snackbar = useSnackBar();
   const navigate = useNavigate();

   const forgotMyPwd = async (e: any) => {
      e.preventDefault();
      try {
         const response = await sendForgotPwdEmail(forgotPwdform.email);
         if (response.status == 200) {
            setForgotPwdform({ ...forgotPwdform, email: '' });
            snackbar.info('Follow the link in the email that we have sent you to reset your password');
            navigate('/login');
         } else {
            setTopMessage({ error: true, message: response.data.error });
         }
      } catch (error) {
         setTopMessage({ error: true, message: e.toString() });
      }
   };

   return (
      <SimpleModal open={true}>
         {topMessage.message !== null && (
            <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error' : 'success'}>
               {topMessage.message}
            </Alert>
         )}
         <form onSubmit={forgotMyPwd}>
            <h2>Retrieve your account</h2>
            <div className="flex column mt-4">
               <TextField
                  label="Email"
                  type={'email'}
                  variant="outlined"
                  value={forgotPwdform.email}
                  onChange={(event: any) =>
                     setForgotPwdform({
                        ...forgotPwdform,
                        email: event.target.value,
                     })
                  }
                  error={!isEmail(forgotPwdform.email) && forgotPwdform.email?.length > 0}
                  helperText={!isEmail(forgotPwdform.email) && forgotPwdform.email?.length > 0 ? 'Please provide a valid email address.' : ''}
                  required={true}
               />

               <Button className="mt-4" variant="contained" type="submit">
                  RESET
               </Button>
               <div className="flex center">
                  <Link className="flex items-center mt-4 small-link" to="/login">
                     {' '}
                     Sign In
                  </Link>
               </div>
            </div>
         </form>
         <Bottom small />
      </SimpleModal>
   );
}
