import { Autocomplete, Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography, Tab, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { getAccount, putAccount, patchAccount } from '../../../../services/account.service';
import { Account, AccountProject } from '../../../../models/account.model';
import { DataGridPro, GridCellEditCommitParams, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import TimeAgo from 'timeago-react';
import { PatchModel } from '../../../../models/path.model';
import { getProjects, patchProject } from '../../../../services/project.service';
import { SearchModel } from '../../../../models/search.model';
import { Project } from '../../../../models/project.model';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { StatusEdit } from '../../../shared/ui/grid/StatusEdit';
import { RenderStatus } from '../../../shared/ui/grid/RenderStatus';
import { RenderRemoveProject } from './grid/RenderRemoveProject';
import { useSnackBar } from '../../../../contexts/SnackbarContext';

export function AccountSingle() {
   const { id } = useParams();
   const [account, setAccount] = useState<Account>();
   const [loading, setLoading] = useState<boolean>(false);
   const [hasChanged, setHasChanged] = useState<boolean>(false);
   const [rowsPerPage, setRowsPerPage] = useState<number>(10);
   const [searchObj, setSearchObj] = useState<SearchModel>({
      page: 0,
      rowsPerPage: 100,
      search: '',
      orderBy: 'sort',
      asc: true,
   });
   const [projects, setProjects] = useState<Project[]>([]);
   const [selectedProject, setSelectedProject] = useState<Project | null>();
   const [editCellObj, setEditCellObj] = useState<string>();
   const [tabIndex, setTabIndex] = useState('1');
   const navigate = useNavigate();
   const snackbar = useSnackBar();

   const [gridHeight, setGridHeight] = useState<number>(0);
   const [gridHeight2, setGridHeight2] = useState<number>(0);

   useEffect(() => {
      function updateGridHeight() {
         const topHeight = document.getElementById('admin-top')?.clientHeight;
         setGridHeight(window.innerHeight - (topHeight ?? 0));
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, [id]);

   useEffect(() => {
      const topHeight = document.getElementById('admin-top-2')?.clientHeight;
      setGridHeight2(gridHeight - (topHeight ?? 0) - 50);
   }, [gridHeight, tabIndex]);

   useEffect(() => {
      retrieveAccount();
      retrieveProjects();
   }, []);

   async function retrieveProjects() {
      try {
         const responseProjects = await getProjects(searchObj, true, []);
         if (!responseProjects.error) {
            setProjects(responseProjects.projects);
         } else {
         }
      } catch (e) {
         setLoading(false);
      }
   }

   async function retrieveAccount() {
      if (!id) return;
      setLoading(true);
      try {
         var response = await getAccount(id);

         if (!response.error) {
            setAccount(response.account);
            setLoading(false);
         } else {
         }
         setLoading(false);
      } catch (e) {
         setLoading(false);
      }
   }

   async function updateAccount(e: any) {
      e?.preventDefault();

      if (!account) return;

      setLoading(true);
      try {
         const response = await putAccount(account);

         if (!response.error) {
            snackbar.success('Account successfully updated.');
            setAccount({
               ...account,
               updatedAt: new Date(),
            });
            setHasChanged(false);
         } else {
            snackbar.error('Error while updating the account.');
         }
         setLoading(false);
      } catch (e) {
         setLoading(false);
      }
   }

   async function updateAccountPatch(params: PatchModel) {
      try {
         const response = await patchAccount(params);
         if (!response.error) {
            snackbar.success('Account successfully updated.');
         } else {
            snackbar.error('Error while updating the account.');
         }
      } catch (e) {
         console.log(e);
      }
   }

   const columns: GridColDef[] = [
      {
         field: 'remove',
         headerName: '',
         editable: false,
         sortable: false,
         filterable: false,
         disableColumnMenu: true,
         renderCell: (params: any) => <RenderRemoveProject params={params} account={account} setAccount={setAccount} updateAccountPatch={updateAccountPatch} />,
      },
      {
         field: 'sort',
         headerName: 'Sort',
         editable: true,
         filterable: true,
      },
      {
         field: 'name',
         headerName: 'Name',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'isActive',
         headerName: 'Status',
         editable: true,
         filterable: false,
         renderEditCell: (params: any) => <StatusEdit {...params} />,
         renderCell: (params: any) => <RenderStatus {...params} />,
      },
      {
         field: 'createdAt',
         headerName: 'Creation',
         editable: false,
         filterable: false,
         flex: 1,
         renderCell: (params: any) => <TimeAgo datetime={new Date(params.row.createdAt.toString())} />,
      },
      {
         field: 'edit',
         headerName: '',
         editable: false,
         sortable: false,
         filterable: false,
         disableColumnMenu: true,
         renderCell: (params: any) => {
            return (
               <div className="flex gap-small">
                  <IconButton aria-label="edit" component="label" onClick={() => navigate(`/admin/projects/${params.row.id}`)}>
                     <EditIcon />
                  </IconButton>
               </div>
            );
         },
      },
   ];

   async function updateProject(params: GridCellEditCommitParams) {
      if (JSON.stringify(params.value) === editCellObj) return;
      try {
         const response = await patchProject(params as PatchModel);
         if (!response.error) {
            snackbar.success('Project successfully updated.');
         } else {
            snackbar.error('Error while updating the project.');
         }
      } catch (e) {
         console.log(e);
      }
   }

   return (
      <div>
         <div id="admin-top" className="admin-top">
            <Button onClick={() => navigate('/admin/accounts')} size="medium" variant="text" startIcon={<KeyboardBackspaceIcon />}>
               Accounts
            </Button>
            <div className="flex items-center">
               <div className="flex column mt-2">
                  <Typography
                     className="ml-3"
                     sx={{
                        lineHeight: '35px',
                     }}
                     variant="h6"
                     color="primary">
                     {account?.id !== undefined ? account?.name : 'Accounts'}
                  </Typography>
                  <Divider />
                  {account?.id !== undefined && (
                     <Typography color="primary">
                        <div className="ml-3 mt-2 gap-10 flex column">
                           <p>{account.id}</p>
                           <p>Creation: {new Date(account.createdAt?.toString() ?? '').toLocaleString() ?? 'null'}</p>
                           <p>Last Modification: {new Date(account.updatedAt?.toString() ?? '').toLocaleString() ?? 'null'}</p>
                        </div>
                     </Typography>
                  )}
               </div>
            </div>
         </div>
         {account?.id !== undefined && (
            <div
               className="flex gap-small between"
               style={{
                  height: gridHeight,
               }}>
               <Box
                  sx={{
                     border: 1,
                     borderColor: 'divider',
                     width: '100%',
                  }}>
                  <TabContext value={tabIndex}>
                     <Box
                        sx={{
                           borderBottom: 1,
                           borderColor: 'divider',
                        }}>
                        <TabList
                           onChange={(event: React.SyntheticEvent, newValue: string) => {
                              setTabIndex(newValue);
                           }}>
                           <Tab label="Info" value="1" />
                           <Tab label="Shared Projects" value="2" />
                        </TabList>
                     </Box>
                     <div
                        className="p-3"
                        style={{
                           height: gridHeight - 100,
                        }}>
                        <TabPanel value="1">
                           <form className="w-30" onSubmit={updateAccount} onChange={() => setHasChanged(true)}>
                              <div className="flex column w-100">
                                 <TextField
                                    label="Name"
                                    type={'text'}
                                    variant="outlined"
                                    value={account.name}
                                    onChange={(event: any) =>
                                       setAccount({
                                          ...account,
                                          name: event.target.value,
                                       })
                                    }
                                    error={account?.name?.length === 0}
                                    helperText={account?.name?.length === 0 ? 'Please provide a valid account name.' : ''}
                                    required={true}
                                 />
                                 <FormControl fullWidth className="mt-3 mr-1 w-100">
                                    <InputLabel id="roles-label">Status</InputLabel>
                                    <Select
                                       labelId="roles-label"
                                       value={account.isActive ? 'true' : 'false'}
                                       label="Status"
                                       onChange={(event: any, val: any) => {
                                          setHasChanged(true);
                                          setAccount({
                                             ...account,
                                             isActive: val.props.value == 'true',
                                          });
                                       }}>
                                       <MenuItem value={'true'}>Active</MenuItem>
                                       <MenuItem value={'false'}>Inactive</MenuItem>
                                    </Select>
                                 </FormControl>
                              </div>
                              <Button className="mt-4" variant="contained" type="submit" disabled={!hasChanged}>
                                 SAVE
                              </Button>
                           </form>
                        </TabPanel>
                        <TabPanel
                           value="2"
                           sx={{
                              padding: 0,
                           }}>
                           <div id="admin-top-2" className="flex start gap-small items-center p-4">
                              <Autocomplete
                                 disablePortal
                                 options={projects.filter((project: Project) => !account.accountProjects.map((accountProject: AccountProject) => accountProject.project?.id).includes(project.id))}
                                 sx={{
                                    width: 300,
                                 }}
                                 renderInput={(params: any) => <TextField {...params} label="Add Project" />}
                                 getOptionLabel={(option) => option.name}
                                 inputValue={selectedProject?.name ?? ''}
                                 onChange={(e: any, v: Project | null) => setSelectedProject(v)}
                                 renderOption={(props: any, option: any) => (
                                    <Box
                                       component="li"
                                       sx={{
                                          '& > img': {
                                             mr: 2,
                                             flexShrink: 0,
                                          },
                                       }}
                                       {...props}>
                                       {option.name}
                                    </Box>
                                 )}
                              />
                              {selectedProject && (
                                 <IconButton
                                    aria-label="edit"
                                    component="label"
                                    color="primary"
                                    onClick={() => {
                                       updateAccountPatch({
                                          id: account.id,
                                          field: 'accountProjects',
                                          value: [
                                             ...account.accountProjects,
                                             {
                                                projectId: selectedProject.id,
                                                project: selectedProject,
                                             } as AccountProject,
                                          ],
                                       } as PatchModel);
                                       setAccount({
                                          ...account,
                                          accountProjects: [
                                             ...account.accountProjects,
                                             {
                                                projectId: selectedProject.id,
                                                project: selectedProject,
                                             } as AccountProject,
                                          ],
                                       });
                                       setSelectedProject(null);
                                    }}>
                                    <AddCircleIcon />
                                 </IconButton>
                              )}
                           </div>
                           <DataGridPro
                              sx={{
                                 height: gridHeight2 - 100,
                                 maxHeight: gridHeight2 - 100,
                                 minHeight: gridHeight2 - 100,
                              }}
                              rows={account.accountProjects.map((accountProject: AccountProject) => accountProject.project)}
                              columns={columns}
                              rowCount={account.accountProjects?.length}
                              loading={loading}
                              pageSize={rowsPerPage}
                              disableSelectionOnClick={true}
                              rowsPerPageOptions={[5, 10, 20, 100]}
                              onPageSizeChange={(page: number) => setRowsPerPage(page)}
                              pagination
                              onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
                              onCellEditCommit={updateProject}
                           />
                        </TabPanel>
                     </div>
                  </TabContext>
               </Box>
            </div>
         )}
      </div>
   );
}
