import { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FinancialCharts } from '../../../models/project.model';
import { NetValuePerAreaChart2 } from '../../admin/admin-content/projects/charts/NetValuePerAreaChart2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

export function NetValuePerAreaCharts(props: { chartData: FinancialCharts }) {
   const [tabIndex, setTabIndex] = useState<string>('0');
   const [chartContainerWidth, setChartContainerWidth] = useState<number>(0);

   useEffect(() => {
      function updateGridHeight() {
         const width = document.getElementById('chart-container')?.clientWidth;

         setChartContainerWidth(width ? width * 0.7 : 0);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, []);

   const getValue = (label: string) => {
      if (props.chartData.netValuePerArea === null) return 0;

      const data = tabIndex === '1' ? props.chartData.netValuePerArea?.perCustomer : props.chartData.netValuePerArea?.total;

      const item = data?.find((item) => item.label === label);

      return item ? item.value.total : 0;
   };

   const formatValue = (value: number) => {
      return value < 0 ? `- $${Math.round(Math.abs(Number(value))).toLocaleString()}` : `$${Math.round(Number(value)).toLocaleString()}`;
   };

   return (
      <div className="w-100">
         <TabContext value={tabIndex}>
            <div className="flex column project-tabs">
               <TabList
                  onChange={(event: React.SyntheticEvent, newValue: string) => {
                     setTabIndex(newValue);
                  }}>
                  <Tab className="pr-2" label="TOTAL" value="0" />
                  <Tab className="pl-2" label={`BY CUSTOMER (${props.chartData.netValuePerArea?.totalCustomers ? Math.round(Number(props.chartData.netValuePerArea?.totalCustomers)).toLocaleString() : 0})`} value="1" />
               </TabList>
               <div className="flex between items-center gap">
                  <div id="chart-container" className="flex column pt-5">
                     {props.chartData.netValuePerArea !== null && props.chartData.netValuePerArea?.total?.length > 0 && props.chartData.netValuePerArea?.perCustomer?.length > 0 && (
                        <>
                           <TabPanel value="0">
                              <NetValuePerAreaChart2 values={props.chartData.netValuePerArea?.total} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                           </TabPanel>
                           <TabPanel value="1">
                              <NetValuePerAreaChart2 values={props.chartData.netValuePerArea?.perCustomer} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                           </TabPanel>
                        </>
                     )}
                  </div>
                  <div className="flex column items-center center net-value-equation">
                     {props.chartData.netValuePerArea && (
                        <TableContainer>
                           <Table>
                              {/* <TableHead>
                                 <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                       <Typography variant="h6" color="secondary">
                                          Total $'s
                                       </Typography>
                                    </TableCell>
                                 </TableRow>
                              </TableHead> */}
                              <TableBody>
                                 <TableRow>
                                    <TableCell>&nbsp;&nbsp;&nbsp;Build Capex</TableCell>
                                    <TableCell>{formatValue(getValue('Build Capex'))}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>+ Drop Capex</TableCell>
                                    <TableCell>{formatValue(getValue('Drop Capex'))}</TableCell>
                                 </TableRow>
                                 <TableRow className="underline">
                                    <TableCell>= Invested Capital</TableCell>
                                    <TableCell>{formatValue(getValue('Build Capex') + getValue('Drop Capex'))}</TableCell>
                                 </TableRow>

                                 <TableRow>
                                    <TableCell>+ Gross Customer Value</TableCell>
                                    <TableCell>{formatValue(getValue('Gross Customer Value'))}</TableCell>
                                 </TableRow>

                                 <TableRow>
                                    <TableCell>Unoptimized Hexbin Value</TableCell>
                                    <TableCell>{formatValue(getValue('Build Capex') + getValue('Drop Capex') + getValue('Gross Customer Value'))}</TableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </TableContainer>
                     )}
                  </div>
               </div>
            </div>
         </TabContext>
      </div>
   );
}
