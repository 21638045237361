import { PatchModel } from "../models/path.model";
import { fetchWithValidation } from "./fetch.service";

const API_URL = process.env.REACT_APP_API as String;
const FileDownload = require("js-file-download");

export const getAsset = async(id: string, fileName: string): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };

    return fetch(`${API_URL}assets/download/${id}`, requestOptions)
           .then(response => response.blob())
           .then(response =>{
            FileDownload(response, fileName);
    });
}

export const patchAsset = async(patch: PatchModel): Promise<any> => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(patch)
    };
    return fetchWithValidation(`${API_URL}assets/${patch.id}`, requestOptions);
}

export const postAsset = async(data: FormData): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include' as any,
        body:data
    };

    return fetchWithValidation(`${API_URL}assets/upload`, requestOptions);
}