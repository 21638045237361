import { useEffect, useState } from 'react';
import { Project } from '../../models/project.model';
import { SearchModel } from '../../models/search.model';
import { getProjects } from '../../services/project.service';
import { ProjectCard } from './ProjectCard';
import { SkeletonProjectCard } from './SkeletonProjectCard';
import { Pagination } from '../shared/ui/Pagination';

export function ProjectsList(props: { accountId: string }) {
   const [projectsObj, setProjectsObj] = useState<{
      total: number;
      projects: Project[];
   }>({
      total: 0,
      projects: [],
   });

   const [isLoading, setIsLoading] = useState<boolean>(false);

   const [showSearch, setShowSearch] = useState<boolean>(false);
   const [seachProject, setSearchProject] = useState('');

   const [page, setPage] = useState(0);
   const projectsPerPage = 20;

   useEffect(() => {
      if (props.accountId) {
         setPage(0);
         retrieveProjects();
      }
   }, [props.accountId]);

   useEffect(() => {
      retrieveProjects();
   }, [page]);

   useEffect(() => {
      if (!showSearch) return;
      const timeOutId = setTimeout(() => {
         setPage(0);
         retrieveProjects();
      }, 500);
      return () => clearTimeout(timeOutId);
   }, [seachProject]);

   const retrieveProjects = async () => {
      setIsLoading(true);
      try {
         var accounts = [];
         accounts.push(props.accountId);
         const response = await getProjects(
            {
               page: page,
               rowsPerPage: projectsPerPage,
               search: seachProject,
               orderBy: 'name',
               asc: true,
            } as SearchModel,
            false,
            accounts
         );

         if (!response.error) {
            setProjectsObj({
               total: response.total,
               projects: response.projects.filter((project: Project) => project.isActive),
            });
            setShowSearch(true);
         } else {
         }
         setIsLoading(false);
      } catch (e) {
         setIsLoading(false);
         console.log(e);
      }
   };

   return (
      <>
         {showSearch && (
            <div className="flex items-center start mb-2">
               <input className="projects-list-search" autoFocus type="text" placeholder="Search" value={seachProject ?? ''} onChange={(event: any) => setSearchProject(event.target.value)} />
            </div>
         )}

         {isLoading ? (
            <div className="flex wrap gap-20">
               <SkeletonProjectCard />
               <SkeletonProjectCard />
               <SkeletonProjectCard />
            </div>
         ) : (
            <div className="flex column gap">
               <div className="flex wrap gap-20">
                  {projectsObj.projects.map((project: Project, index: number) => {
                     return <ProjectCard key={index} project={project} />;
                  })}
               </div>
               {projectsObj.total > projectsPerPage && <Pagination page={page} pages={Math.ceil(projectsObj.total / projectsPerPage)} setPage={setPage} />}
            </div>
         )}
      </>
   );
}
