import { Select, MenuItem, FormControl } from '@mui/material';
import logo from '../static/assets/logo/logo-nav-long.png';
import { useContext, useState, useEffect } from 'react';
import { ConnectModal } from './login/ConnectModal';
import { ProfileMenu } from './profile/ProfileMenu';
import { AuthContext } from '../contexts/AuthContext';
import { authStatus } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getAccounts } from '../services/account.service';
import { Account } from '../models/account.model';

export function Top(props: { selectedAccountId: string; setSelectedAccountId: (accountId: string) => void; setSelectedAccountName: (accountName: string) => void }) {
   const [loginModal, setLoginModal] = useState(false);
   const [accounts, setAccounts] = useState<Account[]>([]);
   const { isAuthenticated } = useContext(AuthContext);
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      if (!accounts.length) {
         retrieveAccounts();
      }
   }, [authStatus().content]);

   useEffect(() => {
      if (!props.selectedAccountId || props.selectedAccountId === '') return;
      localStorage.setItem('selectedAccountId', props.selectedAccountId);
   }, [props.selectedAccountId]);

   useEffect(() => {
      if (!props.selectedAccountId || props.selectedAccountId === '') return;
      const selectedAccount: any = accounts.find((account: any) => account.id === props.selectedAccountId);
      props.setSelectedAccountName(selectedAccount?.name);
   }, [accounts, props.selectedAccountId]);

   const changeSelectedAccount = (id: string) => {
      if (accounts.length > 0) {
         props.setSelectedAccountId(id);
      }
   };

   const retrieveAccounts = async () => {
      var response = await getAccounts({ page: 0, rowsPerPage: 100, search: '', orderBy: 'name', asc: true }, false);

      if (!response.error) {
         setAccounts(response.accounts);
         if (['ADMIN', 'FINADMIN'].some((role) => authStatus().content?.roles.includes(role))) {
            setAccounts([...response.accounts.filter((account: any) => account.id === '98355490-056d-4e3a-b1cc-e63a4f459a6b'), ...response.accounts.filter((account: any) => account.id !== '98355490-056d-4e3a-b1cc-e63a4f459a6b')] as Account[]);
         } else {
            setAccounts(response.accounts.filter((account: any) => authStatus().content?.accounts.includes(account.id)) as Account[]);
         }

         const selectedAccountFromLocalStorage = localStorage.getItem('selectedAccountId');
         if (selectedAccountFromLocalStorage && response.accounts.findIndex((account: any) => account.id === selectedAccountFromLocalStorage) !== -1) {
            props.setSelectedAccountId(selectedAccountFromLocalStorage);
         } else {
            props.setSelectedAccountId(response.accounts[0].id);
         }
      }
   };

   if (!location.pathname.includes('admin')) {
      return (
         <div className="top flex items-center between">
            <img onClick={() => navigate('/')} className="nav-logo pointer" src={logo} alt="" />
            {isAuthenticated ? (
               <div className="flex center items-center gap">
                  {authStatus().content && (
                     <FormControl variant="standard" sx={{ minWidth: '150px' }}>
                        {/* <InputLabel id="demo-simple-select-standard-label">Account</InputLabel> */}
                        <Select
                           value={props.selectedAccountId}
                           label="Account"
                           onChange={(event) => {
                              changeSelectedAccount(event.target.value as string);
                              navigate('/');
                           }}
                           sx={{
                              color: '#749074', // Change the text color when idle
                              '&:before': {
                                 borderColor: '#749074', // Change the border color when idle
                              },
                              '&:hover:not(.Mui-disabled):before': {
                                 borderColor: '#FFFFFF', // Change the border color on hover
                              },
                              '&:after': {
                                 borderColor: '#FFFFFF', // Change the border color when focused
                              },
                              svg: {
                                 color: '#749074', // Change the icon color when idle
                              },
                           }}>
                           {/* {
                              authStatus().content.roles.includes('ADMIN') ?
                           } */}

                           {accounts.map((account: any, i: number) => (
                              <MenuItem className="selector-item" key={i} value={account.id}>
                                 {account.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  )}
                  <ProfileMenu />
               </div>
            ) : (
               <div className="flex">
                  <ConnectModal open={loginModal} setOpen={setLoginModal} signIn={true} />
               </div>
            )}
         </div>
      );
   } else {
      return <div></div>;
   }
}
