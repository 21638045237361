import { useEffect, useState } from 'react';
import { getScenarioData } from '../../../services/project.service';
import { getMeta } from '../../../helpers/helper';
import { Project } from '../../../models/project.model';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from '@mui/material';

export function ScenarioBadges(props: { project: Project; scenarioId?: string }) {
   const [projectBadgesData, setProjectBadgesData] = useState<{ key: string; label: string; value?: string; description: string; color: string; unit: any }[]>([
      {
         key: 'capex',
         label: 'Capex',
         value: !props.scenarioId ? getMeta(props.project.projectMeta, 'capex') : undefined,
         description: 'Total Cost to Connect',
         color: 'gradient-blue',
         unit: {
            position: 'start',
            value: '$',
         },
      },
      {
         key: 'revenue',
         label: 'Revenue',
         value: !props.scenarioId ? getMeta(props.project.projectMeta, 'revenue') : undefined,
         description: 'Revenue',
         color: 'gradient-purple',
         unit: {
            position: 'start',
            value: '$',
         },
      },
      {
         key: 'npv',
         label: 'NPV',
         value: !props.scenarioId ? getMeta(props.project.projectMeta, 'NPV') : undefined,
         description: 'Net Present Value',
         color: 'gradient-yellow',
         unit: {
            position: 'start',
            value: '$',
         },
      },
      {
         key: 'irr',
         label: 'IRR',
         value: !props.scenarioId ? getMeta(props.project.projectMeta, 'IRR') : undefined,
         description: 'Internal Rate of Return',
         color: 'gradient-red',
         unit: {
            position: 'end',
            value: '%',
         },
      },
   ]);

   useEffect(() => {
      if (props.scenarioId) getProjectBadgeValues();
   }, [props.scenarioId]);

   const getProjectBadgeValues = async () => {
      if (!props.scenarioId) return;
      try {
         const response = await getScenarioData(props.scenarioId);
         if (!response.scenarioData) return;
         setProjectBadgesData(
            projectBadgesData.map((badge) => ({
               ...badge,
               value: response.scenarioData[badge.key],
            }))
         );
      } catch (e) {
         console.log(e);
      }
   };
   return (
      <div className="project-badges">
         {projectBadgesData.map(
            (
               projectBadgeData: {
                  description: string;
                  key: string;
                  label: string;
                  value?: string;
                  color: string;
                  unit: any;
               },
               index: number
            ) => {
               return (
                  <div className={`project-badge ${projectBadgeData.color}`} style={{ justifyContent: 'space-between' }} key={index}>
                     <div className="flex items-center gap-small">
                        <span>Unoptimized {projectBadgeData.label}</span>
                        <Tooltip title={projectBadgeData.description} placement="right">
                           <HelpIcon fontSize="small" />
                        </Tooltip>
                     </div>
                     <h3>
                        {projectBadgeData.value && projectBadgeData.value.toString()?.length > 0
                           ? (projectBadgeData.unit.position === 'start' ? (Number(projectBadgeData.value) < 0 ? '-' : '') + projectBadgeData.unit.value : '') +
                             (projectBadgeData.unit.position === 'start' ? Math.round(Math.abs(Number(projectBadgeData.value))).toLocaleString() : Number(projectBadgeData.value).toFixed(2)) +
                             (projectBadgeData.unit.position === 'end' ? projectBadgeData.unit.value : '')
                           : '-'}
                     </h3>
                  </div>
               );
            }
         )}
      </div>
   );
}
