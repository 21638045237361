import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FinancialModel, Project } from '../../../../../models/project.model';
import { FinancialModelCharts } from './FinancialModelCharts';
import { GridCloseIcon } from '@mui/x-data-grid';

export function FinancialModelChartsModal(props: { project: Project; financialModel: FinancialModel; open: boolean; setOpen: (open: boolean) => void }) {
   return (
      <Dialog open={props.open} onClose={() => props.setOpen(false)} fullWidth maxWidth="lg" scroll="paper">
         <DialogTitle>
            Financial Model {props.financialModel.modelId} Charts
            <IconButton aria-label="close" onClick={() => props.setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
               <GridCloseIcon />
            </IconButton>
         </DialogTitle>
         <DialogContent
            style={{
               maxHeight: '80vh',
               minHeight: '50vh',
               minWidth: '50vw',
               overflowY: 'auto',
               fontSize: '12px',
               fontFamily: 'monospace !important',
               color: 'white',
               whiteSpace: 'pre-wrap',
            }}
         >
            <FinancialModelCharts project={props.project} financialModel={props.financialModel} />
         </DialogContent>
      </Dialog>
   );
}
