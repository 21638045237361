import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
   position: 'relative',
   border: '1px solid rgba(0, 0, 0, 0.23)',
   borderRadius: '20px',
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   '&:hover': {
      border: '1px solid #022222',
      backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginLeft: 0,
   width: '100%',
   [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
   },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
   padding: theme.spacing(0, 2),
   height: '100%',
   position: 'absolute',
   pointerEvents: 'none',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
   color: 'inherit',
   '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
         width: '12ch',
         '&:focus': {
            width: '20ch',
         },
      },
   },
}));

export function SearchInput(props: { placeholder: string; value: string; setValue: (value: string) => void; focus?: boolean; light?: boolean }) {
   // return (
   //    <Search color="secondary" className="search-input">
   //       <SearchIconWrapper>
   //          <SearchIcon />
   //       </SearchIconWrapper>
   //       <StyledInputBase placeholder={props.placeholder} inputProps={{ 'aria-label': 'search' }} value={props.value} onChange={(event: any) => props.setValue(event.target.value)} />
   //       {props.value.length > 0 && (
   //          <span className="clear-input" onClick={() => props.setValue('')}>
   //             +
   //          </span>
   //       )}
   //    </Search>
   // );
   return (
      <div className={`search-input ${props.light ? 'light' : ''}`}>
         <Input
            autoFocus={props.focus ?? false}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(event: any) => props.setValue(event.target.value)}
            inputProps={{
               'aria-label': 'search',
            }}
         />
         {/* {props.value.length > 0 && (
            <span className="clear-input" onClick={() => props.setValue('')}>
               +
            </span>
         )} */}
      </div>
   );
}
