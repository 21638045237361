import { useState } from 'react';
import { Asset, FinancialModel, Project } from '../../../../../models/project.model';
import { GridCellEditCommitParams, GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ScenarioEdit } from '../../users/grid/ScenarioEdit';
import { StatusEdit } from '../../../../shared/ui/grid/StatusEdit';
import TimeAgo from 'timeago-react';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { patchAsset } from '../../../../../services/asset.service';
import { PatchModel } from '../../../../../models/path.model';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';
import { ConfirmModal } from '../../../../shared/ui/modals/ConfirmModal';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { UploadAsset } from './UploadAsset';

export function ProjectAssets(props: { project: Project; setProject: React.Dispatch<React.SetStateAction<Project | undefined>>; assets: Asset[]; financialModels: FinancialModel[]; gridAssetsHeight: number }) {
   const [rowsPerPage, setRowsPerPage] = useState<number>(10);
   const [editCellObj, setEditCellObj] = useState<string>();
   const [selectedObj, setSelectedObj] = useState<any>(null);
   const [uploadAssetOpen, setUploadAssetOpen] = useState(false);

   const snackbar = useSnackBar();

   const columns: GridColDef[] = [
      {
         field: 'sort',
         headerName: 'Sort',
         editable: true,
         filterable: true,
         type: 'number',
      },
      {
         field: 'name',
         headerName: 'Name',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'description',
         headerName: 'Description',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'type',
         headerName: 'Type',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'url',
         headerName: 'URL',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'financialModel',
         headerName: 'Scenario',
         editable: true,
         filterable: false,
         flex: 1,
         renderEditCell: (params: GridRenderCellParams<any, any, any>) => <ScenarioEdit params={params} models={props.financialModels.filter((model: FinancialModel) => model.status === 'APPROVED')} />,

         renderCell: (params: any) => {
            if (params.row.financialModel == null) return <span></span>;
            return <span>{params.row.financialModel.scenarioName ?? `Scenario-${params.row.financialModel.modelId}`}</span>;
         },
      },
      {
         field: 'isActive',
         headerName: 'Status',
         editable: true,
         filterable: false,
         renderEditCell: (params: any) => <StatusEdit {...params} />,
         renderCell: (params: any) => {
            return <span className={`status ${params.row.isActive == true ? 'active' : 'inactive'}`}>{params.row.isActive == true ? 'active' : 'inactive'}</span>;
         },
      },
      {
         field: 'createdAt',
         headerName: 'Creation',
         editable: false,
         filterable: false,
         flex: 1,
         renderCell: (params: any) => <TimeAgo datetime={new Date(params.row.createdAt.toString())} />,
      },
      {
         field: 'edit',
         headerName: '',
         editable: false,
         sortable: false,
         filterable: false,
         disableColumnMenu: true,
         renderCell: (params: any) => {
            return (
               <div className="flex gap-small">
                  <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row.id)}>
                     <DeleteIcon />
                  </IconButton>
               </div>
            );
         },
      },
   ];
   async function updateAsset(params: GridCellEditCommitParams) {
      if (JSON.stringify(params.value) === editCellObj) return;
      try {
         const response = await patchAsset(params as PatchModel);
         if (!response.error) {
            snackbar.success('Asset successfully updated.');
         } else {
            snackbar.error('Error while updating the project.');
         }
      } catch (e) {
         console.log(e);
      }
   }

   return (
      <>
         <div id="admin-assets-top" className="p-3">
            <Button variant="outlined" size="medium" color="primary" startIcon={<AddIcon />} onClick={() => setUploadAssetOpen(true)}>
               New asset
            </Button>
            <UploadAsset open={uploadAssetOpen} setOpen={setUploadAssetOpen} projectId={props.project.id} financialModels={props.financialModels} />
         </div>
         <ConfirmModal
            selectedObj={selectedObj}
            setSelectedObj={setSelectedObj}
            icon={<HighlightOffIcon color="error" fontSize="large" />}
            title="Confirm Delete"
            message="Are you sure you want to delete the selected asset?"
            buttonText="Delete"
            onConfirm={() => {
               if (!props.project) return;
               updateAsset({
                  id: selectedObj,
                  field: 'isDeleted',
                  value: true,
               } as PatchModel);
               const newAssets = props.project?.assets?.filter((asset: Asset) => asset.id !== selectedObj);
               props.setProject({
                  ...props.project,
                  assets: newAssets,
               });
               setSelectedObj(null);
            }}
         />
         <DataGridPro
            sx={{
               height: props.gridAssetsHeight,
               maxHeight: props.gridAssetsHeight,
               minHeight: props.gridAssetsHeight,
            }}
            key={uploadAssetOpen ? 'open' : 'close'}
            rows={props.project.assets}
            columns={columns}
            rowCount={props.project.assets.length}
            // loading={loading}
            pageSize={rowsPerPage}
            disableSelectionOnClick={true}
            rowsPerPageOptions={[5, 10, 20, 100]}
            onPageSizeChange={(page: number) => setRowsPerPage(page)}
            pagination
            onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
            onCellEditCommit={updateAsset}
         />
      </>
   );
}
