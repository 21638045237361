import { IconButton, Modal } from '@mui/material';
import { DivProps } from 'react-html-props';
import CloseIcon from '@mui/icons-material/Close';

export function SimpleModal({ open, setOpen, ...divProps }: SimpleModalProps) {
   return setOpen ? (
      <Modal open={open} onClose={() => setOpen(false)}>
         <div className="modal-content">
            <div className="modal-close" onClick={() => setOpen(false)}>
               <IconButton aria-label="delete">
                  <CloseIcon />
               </IconButton>
            </div>
            {divProps.children}
         </div>
      </Modal>
   ) : (
      <Modal open={open}>
         <div className="modal-content">{divProps.children}</div>
      </Modal>
   );
}

export interface SimpleModalProps extends DivProps {
   open: boolean;
   setOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}
