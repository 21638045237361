import { Alert, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import { WarningMessage } from '../../../../../models/warningMessage.model';
import { SimpleModal } from '../../../../shared/ui/modals/SimpleModal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';
import { postAsset } from '../../../../../services/asset.service';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';
import { FinancialModel } from '../../../../../models/project.model';

export function UploadAsset(props: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>>; projectId: string; financialModels: FinancialModel[] }) {
   const [topMessage, setTopMessage] = useState({
      error: false,
      message: null,
   } as WarningMessage);
   const [form, setForm] = useState({ name: '', description: '', file: null, modelId: 'undefined' });
   const [selectedFileLabel, setSelectedFileLabel] = useState('');
   const [selectedFile, setSelectedFile] = useState(null);
   const snackbar = useSnackBar();

   useEffect(() => {
      if (!props.open) resetForm();
   }, [props.open]);

   useEffect(() => {
      if (topMessage.message) {
         setTimeout(() => setTopMessage({ error: false, message: null }), 3000);
      }
   }, [topMessage]);

   const handleFileChange = (event: any) => {
      if (event.target.files.length === 0) return;
      setSelectedFile(event.target.files[0]);
      setSelectedFileLabel(event.target.files[0].name);
   };

   const resetForm = () => {
      setSelectedFileLabel('');
      setSelectedFile(null);
      form.description = '';
      form.name = '';
      form.file = null;
   };

   const handleUpload = async (e: any) => {
      e.preventDefault();
      if (!selectedFile) {
         setTopMessage({ error: true, message: 'No file provided' });
         return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('name', form.name);
      formData.append('description', form.description);
      formData.append('projectId', props.projectId);
      if (form.modelId !== 'undefined') {
         formData.append('modelId', form.modelId);
      }

      try {
         const response = await postAsset(formData);
         if (response.error) {
            snackbar.error('Asset already exists');
         } else {
            props.setOpen(false);
            snackbar.success('Asset successfully created');
         }
      } catch (error) {
         snackbar.error('Error while uploading asset');
      }
   };

   return (
      <SimpleModal open={props.open} setOpen={props.setOpen}>
         <div className="flex column">
            <div>
               <Typography sx={{ mb: 1 }} variant="h6" component="div">
                  Upload new asset
               </Typography>
            </div>
            <div>
               <form onSubmit={handleUpload} className="flex column mt-4 gap">
                  {topMessage.message !== null && (
                     <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error' : 'success'}>
                        {topMessage.message}
                     </Alert>
                  )}
                  <TextField label="Name" variant="outlined" value={form.name} required onChange={(event: any) => setForm({ ...form, name: event.target.value })} />
                  <TextField label="Description" variant="outlined" value={form.description} required onChange={(event: any) => setForm({ ...form, description: event.target.value })} />

                  <FormControl>
                     <InputLabel id="roles-label">Visibility</InputLabel>
                     <Select
                        sx={{ textAlign: 'left' }}
                        labelId="roles-label"
                        value={form.modelId}
                        label="Visibility"
                        onChange={(event: any) => {
                           const selectedValue = event.target.value;
                           setForm({ ...form, modelId: selectedValue });
                        }}>
                        <MenuItem value="undefined">Global</MenuItem>
                        {props.financialModels
                           .filter((model: FinancialModel) => model.status === 'APPROVED')
                           .map((model: FinancialModel) => (
                              <MenuItem key={model.id} value={model.id}>
                                 {model.scenarioName ?? 'Scenario-' + model.modelId}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>

                  <div className="flex center items-center column">
                     <Button variant="contained" component="label" startIcon={<FileUploadIcon style={{ color: 'white' }} />} color="primary">
                        Import
                        <input hidden accept="*/*" type="file" onChange={handleFileChange} />
                     </Button>
                     <span
                        className="mt-2"
                        style={{
                           maxWidth: '300px',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           whiteSpace: 'nowrap',
                        }}>
                        {selectedFileLabel}
                     </span>
                  </div>
                  <div className="flex end gap-small">
                     <Button onClick={() => props.setOpen(false)} variant="outlined">
                        Cancel
                     </Button>
                     <Button type="submit" variant="contained" color="primary" startIcon={<AddIcon />}>
                        Upload
                     </Button>
                  </div>
               </form>
            </div>
         </div>
      </SimpleModal>
   );
}
