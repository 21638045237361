import { GridRenderCellParams } from '@mui/x-data-grid';
import { UserAccount, UserRole } from '../../../../../models/user.model';

export function RenderAccounts(params: GridRenderCellParams<UserAccount[]>) {
   return (
      <div className="flex gap-small">
         {params.row.userAccounts.map((userAccount: UserAccount, index: number) => (
            <span className="role-status" key={index}>
               {userAccount.account.name}
            </span>
         ))}
      </div>
   );
}
