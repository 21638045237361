import { Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FolderIcon from '@mui/icons-material/Folder';
import logo from '../../static/assets/logo/logo-nav-long.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../../contexts/AuthContext';
import { useContext } from 'react';
import { authStatus } from '../../services/auth.service';

export function AdminNav() {
   const navigate = useNavigate();
   const { setAuthenticated } = useContext(AuthContext);
   const location = useLocation();

   const currentPath = location.pathname;
   const categoryPath = currentPath.split('/')[2] || '';

   return (
      <div className="flex column center items-center">
         <div className="flex column w-100 ">
            <Link to={'/'}>
               <img src={logo} alt="" />
            </Link>
            {/* <div className={`menu-link ${!categoryPath ? ' selected' : ''} `} onClick={() => navigate('/admin')}>
               <div>
                  <DashboardIcon />
                  <p>Dashboard</p>
               </div>
            </div> */}
            <div className={`menu-link ${categoryPath === 'accounts' ? ' selected' : ''}`} onClick={() => navigate('/admin/accounts')}>
               <div>
                  <AccountBoxIcon />
                  <p>Accounts</p>
               </div>
            </div>
            <div className={`menu-link ${categoryPath === 'projects' ? ' selected' : ''}`} onClick={() => navigate('/admin/projects')}>
               <div>
                  <FolderIcon />
                  <p>Projects</p>
               </div>
            </div>
            {['ADMIN'].some((role) => authStatus().content?.roles.includes(role)) && (
               <div className={`menu-link ${categoryPath === 'users' ? ' selected' : ''}`} onClick={() => navigate('/admin/users')}>
                  <div>
                     <GroupIcon />
                     <p>Users</p>
                  </div>
               </div>
            )}
         </div>
         <Button variant="text" color="secondary" startIcon={<LogoutIcon />} onClick={() => setAuthenticated(false)}>
            Log out
         </Button>
      </div>
   );
}
