import { Role, User } from '../../../../models/user.model';
import { createUser, getUsers, patchUser, getUsersReport } from '../../../../services/user.service';
import { useState, useEffect } from 'react';
import { SearchInput } from '../../../shared/ui/SearchInput';
import TimeAgo from 'timeago-react';
import { DataGridPro, GridColDef, GridRenderCellParams, GridSortModel, GridCellEditCommitParams, GridCellParams } from '@mui/x-data-grid-pro';
import { Button, IconButton, Autocomplete, TextField, Box } from '@mui/material';
import { PatchModel } from '../../../../models/path.model';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EditIcon from '@mui/icons-material/Edit';
import { SignUpModel } from '../../../../models/signup.model';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { SearchModel } from '../../../../models/search.model';
import { Account } from '../../../../models/account.model';
import { getAccounts } from '../../../../services/account.service';
import { ConfirmModal } from '../../../shared/ui/modals/ConfirmModal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { StatusEdit } from '../../../shared/ui/grid/StatusEdit';
import { RolesEdit } from './grid/EditRoles';
import { AccountEdit } from './grid/EditAccount';
import { RenderStatus } from '../../../shared/ui/grid/RenderStatus';
import { RenderRoles } from './grid/RenderRoles';
import { RenderAccount } from './grid/RenderAccount';
import { useSnackBar } from '../../../../contexts/SnackbarContext';
import { NewUserModal } from './NewUserModal';
import { AccountsEdit } from './grid/EditAccounts';
import { RenderAccounts } from './grid/RenderAccounts';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export function Users() {
   const getSortSettingsFromLocalStorage = () => {
      const sortSettingsStr = localStorage.getItem('adminUsersSort');
      if (sortSettingsStr) {
         const sortSettings = JSON.parse(sortSettingsStr);
         return { orderBy: sortSettings.orderBy, asc: sortSettings.asc };
      } else {
         return { orderBy: 'firstname', asc: true };
      }
   };

   const [searchObj, setSearchObj] = useState<SearchModel>({
      page: 0,
      rowsPerPage: 100,
      search: '',
      ...getSortSettingsFromLocalStorage(),
   });
   const [loading, setLoading] = useState<boolean>(false);
   const [rows, setRows] = useState<User[]>([]);
   const [total, setTotal] = useState(0);
   const [selectedObj, setSelectedObj] = useState<any>(null);
   const [accounts, setAccounts] = useState<Account[]>([]);
   const [roles, setRoles] = useState<Role[]>([]);
   const [accountsFilters, setAccountsFilters] = useState<Account[]>([]);
   const [editCellObj, setEditCellObj] = useState<string>();
   const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
   const navigate = useNavigate();
   const snackbar = useSnackBar();

   const [gridHeight, setGridHeight] = useState<number>(200);

   useEffect(() => {
      function updateGridHeight() {
         const topHeight = document.getElementById('admin-top')?.clientHeight;
         setGridHeight(window.innerHeight - (topHeight ?? 0) ?? 200);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, [rows]);

   useEffect(() => {
      const timeOutId = setTimeout(() => {
         setSearchObj({ ...searchObj, page: 0 });
         retrieveUsers();
      }, 500);
      return () => clearTimeout(timeOutId);
   }, [searchObj.search]);

   useEffect(() => {
      setSearchObj({ ...searchObj, page: 0 });
      retrieveUsers();
   }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc, accountsFilters]);

   useEffect(() => {
      retrieveUsers();
   }, [searchObj.page]);

   useEffect(() => {
      if (searchObj.orderBy && searchObj.asc !== undefined) updateSortSettings(searchObj.orderBy, searchObj.asc ?? true);
   }, [searchObj.orderBy, searchObj.asc]);

   useEffect(() => {
      retrieveUsers();
      retrieveAccounts();
   }, []);

   const updateSortSettings = (orderBy: any, asc: boolean) => {
      const sortSettings = { orderBy, asc };
      localStorage.setItem('adminUsersSort', JSON.stringify(sortSettings));
   };

   const columns: GridColDef[] = [
      {
         field: 'firstname',
         headerName: 'First Name',
         editable: true,
         width: 100,
         filterable: false,
         flex: 1,
      },
      {
         field: 'lastname',
         headerName: 'Last Name',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'isActive',
         headerName: 'Status',
         editable: true,
         filterable: false,
         renderEditCell: (params: GridRenderCellParams<any, any, any>) => <StatusEdit {...params} />,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderStatus {...params} />,
      },
      {
         field: 'email',
         headerName: 'Email',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'userRoles',
         headerName: 'Role(s)',
         editable: true,
         filterable: false,
         sortable: false,
         flex: 1,
         renderEditCell: (params: GridRenderCellParams<any, any, any>) => <RolesEdit params={params} roles={roles} />,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderRoles {...params} />,
      },
      {
         field: 'userAccounts',
         headerName: 'Account(s)',
         editable: true,
         filterable: false,
         flex: 1,
         renderEditCell: (params: GridRenderCellParams<any, any, any>) => <AccountsEdit params={params} accounts={accounts} />,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderAccounts {...params} />,
      },
      {
         field: 'createdAt',
         headerName: 'Creation',
         editable: false,
         filterable: false,
         flex: 1,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <TimeAgo datetime={new Date(params.row.createdAt.toString())} />,
      },
      {
         field: 'edit',
         headerName: '',
         editable: false,
         sortable: false,
         filterable: false,
         disableColumnMenu: true,
         renderCell: (params: GridRenderCellParams<any, any, any>) => {
            return (
               <div className="flex gap-small">
                  <IconButton
                     aria-label="edit"
                     component="label"
                     onClick={() =>
                        navigate(`/admin/users/${params.row.id}`, {
                           replace: true,
                        })
                     }>
                     <EditIcon />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row.id)}>
                     <DeleteIcon />
                  </IconButton>
                  <ConfirmModal
                     selectedObj={selectedObj === params.row.id ? selectedObj : null}
                     setSelectedObj={setSelectedObj}
                     icon={<HighlightOffIcon color="error" fontSize="large" />}
                     title="Confirm Delete"
                     message="Are you sure you want to delete the selected user?"
                     buttonText="Delete"
                     onConfirm={() => {
                        updateUser(
                           {
                              id: selectedObj,
                              field: 'isDeleted',
                              value: true,
                           } as PatchModel,
                           true
                        );
                        setSearchObj({ ...searchObj, page: 0 });
                        setRows(rows.filter((row: User) => row.id !== selectedObj));
                        setTotal(total - 1);
                        setSelectedObj(null);
                     }}
                  />
               </div>
            );
         },
      },
   ];

   async function retrieveUsers() {
      try {
         setLoading(true);
         const response = await getUsers(
            {
               ...searchObj,
               orderBy: searchObj.orderBy ?? 'firstname',
               asc: searchObj.asc ?? true,
            },
            accountsFilters.map((account: Account) => account.id)
         );
         if (!response.error) {
            setRoles(response.roles);
            setRows(response.users);
            setTotal(response.total);
            setLoading(false);
         } else {
            setLoading(false);
         }
      } catch (e) {
         setLoading(false);
      }
   }

   async function updateUser(params: GridCellEditCommitParams, isDeleting?: boolean) {
      if (JSON.stringify(params.value) === editCellObj) return;
      try {
         const response = await patchUser(params as PatchModel);
         if (!response.error) {
            snackbar.success(`User successfully ${isDeleting ? 'deleted' : 'updated'}.`);
         } else {
            snackbar.error(`Error while ${isDeleting ? 'deleteding' : 'updateding'} the user.`);
         }
      } catch (e) {
         console.log(e);
      }
   }

   async function newUser() {
      try {
         const response = await createUser({} as SignUpModel);
         if (!response.error) {
            snackbar.success('User successfully created.');
            navigate(`/admin/users/${response.userId}`);
         } else {
            snackbar.error('Error while updating the user.');
         }
      } catch (e) {}
   }

   async function retrieveAccounts() {
      try {
         const responseAccounts = await getAccounts(
            {
               page: 0,
               rowsPerPage: 100,
               search: '',
               orderBy: 'name',
               asc: true,
            },
            false
         );
         if (!responseAccounts.error) {
            setAccounts(responseAccounts.accounts);
         } else {
         }
      } catch (e) {}
   }

   const handleUserReportDownload = async () => {
      try {
         setLoading(true);
         const response = await getUsersReport(
            {
               ...searchObj,
               orderBy: searchObj.orderBy ?? 'firstname',
               asc: searchObj.asc ?? true,
            },
            accountsFilters.map((account: Account) => account.id)
         );
         if (!response.error) {
            const worksheet = XLSX.utils.json_to_sheet([
               ...response.users.map((u: any) => ({
                  'First Name': u.firstname,
                  'Last Name': u.lastname,
                  Email: u.email,
                  Status: u.isActive ? 'Active' : 'Inactive',
                  'Role(s)': u.userRoles,
                  'Account(s)': u.userAccounts,
                  'Creation Date': u.createdAt ? new Date(u.createdAt).toLocaleString() : '',
                  'Last Login': u.last_login ? new Date(u.last_login).toLocaleString() : '',
                  'Disabled Date': u.last_deactivate ? new Date(u.last_deactivate).toLocaleString() : '',
                  'Disabled By': u.last_deactivate_by,
               })),
            ]);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Users Access Report');
            const excelBuffer = XLSX.write(workbook, {
               bookType: 'xlsx',
               type: 'array',
            });
            const data = new Blob([excelBuffer], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(data, `Hexvarium Users Access Report.xlsx`);

            setLoading(false);
         } else {
            setLoading(false);
         }
      } catch (e) {
         setLoading(false);
      }
   };

   return (
      <div>
         <div id="admin-top" className="admin-top">
            {accounts.length > 0 && roles.length > 0 && (
               <div className="flex between items-center w-100 mb-4">
                  <NewUserModal open={userModalOpen} onClose={() => setUserModalOpen(false)} accounts={accounts} roles={roles} />
                  <Button color="primary" size="medium" variant="outlined" startIcon={<GroupAddIcon />} onClick={() => setUserModalOpen(true)}>
                     User
                  </Button>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: '400px' }}>
                     <Button color="primary" size="medium" variant="outlined" startIcon={<SummarizeIcon />} onClick={() => handleUserReportDownload()}>
                        Access Report
                     </Button>
                     <Autocomplete
                        color="secondary"
                        sx={{ minWidth: '200px' }}
                        multiple
                        size="small"
                        options={accounts}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField color="secondary" {...params} label="Filter by account(s)" />}
                        onChange={(event: any, val: Account[]) => {
                           setAccountsFilters(val);
                        }}
                     />
                  </Box>
               </div>
            )}
            <div className="flex items-center end">
               <SearchInput placeholder="Users" value={searchObj.search ?? ''} setValue={(value: string) => setSearchObj({ ...searchObj, search: value })} />
            </div>
         </div>
         <DataGridPro
            sx={{ minHeight: gridHeight, height: gridHeight, maxHeight: gridHeight, backgroundColor: 'white' }}
            rows={rows}
            columns={columns}
            rowCount={total}
            loading={loading}
            pageSize={searchObj.rowsPerPage}
            onPageSizeChange={(newPageSize: number) => {
               setSearchObj({
                  ...searchObj,
                  page: 0,
                  rowsPerPage: newPageSize,
               });
            }}
            disableSelectionOnClick={true}
            onPageChange={(page: number) => setSearchObj({ ...searchObj, page: page })}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={[
               {
                  field: searchObj.orderBy ?? '',
                  sort: searchObj.asc === true ? 'asc' : 'desc',
               },
            ]}
            onSortModelChange={(sortModel: GridSortModel) => {
               if (sortModel[0] === undefined) {
                  setSearchObj({ ...searchObj, asc: true });
               } else {
                  setSearchObj({
                     ...searchObj,
                     orderBy: sortModel[0]?.field ?? 'firstname',
                     asc: sortModel[0]?.sort == 'asc' ? true : false,
                  });
               }
            }}
            onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
            onCellEditCommit={(params: GridCellEditCommitParams) => updateUser(params)}
         />
      </div>
   );
}
