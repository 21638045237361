import { FormControlLabel, Skeleton, Switch } from '@mui/material';
import { useMemo, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, LabelList, Legend, XAxis, YAxis } from 'recharts';

export function DistributionByHexbinValue2(props: { values: any[] | null; isFullscreen: boolean; parentWidth: number }) {
   const [showValues, setShowValues] = useState(false);
   const chartData = useMemo(() => {
      if (!props.values) return null;

      return props.values
         .filter((value: any) => value.displayChart === true)
         .map((item) => ({
            label: item.label,
            total: Math.round(item.total),
            lessThanMinus10: item.lessThanMinus10,
            lessThan10: item.lessThan10,
            moreThan10: item.moreThan10,
         }));
   }, [props.values]);

   if (!props.values || !chartData) return null;
   if (!props.values?.length) return <Skeleton className="loading-chart" style={{ height: 500, width: props.parentWidth }}></Skeleton>;

   const formatValue = (obj: any) => {
      if (obj.percentage === 0 || obj.percentage < 1) return null;
      if (showValues) return `${Math.round(obj.value).toLocaleString()}`;
      return `${Math.round(obj.percentage).toFixed(0).toLocaleString()}%`;
   };

   const formatLabel = (value: any, entry: any, index: any) => {
      var friendlyText = '';
      switch (value) {
         case 'lessThanMinus10.percentage':
            friendlyText = 'Negative';
            break;
         case 'lessThan10.percentage':
            friendlyText = 'Breakeven';
            break;
         case 'moreThan10.percentage':
            friendlyText = 'Positive';
            break;
         default:
            friendlyText = value;
            break;
      }
      return <span style={{ color: 'grey' }}>{friendlyText}</span>;
   };

   const barWidth = 30;

   return (
      <div className="flex column ">
         <div className="flex end">
            <FormControlLabel control={<Switch color="secondary" value={showValues} onChange={() => setShowValues(!showValues)} />} label={<span style={{ color: '#a8a8a8' }}>Values</span>} />
         </div>
         <ComposedChart width={props.isFullscreen ? window.innerWidth - 200 : props.parentWidth} height={props.isFullscreen ? window.innerHeight - 200 : 400} data={chartData} layout="vertical">
            <CartesianGrid vertical={true} horizontal={false} stroke="grey" />

            <XAxis type="number" axisLine={false} tickLine={false} tick={{ fill: '#a8a8a8' }} domain={[0, 100]} tickFormatter={(value) => value.toLocaleString()} />
            <YAxis axisLine={false} tickLine={false} dataKey="label" tick={{ fill: '#a8a8a8' }} type="category" width={100} />
            {/* <Tooltip content={tooltipContent} /> */}
            <Legend formatter={formatLabel} />
            <Bar dataKey={`lessThanMinus10.percentage`} stackId="a" fill="var(--hex-red)" barSize={barWidth}>
               <LabelList dataKey={`lessThanMinus10`} position="center" formatter={formatValue} style={{ fill: 'black', fontSize: '12px', fontWeight: '700' }} />
            </Bar>
            <Bar dataKey="lessThan10.percentage" stackId="a" fill="var(--hex-yellow)" barSize={barWidth}>
               <LabelList dataKey="lessThan10" position="center" formatter={formatValue} style={{ fill: 'black', fontSize: '12px', fontWeight: '700' }} />
            </Bar>
            <Bar dataKey="moreThan10.percentage" stackId="a" fill="var(--hex-teal)" barSize={barWidth}>
               <LabelList dataKey="moreThan10" position="center" formatter={formatValue} style={{ fill: 'black', fontSize: '12px', fontWeight: '700' }} />
            </Bar>
         </ComposedChart>
      </div>
   );
}
