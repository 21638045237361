import { SignInModel } from '../models/signin.model';
import jwt_decode from 'jwt-decode';
import { UpdatePassword } from '../models/updatePassword.model';
import { TokenContent } from '../models/token.model';
import { GoogleResponse } from '../models/googleLogin.model';
import { fetchWithValidation } from './fetch.service';

const API_URL = process.env.REACT_APP_API as String;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

export const authStatus = (): { authenticated: Boolean; content?: TokenContent | null } => {
   let token = localStorage.getItem('token');
   if (!token) return { authenticated: false };
   let decodedToken = jwt_decode(token) as any;
   if (decodedToken.exp === undefined) return { authenticated: false };
   let currentDate = new Date();
   if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return { authenticated: false };
   } else {
      return { authenticated: true, content: decodedToken };
   }
};

export const signin = async (model: SignInModel): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(model),
   };

   return fetch(`${API_URL}auth/login`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const signout = async (): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };

   return fetch(`${API_URL}auth/logout`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const refreshToken = async (): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };

   return fetchWithValidation(`${API_URL}auth/refresh`, requestOptions);
};

export const refreshTokenInit = async (): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };

   return fetch(`${API_URL}auth/refresh`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const sendForgotPwdEmail = async (email: string): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({
         email: email,
      }),
   };

   return fetch(`${API_URL}auth/password-reset`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const checkPwdResetToken = async (resetToken: string): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({
         token: resetToken,
      }),
   };

   return fetchWithValidation(`${API_URL}auth/password-reset/check-token`, requestOptions);
};

export const restPassword = async (userId: string, password: string): Promise<any> => {
   const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({
         newPassword: password,
      }),
   };

   return fetchWithValidation(`${API_URL}users/password-reset/${userId}`, requestOptions);
};

export const updatePassword = async (userId: string, model: UpdatePassword): Promise<any> => {
   const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(model),
   };

   return fetchWithValidation(`${API_URL}users/password/${userId}`, requestOptions);
};

export const googleLogin = async (googleResponse: GoogleResponse): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(googleResponse),
   };

   return fetchWithValidation(`${API_URL}auth/google-login`, requestOptions);
};

export const getGoogleClientId = (): string => {
   return GOOGLE_CLIENT_ID;
};
