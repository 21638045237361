import { useEffect, useState } from 'react';
import { FIConfig, FeasibilityInstance, Project } from '../../../../../models/project.model';
import { Button, Card, CardContent, IconButton, Typography, Link, CircularProgress, Tooltip, Autocomplete, TextField, Avatar } from '@mui/material';
import { Add, Check, Clear, Undo, Wysiwyg } from '@mui/icons-material';
import { ConfirmationPopUp } from '../../../../shared/ui/ConfirmationPopUp';
import { deleteFeasibilityInstance, runFeasibilityInstance, updateFeasibilityInstance } from '../../../../../services/project.service';
import { FEASIBILITY_STUDY_STATUS } from '../../../../../constants/project';
import { FeasibilityInstanceDetails } from './FeasibilityInstanceDetails';
import { FeasibilityInstanceLogs } from './FeasibilityInstanceLogs';
import { RunFeasibilityInstanceForm } from './RunFeasibilityInstanceForm';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getStatusColor } from '../../../../../helpers/helper';
import { AuthorAvatar } from '../../../../shared/ui/AuthorAvatar';
import GoogleIcon from '@mui/icons-material/Google';
import { FoursquareToken } from '../../../../../models/token.model';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';

export function FeasibilityInstances(props: { parentHeight: number; project: Project; feasibilityInstances: FeasibilityInstance[]; setFeasibilityInstances: CallableFunction; runFinancial: (instance: FeasibilityInstance) => void; foursquareToken: FoursquareToken | null }) {
   const [logsOpen, setLogsOpen] = useState(false);
   const [configOpen, setConfigOpen] = useState(false);
   const [selectedInstance, setSelectedInstance] = useState<FeasibilityInstance | null>(null);
   const [openApproveMsg, setOpenApproveMsg] = useState(false);
   const [openUnapproveMsg, setOpenUnapproveMsg] = useState(false);
   const [openDeleteMsg, setOpenDeleteMsg] = useState(false);
   const [openUndoMsg, setOpenUndoMsg] = useState(false);
   const [openRunForm, setOpenRunForm] = useState(false);
   const [statusFiler, setStatusFilter] = useState<string>('');
   const [gridHeight, setGridHeight] = useState<number>(0);

   const snackbar = useSnackBar();

   useEffect(() => {
      function updateGridHeight() {
         setGridHeight(props.parentHeight - 53);
      }

      updateGridHeight();
      window.addEventListener('resize', updateGridHeight);
      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, [props.project, props.feasibilityInstances, props.parentHeight]);

   const updateStatus = async (status: string, msg: string) => {
      try {
         if (!selectedInstance) return;
         await updateFeasibilityInstance(props.project.id, selectedInstance.instanceId, status, null);
         props.feasibilityInstances.find((instance) => instance.instanceId === selectedInstance.instanceId)!.status = status;
         props.setFeasibilityInstances([...props.feasibilityInstances]);
         snackbar.success(msg);
      } catch (error) {
         snackbar.error('error! see the logs');
         console.log(error);
      }
      setSelectedInstance(null);
   };

   const handleDelete = async () => {
      try {
         if (!selectedInstance) return;
         await deleteFeasibilityInstance(props.project.id, selectedInstance.instanceId);
         props.setFeasibilityInstances(props.feasibilityInstances.filter((instance) => instance.instanceId !== selectedInstance.instanceId));
         snackbar.success('Instance Deleted');
      } catch (error) {
         snackbar.error('Error deleting the instance');
         console.log(error);
      }
      setSelectedInstance(null);
   };

   const handleApprove = () => {
      updateStatus(FEASIBILITY_STUDY_STATUS.APPROVED, 'Instance Approved');
   };

   const handleUnapprove = () => {
      updateStatus(FEASIBILITY_STUDY_STATUS.UNAPPROVED, 'Instance Unapproved');
   };

   const handleUndo = () => {
      updateStatus(FEASIBILITY_STUDY_STATUS.COMPLETED, 'Instance Status Updated');
   };

   const runFormOnSubmit = async (data: FIConfig, boundaryType: string | null, boundaryName: string | null, boundaryContent: string | null, customBslContent: string | null, customBslType: string | null) => {
      try {
         snackbar.info('New instance submitted');
         await runFeasibilityInstance(props.project.id, data, boundaryType, boundaryName, boundaryContent, customBslContent, customBslType);
         snackbar.success('New Instance is running');
      } catch (error) {
         console.log(error);
         alert('error occur' + error);
      }
   };

   return (
      <>
         <div
            style={{
               maxHeight: gridHeight,
               minHeight: gridHeight,
               height: gridHeight,
               overflowY: 'auto',
            }}>
            <div className="flex between items-center p-3 pb-0">
               <Button variant="outlined" size="medium" color="primary" startIcon={<Add />} onClick={() => setOpenRunForm(true)}>
                  New Instance
               </Button>
               <Autocomplete
                  id="filter-instance"
                  options={[
                     {
                        value: FEASIBILITY_STUDY_STATUS.APPROVED,
                        label: 'Approved',
                     },
                     {
                        value: FEASIBILITY_STUDY_STATUS.COMPLETED,
                        label: 'Completed',
                     },
                     {
                        value: FEASIBILITY_STUDY_STATUS.RUNNING,
                        label: 'Running',
                     },
                     {
                        value: FEASIBILITY_STUDY_STATUS.UNAPPROVED,
                        label: 'Unapproved',
                     },
                     {
                        value: FEASIBILITY_STUDY_STATUS.ERROR,
                        label: 'Error',
                     },
                  ]}
                  getOptionLabel={(option) => option.label}
                  filterOptions={(options, { inputValue }) => options.filter((option) => option.label.includes(inputValue))}
                  renderInput={(params) => <TextField {...params} label="Filter by status" />}
                  style={{ marginRight: '10px', minWidth: '250px' }}
                  onChange={(event, value) => setStatusFilter(value?.value || '')}
               />
            </div>
            <div className="instances p-3">
               {props.feasibilityInstances
                  .filter((filter: FeasibilityInstance) => statusFiler === '' || statusFiler === filter.status)
                  .map((element) => (
                     <Card
                        key={element.instanceId}
                        className={`instance-card mb-4 ${[FEASIBILITY_STUDY_STATUS.ERROR, FEASIBILITY_STUDY_STATUS.UNAPPROVED].includes(element.status) ? 'hidden' : ''} ${element.status === FEASIBILITY_STUDY_STATUS.APPROVED ? 'approved' : ''}`}
                        sx={{
                           borderColor: `${getStatusColor(element.status)}`,
                        }}>
                        <CardContent className="instance-card-content">
                           <div className="flex column between items-start">
                              <div className="flex between items-center w-100">
                                 <Typography color="primary" variant="h5" component="div">
                                    <Link
                                       className="flex items-center gap-small"
                                       underline="hover"
                                       onClick={() => {
                                          setSelectedInstance(element);
                                          setConfigOpen(true);
                                       }}>
                                       <span>Instance {element.instanceId}</span>
                                       <LaunchIcon fontSize="small" />
                                    </Link>
                                 </Typography>
                                 {element.user ? (
                                    <AuthorAvatar firstname={element.user.firstname} lastname={element.user.lastname} />
                                 ) : (
                                    <Tooltip placement="left" title={'Hexvarium'}>
                                       <Avatar>H</Avatar>
                                    </Tooltip>
                                 )}
                              </div>
                              <Typography sx={{ fontSize: '12px' }}>{`${new Date(element.createdAt).toLocaleDateString()} ${new Date(element.createdAt).toLocaleTimeString()}`}</Typography>

                              <div className="notes-container">
                                 {element?.notes?.length && element?.notes?.length > 0 ? (
                                    <Tooltip title={element?.notes?.length && element.notes?.length > 120 && element?.notes}>
                                       <div className={`notes`}>{element.notes.substring(0, 120) + (element.notes?.length > 120 ? '...' : '')}</div>
                                    </Tooltip>
                                 ) : (
                                    <Link
                                       className="flex items-center add-note"
                                       underline="hover"
                                       onClick={() => {
                                          setSelectedInstance(element);
                                          setConfigOpen(true);
                                       }}>
                                       <span>Add a note</span>
                                    </Link>
                                 )}
                              </div>

                              <div className="mt-3 flex between items-center w-100">
                                 <div
                                    key={element.instanceId}
                                    className="status"
                                    style={{
                                       color: getStatusColor(element.status),
                                       borderColor: getStatusColor(element.status),
                                    }}>
                                    {element.status}
                                 </div>
                                 <div className="flex end items-center w-100">
                                    {element.status === FEASIBILITY_STUDY_STATUS.APPROVED && props.foursquareToken && (
                                       <Tooltip title="New Financial Scenario">
                                          <IconButton size="small" onClick={() => props.runFinancial(element)}>
                                             <AttachMoneyIcon fontSize="medium" />
                                          </IconButton>
                                       </Tooltip>
                                    )}

                                    {element.status === FEASIBILITY_STUDY_STATUS.ERROR && (
                                       <Tooltip title="Delete">
                                          <IconButton
                                             onClick={() => {
                                                setSelectedInstance(element);
                                                setOpenDeleteMsg(true);
                                             }}>
                                             <DeleteIcon fontSize="medium" />
                                          </IconButton>
                                       </Tooltip>
                                    )}
                                    {element.logs?.replace(/\r?\n|\r|\s/g, '').length > 0 && (
                                       <Tooltip title="Show Logs">
                                          <IconButton
                                             size="small"
                                             onClick={() => {
                                                setSelectedInstance(element);
                                                setLogsOpen(true);
                                             }}>
                                             <Wysiwyg fontSize="medium" />
                                          </IconButton>
                                       </Tooltip>
                                    )}

                                    {(element.status === FEASIBILITY_STUDY_STATUS.APPROVED || element.status === FEASIBILITY_STUDY_STATUS.UNAPPROVED || element.status === FEASIBILITY_STUDY_STATUS.COMPLETED) && (
                                       <Tooltip title="Open Google Container">
                                          <IconButton target="_blank" href={'https://console.cloud.google.com/storage/browser/50_50_workflow_projects/' + props.project.id + '-' + element.instanceId}>
                                             <GoogleIcon fontSize="small" />
                                          </IconButton>
                                       </Tooltip>
                                    )}

                                    {element.status === FEASIBILITY_STUDY_STATUS.COMPLETED && (
                                       <>
                                          <Tooltip title="Unapprove">
                                             <IconButton
                                                color="default"
                                                size="small"
                                                onClick={() => {
                                                   setOpenUnapproveMsg(true);
                                                   setSelectedInstance(element);
                                                }}>
                                                <Clear fontSize="medium" />
                                             </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Approve">
                                             <IconButton
                                                size="small"
                                                onClick={() => {
                                                   setOpenApproveMsg(true);
                                                   setSelectedInstance(element);
                                                }}>
                                                <Check fontSize="medium" />
                                             </IconButton>
                                          </Tooltip>
                                       </>
                                    )}

                                    {(element.status === FEASIBILITY_STUDY_STATUS.APPROVED || element.status === FEASIBILITY_STUDY_STATUS.UNAPPROVED) && (
                                       <Tooltip title="Undo">
                                          <IconButton
                                             className="ml-2"
                                             size="small"
                                             onClick={() => {
                                                setOpenUndoMsg(true);
                                                setSelectedInstance(element);
                                             }}>
                                             <Undo fontSize="small" />
                                          </IconButton>
                                       </Tooltip>
                                    )}

                                    {element.status === FEASIBILITY_STUDY_STATUS.RUNNING && <CircularProgress className="ml-2" size={'18px'} color="primary" disableShrink />}
                                 </div>
                              </div>
                           </div>
                        </CardContent>
                     </Card>
                  ))}
            </div>
            {selectedInstance !== null && (
               <>
                  <FeasibilityInstanceDetails projectId={props.project.id} instance={selectedInstance} setFeasibilityInstances={props.setFeasibilityInstances} open={configOpen} setOpen={setConfigOpen} />
                  <FeasibilityInstanceLogs
                     instance={{
                        ...selectedInstance,
                        logs: (props.feasibilityInstances.find((instance: FeasibilityInstance) => instance.instanceId === selectedInstance.instanceId) as FeasibilityInstance)?.logs,
                     }}
                     open={logsOpen}
                     setOpen={setLogsOpen}
                  />
               </>
            )}
            <ConfirmationPopUp openMsg={openDeleteMsg} setOpenMsg={setOpenDeleteMsg} handleConfirm={handleDelete} warning={true} msg="Are you sure you want to delete this feasibility instance?" title="Delete DMA Instance" />

            <ConfirmationPopUp openMsg={openUnapproveMsg} setOpenMsg={setOpenUnapproveMsg} handleConfirm={handleUnapprove} warning={true} msg="Are you sure you want to unapprove this feasibility study instance?" title="Unapprove DMA Instance" />
            <ConfirmationPopUp openMsg={openApproveMsg} setOpenMsg={setOpenApproveMsg} handleConfirm={handleApprove} warning={false} msg="Are you sure you want to approve this feasibility study instance?" title="Approve DMA Instance" />
            <ConfirmationPopUp openMsg={openUndoMsg} setOpenMsg={setOpenUndoMsg} handleConfirm={handleUndo} warning={true} msg="Are you sure you want to undo this feasibility study instance status ?" title="Undo DMA Instance status" />
            <RunFeasibilityInstanceForm open={openRunForm} setOpen={setOpenRunForm} onSubmit={runFormOnSubmit} projectName={props.project.name} />
         </div>
      </>
   );
}
