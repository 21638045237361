import { DynamicMarketAssumption, MarketAssumptionsModel, Project } from '../../../models/project.model';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Buffer } from 'buffer';

export function MarketAssumptions(props: { project: Project; marketAssumptions?: MarketAssumptionsModel | null; detailed?: boolean }) {
   const downloadGeojson = (content: string, fileName: string) => {
      const element = document.createElement('a');
      const file = new Blob([Buffer.from(content, 'base64')], {
         type: 'text/plain',
      });
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
   };

   return (
      <div id="market-assumptions" className="flex gap market-assumptions">
         {props.marketAssumptions && (
            <div className="section-card flex column gap w-100">
               <div className="flex items-center between">
                  <h2>MARKET ASSUMPTIONS</h2>
               </div>
               <div className="flex gap w-100">
                  <TableContainer>
                     <Table>
                        <TableHead>
                           <TableRow>
                              <TableCell>
                                 <Typography variant="h6" gutterBottom>
                                    Inputs
                                 </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {props.marketAssumptions.feasibilityInstance && props.detailed && (
                              <>
                                 <TableRow>
                                    <TableCell>H3 Resolution</TableCell>
                                    <TableCell>{props.marketAssumptions.feasibilityInstance.configJSON.h3_resolution}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Maximum length of drops</TableCell>
                                    <TableCell>{Number(props.marketAssumptions.feasibilityInstance.configJSON.max_drop_length_m).toLocaleString()}</TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell>Maximum length of extended drop</TableCell>
                                    <TableCell>{props.marketAssumptions.feasibilityInstance.configJSON.max_extended_drop_length_m ?? 0}</TableCell>
                                 </TableRow>
                              </>
                           )}
                           <TableRow>
                              <TableCell>Basic</TableCell>
                              <TableCell>${Number(props.marketAssumptions.financialModel.inputs.arpu?.inferior).toLocaleString()}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>Good</TableCell>
                              <TableCell>${Number(props.marketAssumptions.financialModel.inputs.arpu?.value).toLocaleString()}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>Better</TableCell>
                              <TableCell>${Number(props.marketAssumptions.financialModel.inputs.arpu?.direct).toLocaleString()}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>Best</TableCell>
                              <TableCell>${Number(props.marketAssumptions.financialModel.inputs.arpu?.superlative).toLocaleString()}</TableCell>
                           </TableRow>

                           {props.marketAssumptions.financialModel.inputs.arpu?.gig !== undefined && (
                              <TableRow>
                                 <TableCell>Gig+</TableCell>
                                 <TableCell>${Number(props.marketAssumptions.financialModel.inputs.arpu.gig).toLocaleString()}</TableCell>
                              </TableRow>
                           )}

                           <TableRow>
                              <TableCell>Aerial $/mile</TableCell>
                              <TableCell>${Number(props.marketAssumptions.financialModel.inputs.construction_cost_per_mile_aerial).toLocaleString()}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>Underground $/mile</TableCell>
                              <TableCell>${Number(props.marketAssumptions.financialModel.inputs.construction_cost_per_mile_underground).toLocaleString()}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>Aerial % of mile</TableCell>
                              <TableCell>{Math.round(Number(props.marketAssumptions.financialModel.inputs.aerial_pct_fttc) * 100)}%</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>Underground % of mile</TableCell>
                              <TableCell>{Math.round(100 - Number(props.marketAssumptions.financialModel.inputs.aerial_pct_fttc) * 100)}%</TableCell>
                           </TableRow>

                           {props.marketAssumptions.financialModel.inputs.take_rate?.residential_override !== undefined && Number(props.marketAssumptions.financialModel.inputs.take_rate?.residential_override) > 0 && (
                              <TableRow>
                                 <TableCell>Take Rate Residential</TableCell>
                                 <TableCell>{Number(props.marketAssumptions.financialModel.inputs.take_rate?.residential_override) * 100}%</TableCell>
                              </TableRow>
                           )}
                           {props.marketAssumptions.financialModel.inputs.take_rate?.business_override !== undefined && Number(props.marketAssumptions.financialModel.inputs.take_rate?.business_override) > 0 && (
                              <TableRow>
                                 <TableCell>Take Rate Business</TableCell>
                                 <TableCell>{Number(props.marketAssumptions.financialModel.inputs.take_rate?.business_override) * 100}%</TableCell>
                              </TableRow>
                           )}

                           {props.marketAssumptions.feasibilityInstance && (
                              <>
                                 <TableRow>
                                    <TableCell>State</TableCell>
                                    <TableCell>{props.marketAssumptions.feasibilityInstance.configJSON.boundary_spec.state_code}</TableCell>
                                 </TableRow>

                                 <TableRow>
                                    <TableCell>Boundary Type</TableCell>
                                    <TableCell>{props.marketAssumptions.feasibilityInstance.configJSON.boundary_spec.boundary_type}</TableCell>
                                 </TableRow>
                                 {props.marketAssumptions.feasibilityInstance.configJSON.boundary_spec.boundary_type === 'county' && (
                                    <TableRow>
                                       <TableCell>County</TableCell>
                                       <TableCell>{props.marketAssumptions.feasibilityInstance.configJSON.boundary_spec.county_name}</TableCell>
                                    </TableRow>
                                 )}
                                 {props.marketAssumptions.feasibilityInstance.configJSON.boundary_spec.boundary_type === 'city' && (
                                    <TableRow>
                                       <TableCell>City</TableCell>
                                       <TableCell>{props.marketAssumptions.feasibilityInstance.configJSON.boundary_spec.city_name}</TableCell>
                                    </TableRow>
                                 )}
                                 {props.marketAssumptions.feasibilityInstance.configJSON.boundary_spec.boundary_type === 'custom' && props.marketAssumptions?.feasibilityInstance?.boundaryContent?.length > 0 && (
                                    <TableRow>
                                       <TableCell>Boundary File</TableCell>
                                       <TableCell>
                                          <a
                                             className="flex items-center"
                                             color="primary"
                                             onClick={() =>
                                                props.marketAssumptions?.feasibilityInstance?.boundaryContent &&
                                                downloadGeojson(props.marketAssumptions.feasibilityInstance.boundaryContent, `${props.marketAssumptions.feasibilityInstance.boundaryName}.${props.marketAssumptions.feasibilityInstance.boundaryType}`)
                                             }>
                                             boundary.{props.marketAssumptions.feasibilityInstance.boundaryType} <AttachFileIcon fontSize="small" />
                                          </a>
                                       </TableCell>
                                    </TableRow>
                                 )}
                              </>
                           )}
                        </TableBody>
                     </Table>
                  </TableContainer>
                  {props.marketAssumptions.dynamicMarketAssumptions.map((assumptions: DynamicMarketAssumption, index: number) => {
                     return (
                        <TableContainer key={index}>
                           <Table>
                              <TableHead>
                                 <TableRow>
                                    <TableCell>
                                       <Typography variant="h6" gutterBottom>
                                          {assumptions.label}
                                       </Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {assumptions.values.map((value: { label: string; value: number; unit?: string; unitAfter?: boolean }, index: number) => {
                                    return (
                                       <TableRow key={index}>
                                          <TableCell>{value.label}</TableCell>
                                          <TableCell>
                                             {Number(value.value) < 0 && !value.unitAfter && value.unit !== undefined ? `-` : ''}
                                             {value.unit !== undefined && !value.unitAfter ? value.unit : ''}
                                             {Number((!value.unitAfter && value.unit && Number(value.value < 0) ? -1 : 1) * Math.round(Number(value.value))).toLocaleString()}
                                          </TableCell>
                                       </TableRow>
                                    );
                                 })}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     );
                  })}
               </div>
            </div>
         )}
      </div>
   );
}
