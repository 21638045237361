import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { MarketProfileModel, Project } from '../../../models/project.model';
import { formatNumber } from '../../../helpers/helper';

export function MarketProfile(props: { project: Project; marketProfile?: MarketProfileModel | null }) {
   return (
      <div id="market-profile" className="flex gap market-assumptions">
         {props.marketProfile && (
            <div className="section-card flex column gap w-100">
               <div className="flex items-center between w-100">
                  <h2>MARKET PROFILE</h2>
               </div>
               <div className="flex gap w-100">
                  <TableContainer>
                     <Table>
                        <TableHead>
                           <TableRow>
                              <TableCell>
                                 <Typography variant="h2" gutterBottom>
                                    {Math.round(Number(props.marketProfile.passings.business) + Number(props.marketProfile.passings.residential)).toLocaleString()}
                                 </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           <TableRow>
                              <TableCell>{props.marketProfile.passings.residential.toLocaleString()}</TableCell>
                              <TableCell>Residential Passings</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>{props.marketProfile.passings.business.toLocaleString()}</TableCell>
                              <TableCell>Business Passings</TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
                  <TableContainer>
                     <Table>
                        <TableHead>
                           <TableRow>
                              <TableCell>
                                 <Typography variant="h2" gutterBottom>
                                    {Number(props.marketProfile.homes.owned)}%
                                 </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           <TableRow>
                              <TableCell>{props.marketProfile.homes.owned}%</TableCell>
                              <TableCell style={{ width: '100%' }}>Residential Homes Owned</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>{props.marketProfile.homes.rented}%</TableCell>
                              <TableCell style={{ width: '100%' }}>Residential Homes Rented</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>${Math.round(Number(props.marketProfile.homes.medianIncome)).toLocaleString()}</TableCell>
                              <TableCell style={{ width: '100%' }}>Median Income</TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
                  <TableContainer>
                     <Table>
                        <TableHead>
                           <TableRow>
                              <TableCell>
                                 <Typography variant="h2" gutterBottom>
                                    {Number(props.marketProfile.family.single)}%
                                 </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           <TableRow>
                              <TableCell>{props.marketProfile.family.single}%</TableCell>
                              <TableCell>Single Family</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>{props.marketProfile.family.multi}%</TableCell>
                              <TableCell>Multi Family (Includes MDUs)</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>${Math.round(Number(props.marketProfile.family.medianIncome)).toLocaleString()}</TableCell>
                              <TableCell>Median Home Value</TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
               </div>
            </div>
         )}
      </div>
   );
}
