import separation from '../static/assets/images/footer.png';
import { useLocation } from 'react-router-dom';
import { ReactComponent as FooterLogo } from '../static/assets/logo/footer-logo-big.svg';

export function Bottom(props: { small?: boolean }) {
   const location = useLocation();

   if (!location.pathname.includes('admin')) {
      return (
         <div className={`bottom ${props.small ? 'small' : ''}`}>
            {!props.small && <p>Building broadband for you, with you</p>}
            <FooterLogo />
         </div>
      );
   } else {
      return <div></div>;
   }
}
