import { createTheme } from '@mui/material/styles';

export const customTheme = createTheme({
   palette: {
      primary: {
         main: '#022222',
         dark: '#022222',
         light: '#053c3c',
      },
      secondary: {
         main: '#749074',
      },
      error: {
         main: '#ff5449', // Red
         light: '#ec6666', // Light Red
      },
      warning: {
         main: '#e6ff31', // Yellow
      },
      info: {
         main: '#147ad6', // Blue
         light: '#79d2de', // Light Blue
      },
      success: {
         main: '#022222', // Dark Green
      },
   },
});
