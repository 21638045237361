import { Alert, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WarningMessage } from '../../models/warningMessage.model';
import { useNavigate } from 'react-router-dom';
import { SimpleModal } from '../shared/ui/modals/SimpleModal';
import { checkPwdResetToken, restPassword } from '../../services/auth.service';
import { useSnackBar } from '../../contexts/SnackbarContext';

export function PasswordResetToken() {
   const [form, setForm] = useState({
      newPassword: '',
      newPasswordConfirm: '',
   });
   const [topMessage, setTopMessage] = useState({
      error: false,
      message: null,
   } as WarningMessage);
   const { token } = useParams();
   const [userId, setUserId] = useState('');
   const navigate = useNavigate();
   const snackbar = useSnackBar();

   useEffect(() => {
      checkToken();
   }, []);

   useEffect(() => {
      if (topMessage.message) {
         setTimeout(() => setTopMessage({ error: false, message: null }), 3000);
      }
   }, [topMessage]);

   const submit = async (e: any) => {
      e.preventDefault();
      if (userId !== '' && form.newPassword === form.newPasswordConfirm) {
         const response = await restPassword(userId, form.newPassword);
         if (!response.error) {
            snackbar.success('Your password has been reset!');
            setTopMessage({ error: false, message: response.message });
            navigate('/login');
         } else {
            setTopMessage({ error: true, message: response.error });
         }
      }
   };

   const checkToken = async () => {
      if (token) {
         try {
            const response = await checkPwdResetToken(token);
            if (!response.error) {
               setUserId(response.userId);
            } else {
               snackbar.error('Invalid token : ' + response.error);
               navigate('/login');
            }
         } catch (error) {
            snackbar.error('unexpected error occured, please contact the support team');
            navigate('/login');
         }
      }
   };

   return (
      <SimpleModal open={true}>
         <div>
            {topMessage.message !== null && (
               <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error' : 'success'}>
                  {topMessage.message}
               </Alert>
            )}
            <form onSubmit={submit} className="flex column mt-2">
               <h2>Reset your password</h2>
               <TextField
                  className="mt-4"
                  label="New Password"
                  type={'password'}
                  variant="outlined"
                  required
                  value={form.newPassword}
                  onChange={(event: any) => setForm({ ...form, newPassword: event.target.value })}
               />
               <TextField
                  className="mt-2 mb-4"
                  label="Confirm New Password"
                  type={'password'}
                  variant="outlined"
                  required
                  value={form.newPasswordConfirm}
                  onChange={(event: any) =>
                     setForm({
                        ...form,
                        newPasswordConfirm: event.target.value,
                     })
                  }
                  error={form.newPasswordConfirm.length > 0 && form.newPasswordConfirm !== form.newPassword}
                  helperText={form.newPasswordConfirm.length && form.newPasswordConfirm !== form.newPassword ? 'Passwords need to match.' : ''}
               />
               <Button className="mt-2" variant="contained" type="submit">
                  RESET
               </Button>
            </form>
         </div>
      </SimpleModal>
   );
}
