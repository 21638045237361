import { useState } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackBar } from '../../../contexts/SnackbarContext';
const CopyToClipboardButton = (props: { value: string }) => {
   const snackbar = useSnackBar();

   const handleClick = () => {
      navigator.clipboard.writeText(props.value);
      snackbar.success('Copied to clipboard');
   };

   return (
      <>
         <IconButton onClick={handleClick} color="inherit" size="medium">
            <ContentCopyIcon />
         </IconButton>
      </>
   );
};

export default CopyToClipboardButton;
