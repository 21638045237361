import { Bottom } from '../components/Bottom';
import { ConnectModal } from '../components/login/ConnectModal';

export function Login() {
   return (
      <div className="flex center items-center">
         <ConnectModal open={true} signIn={true} />
         <Bottom small />
      </div>
   );
}
