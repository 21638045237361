import { Alert, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Role, User, UserAccount, UserRole } from '../../../../models/user.model';
import { getUser, putUser } from '../../../../services/user.service';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { isEmail } from '../../../../helpers/helper';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRoles } from '../../../../services/role.service';
import { Account } from '../../../../models/account.model';
import { getAccounts } from '../../../../services/account.service';
import { useSnackBar } from '../../../../contexts/SnackbarContext';
import { Log } from '../../../../models/log.model';
import { getUserLogs } from '../../../../services/logs.service';
import { Logs } from '../../Logs';

export function UserSingle() {
   const { id } = useParams();

   const [user, setUser] = useState<User>();
   const [roles, setRoles] = useState<Role[]>([]);
   const [accounts, setAccounts] = useState<Account[]>([]);
   const [newPassword, setNewPassword] = useState<any>(null);
   const [hasChanged, setHasChanged] = useState<boolean>(false);
   const navigate = useNavigate();
   const snackbar = useSnackBar();
   const location = useLocation();

   const [logs, setLogs] = useState<Log[] | null>(null);

   async function retrieveUser() {
      if (!id) return;
      try {
         var [userResponse, rolesResponse, accountsResponse] = await Promise.all([
            getUser(id),
            getRoles(),
            getAccounts(
               {
                  page: 0,
                  rowsPerPage: 100,
                  search: '',
                  orderBy: 'name',
                  asc: true,
               },
               false
            ),
         ]);

         userResponse = userResponse;
         rolesResponse = rolesResponse;
         accountsResponse = accountsResponse;

         if (!userResponse.error && !rolesResponse.error && !accountsResponse.error) {
            setUser(userResponse.user);
            setRoles(rolesResponse.roles);
            setAccounts(accountsResponse.accounts);

            const stateData = location.state?.newUser;
            if (stateData && stateData.userId === userResponse.user.id) {
               setNewPassword(stateData.password);
               snackbar.info('Make sure to save the temporary password.');
            }
         }
      } catch (e) {}
   }

   async function updateUser(e: any) {
      e.preventDefault();
      if (!user) return;
      try {
         const response = await putUser(user);

         if (!response.error) {
            snackbar.success('User successfully updated.');
            setNewPassword(response.newPassword ?? null);
            setUser({ ...user, updatedAt: new Date() });
            setHasChanged(false);
         } else {
            snackbar.error('Error while updating the user.');
         }
      } catch (e) {}
   }

   useEffect(() => {
      retrieveUser();
   }, []);

   useEffect(() => {
      if (!user) return;
      const fetchLogs = async () => {
         try {
            const fetchedLogs = await getUserLogs(user.id);
            setLogs(fetchedLogs ?? []);
         } catch (error) {
            console.error(error);
            setLogs([]);
         }
      };

      fetchLogs();
   }, [user]);

   return (
      <div className="admin-top">
         <div className="flex between items-center w-100 mb-4">
            <Button onClick={() => navigate('/admin/users', { replace: true })} size="medium" variant="text" startIcon={<KeyboardBackspaceIcon />}>
               Users
            </Button>
         </div>
         {user !== undefined && (
            <div className="flex gap wrap">
               <form className="flex-1" onSubmit={updateUser} onChange={() => setHasChanged(true)}>
                  <h2>User details</h2>
                  <p>ID: {user.id}</p>

                  <div className="mt-3 flex column">
                     <p>Creation: {new Date(user.createdAt?.toString() ?? '').toLocaleString() ?? 'null'}</p>
                     <p>Last Modification: {new Date(user.updatedAt?.toString() ?? '').toLocaleString() ?? 'null'}</p>
                  </div>

                  <div className="flex column mt-5 w-70">
                     {newPassword !== null && (
                        <Alert
                           className="mb-5"
                           variant="outlined"
                           severity={'success'}
                           action={
                              <Button
                                 className="flex items-center pt-1"
                                 color="inherit"
                                 variant="text"
                                 size="small"
                                 onClick={() => {
                                    navigator.clipboard.writeText(newPassword);
                                    snackbar.success('Password copied to clipboard.');
                                 }}>
                                 <span>COPY</span>
                              </Button>
                           }>
                           New password: <strong>{newPassword}</strong>
                        </Alert>
                     )}
                     <div className="flex between mb-3">
                        <TextField
                           className="mr-1 w-100"
                           required
                           label="First Name"
                           variant="outlined"
                           value={user.firstname}
                           onChange={(event: any) =>
                              setUser({
                                 ...user,
                                 firstname: event.target.value,
                              })
                           }
                        />
                        <TextField className="ml-1 w-100" required label="Last Name" variant="outlined" value={user.lastname} onChange={(event: any) => setUser({ ...user, lastname: event.target.value })} />
                     </div>
                     <TextField
                        label="Email"
                        type={'email'}
                        variant="outlined"
                        value={user.email}
                        onChange={(event: any) => setUser({ ...user, email: event.target.value })}
                        error={!isEmail(user.email) && user.email?.length > 0}
                        helperText={!isEmail(user.email) && user.email?.length > 0 ? 'Please provide a valid email address.' : ''}
                        required={true}
                     />
                     <div className="flex between">
                        <FormControl fullWidth className="mt-3 mr-1 w-100">
                           <InputLabel id="roles-label">Status</InputLabel>
                           <Select
                              labelId="roles-label"
                              value={user.isActive ? 'true' : 'false'}
                              label="Status"
                              onChange={(event: any, val: any) => {
                                 setHasChanged(true);
                                 setUser({
                                    ...user,
                                    isActive: val.props.value == 'true',
                                 });
                              }}>
                              <MenuItem value={'true'}>Active</MenuItem>
                              <MenuItem value={'false'}>Inactive</MenuItem>
                           </Select>
                        </FormControl>
                        <FormControl fullWidth className="mt-3 ml-1 w-100">
                           <InputLabel id="roles-label">Accounts</InputLabel>
                           <Select
                              labelId="roles-label"
                              value={user.userAccounts}
                              label="Accounts"
                              multiple
                              renderValue={(selected: UserAccount[]) => selected.map((userAccount: UserAccount) => userAccount.account.name).join(', ')}
                              onChange={(event: any, val: any) => {
                                 setHasChanged(true);
                                 var newAccounts: UserAccount[] = [];
                                 const changedAccountId = val.props.value;
                                 const exists = user.userAccounts.map((userAccount: UserAccount) => userAccount.accountId).includes(changedAccountId);

                                 if (!exists) {
                                    const accountToAdd = accounts.filter((account: Account) => account.id === changedAccountId)[0];
                                    newAccounts = [...user.userAccounts, { accountId: accountToAdd.id, account: accountToAdd }];
                                 } else {
                                    newAccounts = user.userAccounts.filter((userAccount: UserAccount) => userAccount.accountId !== changedAccountId);
                                 }

                                 setUser({ ...user, userAccounts: newAccounts });
                              }}>
                              {accounts.map((account: Account, index: number) => (
                                 <MenuItem key={index} value={account.id}>
                                    <Checkbox checked={user.userAccounts.map((userAccount: UserAccount) => userAccount.accountId).indexOf(account.id) > -1} />
                                    <ListItemText primary={account.name} />
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                        {/* <FormControl fullWidth className="mt-3 ml-1 w-100">
                           <InputLabel id="account-label" className="label">
                              Account
                           </InputLabel>
                           <Select
                              labelId="account-label"
                              defaultOpen={false}
                              value={user.account}
                              label="Account"
                              renderValue={(selected: Account) => selected.name}
                              onChange={(event: any, val: any) => {
                                 setHasChanged(true);
                                 setUser({
                                    ...user,
                                    account: accounts.find((account: Account) => account.id === val.props.value) as Account,
                                 });
                              }}
                           >
                              {accounts.map((account: Account, index: number) => {
                                 return (
                                    <MenuItem key={index} value={account.id}>
                                       {account.name}
                                    </MenuItem>
                                 );
                              })}
                           </Select>
                        </FormControl> */}
                     </div>
                     <FormControl fullWidth className="mt-3 w-100">
                        <InputLabel id="roles-label">Roles</InputLabel>
                        <Select
                           labelId="roles-label"
                           value={user.userRoles}
                           label="Status"
                           multiple
                           renderValue={(selected: UserRole[]) => selected.map((userRole: UserRole) => userRole.role.role).join(', ')}
                           onChange={(event: any, val: any) => {
                              setHasChanged(true);
                              var newRoles: UserRole[] = [];
                              const changedRoleId = val.props.value;
                              const exists = user.userRoles.map((userRole: UserRole) => userRole.roleId).includes(changedRoleId);

                              if (!exists) {
                                 const roleToAdd = roles.filter((role: Role) => role.id === changedRoleId)[0];
                                 newRoles = [...user.userRoles, { roleId: roleToAdd.id, role: roleToAdd }];
                              } else {
                                 newRoles = user.userRoles.filter((userRole: UserRole) => userRole.roleId !== changedRoleId);
                              }

                              setUser({ ...user, userRoles: newRoles });
                           }}>
                           {roles.map((role: Role, index: number) => (
                              <MenuItem key={index} value={role.id}>
                                 <Checkbox checked={user.userRoles.map((userRole: UserRole) => userRole.roleId).indexOf(role.id) > -1} />
                                 <ListItemText primary={role.role} />
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </div>
                  <Button className="mt-4" variant="contained" type="submit" disabled={!hasChanged}>
                     SAVE
                  </Button>
               </form>
               <div className="flex-1 flex column gap">
                  {logs !== null && (
                     <>
                        <h2>Logs</h2>
                        <Logs logs={logs} includeUser={false} />
                     </>
                  )}
               </div>
            </div>
         )}
      </div>
   );
}
