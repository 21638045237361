import { Skeleton } from '@mui/material';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis } from 'recharts';

export function NetValuePerAreaChart2(props: { values: any[] | null; isFullscreen: boolean; parentWidth: number; labeled?: boolean }) {
   if (!props.values) return null;
   if (!props.values?.length) return <Skeleton className="loading-chart" style={{ height: 500, transform: 'unset !important' }}></Skeleton>;

   const chartData = props.values.map((item) => ({
      label: item.label,
      total: item.value.total,
      perCustomer: item.value.perCustomer,
   }));

   const barWidth = 30;

   const formatValue = (value: number) => {
      if (value === 0) return '$0';
      const suffixes = ['', 'k', 'M', 'B', 'T'];

      const suffixIndex = Math.floor(Math.log10(Math.abs(value)) / 3);
      const formattedValue = (value / Math.pow(10, suffixIndex * 3)).toFixed(1);

      return `$${formattedValue}${suffixes[suffixIndex]}`;
   };

   return (
      <BarChart width={props.isFullscreen ? window.innerWidth - 200 : props.parentWidth} height={props.isFullscreen ? window.innerHeight - 200 : 400} data={chartData}>
         <CartesianGrid vertical={false} horizontal={true} stroke="grey" />
         <XAxis dataKey="label" interval={0} height={50} />
         <YAxis axisLine={false} tickLine={false} tickFormatter={formatValue} width={100} />
         <Bar dataKey="total" fill="var(--hex-yellow)" radius={[50, 50, 0, 0]} barSize={barWidth}>
            {props.labeled && <LabelList dataKey="total" position="left" formatter={formatValue} style={{ fill: 'grey' }} />}
         </Bar>
      </BarChart>
   );
}
