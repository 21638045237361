import { PatchModel } from '../models/path.model';
import { SearchModel } from '../models/search.model';
import { SignUpModel } from '../models/signup.model';
import { CreateUserFromAdminModel, User } from '../models/user.model';
import { fetchWithValidation } from './fetch.service';

const API_URL = process.env.REACT_APP_API as String;

export const getUsers = async (searchModel: SearchModel, accountIds?: string[]): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}users?search=${searchModel?.search}&offset=${(searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100)}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${searchModel?.orderBy}&asc=${searchModel?.asc}&accounts=${accountIds}`, requestOptions);
};

export const getUsersReport = async (searchModel: SearchModel, accountIds?: string[]): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // body: JSON.stringify(model),
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}users/user-report`, requestOptions);
};

export const getUser = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}users/${id}`, requestOptions);
};

export const createUserLink = async (model: CreateUserFromAdminModel): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model),
      credentials: 'include' as any,
   };

   return fetchWithValidation(`${API_URL}users/user-link`, requestOptions);
};

export const createUser = async (model: any): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model),
   };

   return fetchWithValidation(`${API_URL}users`, requestOptions);
};

export const patchUser = async (patch: PatchModel): Promise<any> => {
   const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(patch),
   };
   return fetchWithValidation(`${API_URL}users/${patch.id}`, requestOptions);
};

export const putUser = async (userPut: User): Promise<any> => {
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(userPut),
   };
   return fetchWithValidation(`${API_URL}users/${userPut.id}`, requestOptions);
};
