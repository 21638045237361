import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FeasibilityInstance } from '../../../../../models/project.model';
import { useEffect, useRef, useState } from 'react';
import CopyToClipboardButton from '../../../../shared/ui/CopyToClipboardButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FEASIBILITY_STUDY_STATUS } from '../../../../../constants/project';

export function FeasibilityInstanceLogs(props: { instance: FeasibilityInstance; open: boolean; setOpen: (open: boolean) => void }) {
   const { instance, open, setOpen } = props;
   const contentRef = useRef<HTMLDivElement>(null);

   const [hasClickedBottom, setHasClickedBottom] = useState(instance.status === FEASIBILITY_STUDY_STATUS.RUNNING);

   const scrollToBottom = () => {
      setHasClickedBottom(true);
      if (contentRef.current) {
         contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
   };

   useEffect(() => {
      if (hasClickedBottom && props.instance.logs) {
         scrollToBottom();
      }
   }, [props.instance.logs]);

   return (
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg" scroll="paper">
         <DialogTitle>
            Instance {instance.instanceId} Logs
            <CopyToClipboardButton value={instance.logs} />
            <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
               <CloseIcon />
            </IconButton>
         </DialogTitle>
         <DialogContent
            ref={contentRef}
            style={{
               maxHeight: '80vh',
               minHeight: '50vh',
               minWidth: '50vw',
               overflowY: 'auto',
               fontSize: '12px',
               fontFamily: 'monospace !important',
               color: 'white',
               whiteSpace: 'pre-wrap',
               backgroundColor: 'black',
            }}
         >
            {instance.logs}
         </DialogContent>
         <DialogActions>
            <Button onClick={scrollToBottom} color="primary" variant="outlined" endIcon={<ArrowDownwardIcon />}>
               Scroll Down
            </Button>
         </DialogActions>
      </Dialog>
   );
}
