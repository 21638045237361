import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ProjectsList } from '../components/projects/ProjectsLits';
import { Top } from '../components/Top';
import { Bottom } from '../components/Bottom';

export function Dashboard(props: { accountId: string; setAccountId: (accountId: string) => void, setSelectedAccountName: (accountName: string) => void }) {
   const { isAuthenticated } = useContext(AuthContext);

   return (
      <div>
         <Top selectedAccountId={props.accountId} setSelectedAccountId={props.setAccountId} setSelectedAccountName={props.setSelectedAccountName} />
         <div className="container dashboard flex column gap">
            <div className="section-card" style={{ minHeight: '40vh' }}>
               <h2>Projects</h2>
               <div className="">{isAuthenticated ? <ProjectsList accountId={props.accountId} /> : <></>}</div>
            </div>
         </div>
         <Bottom />
      </div>
   );
}
