import { useState } from 'react';
import './App.css';
import { Bottom } from './components/Bottom';
import { ThemeProvider } from '@mui/material';
import { Dashboard } from './pages/Dashboard';
import { authStatus } from './services/auth.service';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { CreateAccount } from './pages/CreateAccount';
import { Login } from './pages/Login';
import { AuthContext } from './contexts/AuthContext';
import { LicenseInfo } from '@mui/x-license-pro';
import { PasswordResetToken } from './components/login/PasswordResetToken';
import { PasswordReset } from './components/login/PasswordReset';
import { AuthProvider } from './components/AuthProvider';
import { SnackBarProvider } from './contexts/SnackbarContext';
import { Admin } from './components/admin/Admin';
import { Home } from './components/admin/admin-content/Home';
import { Accounts } from './components/admin/admin-content/accounts/Accounts';
import { AccountSingle } from './components/admin/admin-content/accounts/AccountSingle';
import { Projects } from './components/admin/admin-content/projects/Projects';
import { ProjectSingle } from './components/admin/admin-content/projects/ProjectSingle';
import { Users } from './components/admin/admin-content/users/Users';
import { UserSingle } from './components/admin/admin-content/users/UserSingle';
import { customTheme } from './static/themes/theme';
import { ProjectPage } from './pages/ProjectPage';
import { UnderMaintenance } from './pages/UnderMaintenance';

LicenseInfo.setLicenseKey('a67bcd5b462e87e921c4d9c50de54c2bTz03ODcyNixFPTE3MzE2MDcyMzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
   const [isAuthenticated, setAuthenticated] = useState(authStatus().authenticated);
   const [selectedAccountId, setSelectedAccountId] = useState('');
   const [selectedAccountName, setSelectedAccountName] = useState('');

   return (
      <ThemeProvider theme={customTheme}>
         <BrowserRouter>
            <AuthContext.Provider value={{ isAuthenticated, setAuthenticated: setAuthenticated }}>
               <AuthProvider setSelectedAccountId={setSelectedAccountId} />
               <SnackBarProvider>
                  <div className="App">
                     <Routes>
                        <Route path="/" element={<Dashboard accountId={selectedAccountId} setAccountId={setSelectedAccountId} setSelectedAccountName={setSelectedAccountName} />} />
                        <Route path="/login" element={<Login />} />

                        <Route path="/project/:id/scenario/:scenarioId/*" element={<ProjectPage />} />
                        <Route path="/project/:id/*" element={<ProjectPage />} />

                        <Route path="/create-account" element={<CreateAccount />} />
                        <Route path="/reset-password" element={<PasswordReset />} />
                        <Route path="/password-reset/:token" element={<PasswordResetToken />} />

                        <Route path="/admin" element={<Admin children={<Home />} />} />
                        <Route path="/admin/accounts" element={<Admin children={<Accounts />} />} />
                        <Route path="/admin/accounts/:id" element={<Admin children={<AccountSingle />} />} />
                        <Route path="/admin/projects" element={<Admin children={<Projects />} />} />
                        <Route path="/admin/projects/:id" element={<Admin children={<ProjectSingle />} />} />
                        <Route path="/admin/users" element={<Admin children={<Users />} />} />
                        <Route path="/admin/users/:id" element={<Admin children={<UserSingle />} />} />

                        {/* <Route path="/" element={<UnderMaintenance />} /> */}

                        <Route path="*" element={<Navigate to="/" />} />
                     </Routes>
                  </div>
               </SnackBarProvider>
            </AuthContext.Provider>
         </BrowserRouter>
      </ThemeProvider>
   );
}

export default App;
