import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from 'recharts';

export function HistogramChart2(props: { values: any[] | null; isFullscreen: boolean; parentWidth: number; labeled?: boolean }) {
   const chartData = useMemo(() => {
      if (!props.values) return null;
      return props.values;
   }, [props.values]);

   if (!props.values || !chartData) return null;
   if (!props.values.length) return <Skeleton style={{ height: 400, transform: 'unset' }}></Skeleton>;

   const barWidth = 30;

   return (
      <BarChart width={props.isFullscreen ? window.innerWidth - 200 : props.parentWidth} height={props.isFullscreen ? window.innerHeight - 200 : 400} data={chartData}>
         <CartesianGrid vertical={false} horizontal={true} stroke="grey" />
         <XAxis angle={chartData.length > 10 ? 45 : 0} dataKey="label" tick={{ fill: '#a8a8a8' }} interval={0} height={chartData.length > 10 ? 100 : 50} dy={chartData.length > 10 ? 40 : 0} />
         <YAxis axisLine={false} tickLine={false} width={100} tick={{ fill: '#a8a8a8' }} tickFormatter={(value) => value.toLocaleString()} />
         <Bar dataKey="passings" fill="var(--hex-yellow)" barSize={barWidth} radius={[50, 50, 0, 0]}>
            {props.labeled && <LabelList dataKey="passings" position="left" formatter={(value: any) => (value > 0 ? value.toLocaleString() : '')} style={{ fill: '#a8a8a8' }} />}
         </Bar>
      </BarChart>
   );
}
