import { ProjectMeta } from '../models/project.model';
import { FEASIBILITY_STUDY_STATUS, PROJECT_META_KEYS } from '../constants/project';
import { Kayaking } from '@mui/icons-material';

export function isEmail(input: string) {
   return String(input)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

function isNumeric(value: any) {
   return /^-?\d+(\.\d+)?$/.test(value);
}

function IsJson(value: string) {
   try {
      var json = JSON.parse(value);
      return typeof json === 'object';
   } catch (e) {
      return false;
   }
}

export function cast(value: string, type: 'string' | 'number' | 'json') {
   switch (type) {
      case 'number':
         if (isNumeric(value)) return Number(value);
         return 0;
      case 'json':
         if (IsJson(value)) return JSON.parse(value);
         return {};
      default:
         if (isNumeric(value)) return Number(value);
         return value;
   }
}

export function getMeta(projectMeta: ProjectMeta[], key: string) {
   return cast(projectMeta.find((meta: ProjectMeta) => meta.key === key)?.value ?? '', PROJECT_META_KEYS[key]);
}

export function metaKeyFriendly(key: string) {
   return key.charAt(0).toUpperCase() + key.slice(1).replaceAll('_', ' ');
}

export const getStatusColor = (status: string): string => {
   switch (status) {
      case FEASIBILITY_STUDY_STATUS.RUNNING:
      case FEASIBILITY_STUDY_STATUS.PUBLISHING:
         return '#ffb94f';
      case FEASIBILITY_STUDY_STATUS.ERROR:
         return '#d95566';
      case FEASIBILITY_STUDY_STATUS.APPROVED:
         return '#8bd186';
      // case FEASIBILITY_STUDY_STATUS.UNAPPROVED:
      //     return 'grey';
      case FEASIBILITY_STUDY_STATUS.COMPLETED:
         return '#737373';
      default:
         return '#';
   }
};

export const addCommasToNumberString = (numberString: string): any => {
   try {
      const regex = /(-?\d+)/g;
      return numberString.replace(regex, (match) => {
         const number = parseFloat(match);
         return isNaN(number) ? match : number.toLocaleString(undefined, { minimumFractionDigits: 0 });
      });
   } catch (e) {
      console.log(e);
      return numberString;
   }
};

export const convertArrayToCSV = (array: any[]): string => {
   const csvRows = [];
   const headers = Object.keys(array[0]);

   // Add the header row
   csvRows.push(headers.join(','));

   // Add data rows
   for (const row of array) {
      const values = headers.map((header) => row[header]);
      csvRows.push(values.join(','));
   }

   return csvRows.join('\n');
};

export function stringToColor(string: string) {
   let hash = 0;
   let i;

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
   }

   let color = '#';

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
   }
   /* eslint-enable no-bitwise */

   return color;
}

export function stringAvatar(name?: string) {
   return {
      sx: {
         bgcolor: name ? stringToColor(name) : '#c4c4c4',
         fontSize: 13,
         width: 28,
         height: 28,
      },
      children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : null,
   };
}

export function formatCategoryPath(categoryPath: string) {
   // Remove dashes and capitalize first letter of each word
   const formattedCategoryPath = categoryPath
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

   return formattedCategoryPath;
}

export function formatNumber(value: number): string {
   const suffixes = ['', 'k', 'M', 'B', 'T'];
   const order = Math.floor(Math.log10(value) / 3);

   if (order < suffixes.length) {
      const divisor = Math.pow(10, order * 3);
      const roundedValue = Math.round((value / divisor) * 10) / 10;
      return `${roundedValue}${suffixes[order]}`;
   } else {
      // If the number is too large, return it as is
      return value.toString();
   }
}
