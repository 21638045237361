import { Button, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { isEmail } from '../../helpers/helper';
import { SignInModel } from '../../models/signin.model';
import { WarningMessage } from '../../models/warningMessage.model';
import { signin } from '../../services/auth.service';
import { GoogleLogin } from './GoogleLogin';

declare global {
   interface Window {
      onSignIn: CallableFunction;
   }
}

export function SignIn(props: { setWarningMessage: React.Dispatch<React.SetStateAction<WarningMessage>> }) {
   const { setAuthenticated } = useContext(AuthContext);
   const [form, setForm] = useState({
      email: '',
      password: '',
      staySigned: false,
   } as SignInModel);
   const navigate = useNavigate();

   const submit = async (e: any) => {
      e.preventDefault();
      try {
         const response = await signin(form);
         if (response?.data?.token) {
            setForm({
               email: '',
               password: '',
               staySigned: false,
            } as SignInModel);
            localStorage.setItem('token', response?.data?.token);
            setAuthenticated(true);
            navigate('/');
         } else {
            props.setWarningMessage({
               error: true,
               message: "Couldn't find your account",
            });
         }
      } catch (e: any) {
         console.log('error', e);
         props.setWarningMessage({ error: true, message: e.toString() });
      }
   };

   return (
      <div>
         <form onSubmit={submit}>
            <h2>Connect with your account</h2>
            <div className="flex column mt-4">
               <TextField
                  label="Email"
                  type={'email'}
                  variant="outlined"
                  value={form.email}
                  onChange={(event: any) => setForm({ ...form, email: event.target.value })}
                  error={!isEmail(form.email) && form.email?.length > 0}
                  helperText={!isEmail(form.email) && form.email?.length > 0 ? 'Please provide a valid email address.' : ''}
                  required={true}
               />
               <TextField
                  className="mt-3 mb-4"
                  label="Password"
                  type={'password'}
                  variant="outlined"
                  value={form.password}
                  onChange={(event: any) => setForm({ ...form, password: event.target.value })}
                  required={true}
               />
               <Button className="mt-4" variant="contained" type="submit">
                  SIGN IN
               </Button>
               <div className="flex center items-center mt-2 w-100">
                  <GoogleLogin errorMsg={props.setWarningMessage} setAuthenticated={setAuthenticated} />
               </div>
            </div>
         </form>
         <div className="flex between items-center">
            <Link className="mt-4 small-link" to="/create-account">
               Create an account
            </Link>
            <Link className="mt-4 small-link" to="/reset-password">
               Forgot password?
            </Link>
         </div>
      </div>
   );
}
