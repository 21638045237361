import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import { FinancialModel } from '../../../../../models/project.model';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

export function ScenarioEdit(props: { params: GridRenderCellParams<FinancialModel>; models: FinancialModel[] }) {
   const { id, value, field } = props.params;
   const apiRef = useGridApiContext();
   return (
      <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
         <FormControl fullWidth className="no-round">
            <Select
               defaultOpen={true}
               value={value}
               label="Status"
               renderValue={(selected: FinancialModel) => selected.scenarioName ?? `Scenario-${selected.modelId}`}
               onChange={(_event: any, val: any) => {
                  apiRef.current.setEditCellValue({
                     id,
                     field,
                     value: props.models.find((account: FinancialModel) => account.id === val.props.value),
                  });
               }}>
               {props.models.map((model: FinancialModel, index: number) => {
                  return (
                     <MenuItem key={index} value={model.id}>
                        {model.scenarioName ?? `Scenario-${model.modelId}`}
                     </MenuItem>
                  );
               })}
            </Select>
         </FormControl>
      </Box>
   );
}
