import { createMap, MapApi} from '@foursquare/map-sdk';
import { useEffect, useState, useRef } from 'react';
import { getMap, getDataset } from '../../services/map.service';
import { putProject } from '../../services/project.service';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TextField, Button, IconButton, LinearProgress } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { MapResponseStatus, Project, Snapshot } from '../../models/project.model';
import { useNavigate } from 'react-router-dom';

const MAP_FOURSQUARE_API_KEY = process.env.REACT_APP_MAP_FOURSQUARE_API_KEY as String;

export function ProjectMap(props: { project: Project; mapId?: string | null; map: MapApi | null; setMap: React.Dispatch<React.SetStateAction<MapApi | null>> }) {
   const [map, setMap] = useState<MapApi | null>(props.map);

   const navigate = useNavigate();
   const [config, setConfig] = useState<any>({});
   const [originalConfig, setOriginalConfig] = useState<any>({});
   const [datasets, setDatasets] = useState<any[]>([]);
   const [dataRetrieved, setDataRetrieved] = useState<boolean>(false);
   const mapContainerRef = useRef<HTMLDivElement>(null);
   // Progress Bar variables
   const [progressBarValue, setProgressBarValue] = useState<number>(1);
   const [progressBarVisible, setProgressBarVisible] = useState<boolean>(true);
   const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
   const [snapshotTitle, setSnapshotTitle] = useState('');
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [openDeleteMsg, setOpenDeleteMsg] = useState(false);
   const [selectedIndex, setSelectedIndex] = useState(-1);
   const [openEditMode, setOpenEditMode] = useState(false);
   const [openSendMsg, setOpenSendMsg] = useState(false);
   const [isFullScreen, setIsFullScreen] = useState(props.project.mapOnly);
   const [showMap, setShowMap] = useState(false);
   const [mapResponseStatus, setMapResponseStatus] = useState<MapResponseStatus | null>(null);

   useEffect(() => {
      getSnapshots();
      if (!map) {
         createProjectMap();
         loadData();
      } else {
         setProgressBarValue(90);
         setTimeout(() => {
            if (mapContainerRef.current) map.addToDOM(mapContainerRef.current);
            setProgressBarVisible(false);
            setShowMap(true);
         }, 10);
      }
   }, [props.mapId, props.map]);

   useEffect(() => {
      if (dataRetrieved && !mapResponseStatus?.mapPrivate && !mapResponseStatus?.datasets?.length) addDatasets();
   }, [dataRetrieved]);

   const addDatasets = async () => {
      if (map) {
         try {
            map.setMapConfig(config);
            setOriginalConfig(config);
            setProgressBarValue(50);

            for (var dataset of datasets) {
               try {
                  switch (dataset.type) {
                     case 'vector-tile':
                        await map.addTileDataset({
                           id: dataset.id,
                           type: 'vector-tile',
                           label: dataset.label,
                           metadata: {
                              dataUrl: dataset.data.tilesetDataUrl,
                              metadataUrl: dataset.data.tilesetMetadataUrl,
                           },
                        });
                        break;
                     case 'sql': // sdk currently doesn't support SQL datasets
                        break;
                     case 'hextile': // sdk currently doesn't support hextile datasets
                        break;
                     case 'managed':
                        map.addDataset({
                           id: dataset.id,
                           label: dataset.label,
                           data: dataset.data,
                        });
                        break;
                     default:
                        break;
                  }
               } catch (e) {
                  console.log('error with dataset: ', dataset, e);
               }
            }

            props.setMap(map);
            setShowMap(true);
            setProgressBarValue(100);
            setTimeout(() => setProgressBarVisible(false), 3500);
         } catch (error) {
            alert("Couldn't load the data, " + error);
            navigate('/');
         }
      }
   };

   const loadData = async () => {
      var mapId = props.mapId ?? '';
      if (!mapId.length) {
         props.project.projectMeta.forEach((element: { value: string; key: string }) => {
            if (element.key === 'map_id') mapId = element.value;
         });
      }
      if (mapId === '') return;
      const info = await getMap(mapId);
      if (info?.status !== 200) {
         setMapResponseStatus({
            mapPrivate: true,
         });
         setProgressBarValue(100);
         setProgressBarVisible(false);
         return;
      }
      setConfig(info.data.latestState.data);

      const privateDatasets: any[] = [];
      var datasetsResult: any[] = [...datasets];
      info.data.datasets.forEach(async function (dataset: any) {
         var datasetResult = {
            id: dataset.id,
            label: dataset.name,
            type: dataset.type,
         };

         switch (dataset.type) {
            case 'vector-tile':
               datasetsResult.push({
                  ...datasetResult,
                  data: dataset.metadata,
               });
               break;
            case 'sql':
               datasetsResult.push({
                  ...datasetResult,
                  data: dataset.description,
               });
               break;
            case 'hextile':
               datasetsResult.push({});
               break;
            case 'managed':
               const response = await getDataset(dataset.id);
               if (response.status !== 200) {
                  privateDatasets.push(dataset);
                  break;
               }
               datasetsResult.push({
                  ...datasetResult,
                  data: response.data,
               });
               break;
            default:
               datasetsResult.push({});
               break;
         }

         if (datasetsResult.length + privateDatasets.length === info.data.datasets.length) {
            if (privateDatasets.length > 0) {
               setMapResponseStatus({
                  ...mapResponseStatus,
                  datasets: privateDatasets.map((privateDataset: any) => {
                     return {
                        name: privateDataset.name,
                        datasetPrivate: true,
                     } as { name: string; datasetPrivate: boolean };
                  }) as { name: string; datasetPrivate: boolean }[],
               } as MapResponseStatus);
               setDataRetrieved(true);
               setProgressBarValue(100);
               setProgressBarVisible(false);
               return;
            }
            setDatasets(datasetsResult);
            setDataRetrieved(true);
         }
      });
   };

   const getSnapshots = async () => {
      props.project.projectMeta.forEach((element: { value: string; key: string }) => {
         if (element.key === 'map_snapshots') {
            const snapshotList = JSON.parse(element.value);
            setSnapshots(snapshotList);
         }
      });
   };

   const updateSnapshots = async (snaps: Snapshot[]) => {
      var project = props.project;
      var indexToUpdate = project.projectMeta.length;
      project.projectMeta.forEach((element: { value: string; key: string }, index: number) => {
         if (element.key === 'map_snapshots') {
            indexToUpdate = index;
         }
      });

      project.projectMeta[indexToUpdate] = {
         key: 'map_snapshots',
         value: JSON.stringify(snaps),
      };
      await putProject(project);
   };

   const createProjectMap = async () => {
      const mapInstance = await createMap({apiKey: MAP_FOURSQUARE_API_KEY.toString() ?? ''});
      if (mapContainerRef.current) mapInstance.addToDOM(mapContainerRef.current);
      setMap(mapInstance);
      setProgressBarValue(20);
   };

   // const createNewSnapshot = () => {
   //    const newSnapshots = [
   //       ...snapshots,
   //       {
   //          config: map?.getMapConfig(),
   //          name: 'SNAPSHOT ' + (snapshots.length + 1),
   //       },
   //    ];
   //    setSnapshots(newSnapshots);
   //    updateSnapshots(newSnapshots);
   // };

   const setMapStateTo = async (config: any) => {
      setProgressBarValue(20);
      setProgressBarVisible(true);

      await new Promise((resolve) => setTimeout(resolve, 500));
      map?.setMapConfig(config);
      setProgressBarValue(50);

      setProgressBarValue(100);
      setTimeout(() => setProgressBarVisible(false), 3500);
   };

   // const resetMapState = () => {
   //    setMapStateTo(originalConfig);
   // };

   const deleteSnapshot = () => {
      const newSnapshots = snapshots.filter((config: any, i: number) => i !== selectedIndex);
      setSnapshots(newSnapshots);
      updateSnapshots(newSnapshots);
      setOpenDeleteMsg(false);
      setAnchorEl(null);
   };

   // const handleDelete = () => {
   //    setOpenDeleteMsg(true);
   //    setAnchorEl(null);
   // };

   // const handleSend = () => {
   //    setOpenSendMsg(true);
   //    setAnchorEl(null);
   // };

   // const handleEdit = () => {
   //    setSnapshotTitle(getTitleByIndex(selectedIndex));
   //    setOpenEditMode(true);
   //    setAnchorEl(null);
   // };

   const getTitleByIndex = (i: number): string => {
      return snapshots[i].name;
   };

   const editSnapshotTitle = () => {
      snapshots.forEach((element: Snapshot, index: number) => {
         if (index === selectedIndex) {
            element.name = snapshotTitle;
         }
      });
      setSnapshots(snapshots);
      setOpenEditMode(false);
      setSnapshotTitle('');
      updateSnapshots(snapshots);
   };

   // const handleActionMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
   //    setSelectedIndex(index);
   //    setAnchorEl(event.currentTarget);
   // };

   const toggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
   };

   return (
      <div>
         <div hidden={!progressBarVisible}>
            <LinearProgress sx={{ height: '5px', backgroundColor: '#022222' }} color="secondary" variant="determinate" value={progressBarValue} />
         </div>
         <div className={`${isFullScreen ? 'map full-screen' : 'map'} `} style={{ zIndex: 9 }}>
            {!showMap && (
               <div className="map-loading flex gap-small items-center" style={{ zIndex: 9999 }}>
                  {!mapResponseStatus?.mapPrivate && !mapResponseStatus?.datasets?.length ? (
                     <p style={{ color: 'white' }}>{!dataRetrieved ? 'Retrieving' : 'Loading'} Datasets...</p>
                  ) : (
                     <div className="flex column">{mapResponseStatus?.mapPrivate || (mapResponseStatus?.datasets?.length !== undefined && mapResponseStatus?.datasets?.length > 0 && <p style={{ color: 'white' }}>This map is private</p>)}</div>
                  )}
               </div>
            )}
            <div className={isFullScreen ? 'map extend' : 'map'} style={{ opacity: showMap ? 1 : 0 }} ref={mapContainerRef}></div>
            {!props.project.mapOnly && showMap && (
               <IconButton
                  disabled={progressBarVisible}
                  onClick={() => toggleFullScreen()}
                  color="secondary"
                  className="fullscreen-btn"
                  onKeyDown={(event) => {
                     if (event.key === 'Escape') {
                        toggleFullScreen();
                     }
                  }}>
                  {isFullScreen ? <FullscreenExit sx={{ color: 'var(--hex-yellow)' }} fontSize="large" /> : <Fullscreen sx={{ color: 'var(--hex-yellow)' }} fontSize="large" />}
               </IconButton>
            )}
         </div>
         <Dialog open={openDeleteMsg}>
            <DialogTitle id="alert-dialog-title">Delete Snapshot</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">Are you sure you want to delete this snapshot?</DialogContentText>
            </DialogContent>
            <DialogActions className="mb-2">
               <Button onClick={() => setOpenDeleteMsg(false)} color="primary">
                  Cancel
               </Button>
               <Button onClick={deleteSnapshot} variant="contained" color="warning" autoFocus>
                  Delete
               </Button>
            </DialogActions>
         </Dialog>
         <Dialog open={openEditMode}>
            <DialogTitle id="alert-dialog-title">Edit Snapshot</DialogTitle>
            <DialogContent>
               <TextField value={snapshotTitle} onChange={(event: any) => setSnapshotTitle(event.target.value)} autoFocus />
            </DialogContent>
            <DialogActions className="mb-2">
               <Button onClick={() => setOpenEditMode(false)} color="primary">
                  Cancel
               </Button>
               <Button onClick={editSnapshotTitle} variant="contained" color="info" autoFocus>
                  Save
               </Button>
            </DialogActions>
         </Dialog>
         <Dialog open={openSendMsg}>
            <DialogTitle id="alert-dialog-title">Send Snapshot</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">Are you sure you want to send this snapshot?</DialogContentText>
            </DialogContent>
            <DialogActions className="mb-2">
               <Button onClick={() => setOpenSendMsg(false)} color="primary">
                  Cancel
               </Button>
               <Button onClick={editSnapshotTitle} variant="contained" color="success" autoFocus>
                  Send
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
