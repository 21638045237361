import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { WarningMessage } from '../../models/warningMessage.model';
import { SimpleModal } from '../shared/ui/modals/SimpleModal';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';

export function ConnectModal(props: { open: boolean; setOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined; signIn: boolean }) {
   const [isSignIn, setIsSignIn] = useState(props.signIn);
   const [topMessage, setTopMessage] = useState({
      error: false,
      message: null,
   } as WarningMessage);

   useEffect(() => {
      if (topMessage.error) setTopMessage({ error: false, message: null });
   }, [isSignIn]);

   useEffect(() => {
      if (topMessage.message) {
         setTimeout(() => setTopMessage({ error: false, message: null }), 3000);
      }
   }, [topMessage]);

   return (
      <SimpleModal open={props.open}>
         {topMessage.message !== null && (
            <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error' : 'success'}>
               {topMessage.message}
            </Alert>
         )}
         {isSignIn ? <SignIn setWarningMessage={setTopMessage} /> : <SignUp setWarningMessage={setTopMessage} />}
      </SimpleModal>
   );
}
