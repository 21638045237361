import { useEffect, useMemo, useState } from 'react';
import { User } from '../../models/user.model';
import { Log } from '../../models/log.model';
import { getUserLogs } from '../../services/logs.service';
import { GridColDef } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Account } from '../../models/account.model';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export function Logs(props: { logs: Log[]; includeUser: boolean; gridHeight?: number }) {
   const columns: GridColDef[] = [
      {
         field: 'createdAt',
         headerName: 'Date',
         editable: false,
         width: 1,
         filterable: true,
         flex: 2,
         renderCell: (params) => {
            return new Date(params.value as string).toLocaleString();
         },
      },

      {
         field: 'type',
         headerName: 'Type',
         editable: false,
         flex: 1,
         filterable: true,
         renderCell: (params) => {
            return <div className={`log-type ${params.value}`}>{params.value?.toString()}</div>;
         },
      },
      {
         field: 'action',
         headerName: 'Action',
         editable: false,
         filterable: true,
         flex: 3,
         renderCell: (params) => {
            if (params.value === 'download file') {
               return (
                  <div className="flex gap items-center">
                     <div className="log-action download">
                        <div>File Download</div>
                     </div>
                     <Link className="log-link flex items-center" to={`/admin/projects/${params.row.projectId}?tab=4`}>
                        {params.row.asset?.name}
                        <AttachFileIcon sx={{ width: 15 }} />
                     </Link>

                     <div>{params.row.project?.name}</div>
                  </div>
               );
            }
            return <div className={`log-action ${params.value}`}>{params.value ? params.value.charAt(0).toUpperCase() + params.value.slice(1) : ''}</div>;
         },
      },
   ];

   if (props.includeUser) {
      columns.unshift({
         field: 'user',
         headerName: 'User',
         editable: false,
         filterable: true,
         flex: 2,
         renderCell: (params) => {
            return (
               <div className="flex items-center gap-small">
                  <AccountCircleIcon />
                  <Link className="log-link" to={`/admin/users/${params.row.userId}`}>
                     {params.row.user?.firstName + ' ' + params.row.user?.lastName}
                  </Link>{' '}
               </div>
            );
         },
      });
   }

   return <DataGridPro disableSelectionOnClick={true} sx={{ minHeight: props.gridHeight ?? '80vh', maxHeight: props.gridHeight ?? '80vh', backgroundColor: 'white' }} rows={props.logs} columns={columns} rowCount={props.logs.length} rowsPerPageOptions={[5, 10, 20, 100]} pagination />;
}
