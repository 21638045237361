import { FoursquareAsset, Snapshot } from '../models/project.model';
import { fetchWithValidation } from './fetch.service';

const FQ_API_URL = process.env.REACT_APP_FOURSQUARE_API as String;
const API_URL = process.env.REACT_APP_API as String;

export const getMap = async (mapId: String, userId?: string): Promise<any> => {
   var resp = await getAccessToken(userId);
   const requestOptions = {
      method: 'GET',
      headers: {
         Authorization: 'Bearer ' + resp.token,
      },
   };
   const result = await fetch(`${FQ_API_URL}/maps/${mapId}`, requestOptions);
   return { status: result.status, data: await result.json() };
};

// export const getMap2 = async (mapId: String): Promise<any> => {
//    const requestOptions = {
//       method: 'GET',
//       headers: { 'Content-Type': 'application/json' },
//       credentials: 'include' as any,
//    };
//    return fetchWithValidation(`${API_URL}financial-models/map?id=${mapId}`, requestOptions);
// };

export const getAssetPermission = async (type: string, id: string, userId?: string): Promise<any> => {
   var resp = await getAccessToken(userId);
   const requestOptions = {
      method: 'GET',
      headers: {
         Authorization: 'Bearer ' + resp.token,
      },
   };
   const result = await fetch(`${FQ_API_URL}/permissions/${type}/${id}`, requestOptions);
   return { status: result.status, data: await result.json() };
};

export const getDataset = async (datasetId: string, userId?: string): Promise<any> => {
   var resp = await getAccessToken(userId);
   const requestOptions = {
      method: 'GET',
      headers: {
         Authorization: 'Bearer ' + resp.token,
      },
   };
   return fetch(`${FQ_API_URL}/datasets/${datasetId}/data`, requestOptions).then(async (response) => {
      var responseClone = response.clone();
      var error = false;
      var result = await response.json().catch((reason: any) => {
         error = true;
      });
      if (error) {
         result = responseClone.text();
      }
      return { status: response.status, data: await result };
   });
};

export const getMapSnapshotsByProjectId = async (projectId: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}projects/${projectId}/snapshots`, requestOptions);
};

export const setAssetsPermission = async (modelId: string): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({ modelId: modelId }),
   };
   return fetchWithValidation(`${API_URL}financial-models/share-foursquare-assets`, requestOptions);
};

export const updateMapSnapshots = async (projectId: string, snapshots: Snapshot[]): Promise<any> => {
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(snapshots),
   };
   return fetchWithValidation(`${API_URL}projects/${projectId}/snapshots`, requestOptions);
};

async function getAccessToken(userId?: string): Promise<any> {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}projects/access-token${userId ? `?userId=${userId}` : ''}`, requestOptions);
}
