import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { FinancialModel, FoursquareAsset, MapDetails } from '../../../../../models/project.model';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { getAssetPermission, getMap, setAssetsPermission } from '../../../../../services/map.service';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LockIcon from '@mui/icons-material/Lock';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';

export function MapDetailsModal(props: { financialModel: FinancialModel; open: boolean; setOpen: (open: boolean) => void }) {
   const { financialModel, open, setOpen } = props;
   const [mapDetails, setMapDetails] = useState<MapDetails | null>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const [showShareBtn, setShowShareBtn] = useState<boolean>(false);
   const snackbar = useSnackBar();

   useEffect(() => {
      if (financialModel.publishedMapId) retieveMapData();
   }, [financialModel]);

   const shareAssets = async () => {
      if (!mapDetails?.mapId) return;
      setLoading(true);
      try {
         const response = await setAssetsPermission(props.financialModel.id);
         if (!response.error) {
            setMapDetails({
               ...mapDetails,
               isShared: true,
               datasets:
                  mapDetails?.datasets &&
                  mapDetails?.datasets?.map((dataset: any) => {
                     return {
                        ...dataset,
                        isShared: true,
                     };
                  }),
            });
            snackbar.success('Assets shared successfully');
            setShowShareBtn(false);
         } else {
            snackbar.error('Error sharing assets');
         }
      } catch (e) {
         console.log(e);
         snackbar.error('Error sharing assets');
      }
      setLoading(false);
   };

   const retieveMapData = async () => {
      setMapDetails(null);
      if (!financialModel.publishedMapId) return;
      try {
         var mapDetailsObj: MapDetails = {
            mapId: financialModel.publishedMapId,
            isShared: undefined,
            datasets: null,
         } as MapDetails;
         const map = await getMap(financialModel.publishedMapId, financialModel.user.id);
         if (map?.status !== 200) {
            return;
         }

         const mapShared = await getAssetPermission('map', mapDetailsObj.mapId, financialModel.user.id);
         if (mapShared?.status !== 200) return;
         mapDetailsObj = {
            ...mapDetailsObj,
            isShared: mapShared.data?.organization === 'viewer' || mapShared.data?.organization === 'editor',
         };

         if (mapShared.data?.organization !== 'viewer' && mapShared.data?.organization !== 'editor' && !showShareBtn) setShowShareBtn(true);

         if (map.data.datasets.length === 0) {
            setMapDetails({
               ...mapDetailsObj,
               datasets: [],
            });
            return;
         }
         setMapDetails({
            ...mapDetailsObj,
         });

         var datasetsResult: any[] = [];
         map.data.datasets.forEach(async function (dataset: any) {
            var datasetResult = {
               id: dataset.id,
               label: dataset.name,
               type: dataset.type,
               isShared: undefined,
            };

            switch (dataset.type) {
               case 'vector-tile':
                  datasetsResult.push({
                     ...datasetResult,
                     data: dataset.metadata,
                     url: dataset.metadata.tilesetMetadataUrl,
                     isShared: true,
                  });
                  break;
               case 'sql':
                  datasetsResult.push({
                     ...datasetResult,
                     isShared: true,

                     data: dataset.description,
                  });
                  break;
               case 'hextile':
                  datasetsResult.push({
                     ...datasetResult,
                     isShared: true,
                  });
                  break;
               case 'managed':
                  const datasetShared = await getAssetPermission('dataset', dataset.id, financialModel.user.id);
                  if (datasetShared?.status !== 200) return;
                  datasetsResult.push({
                     ...datasetResult,
                     url: `https://studio.foursquare.com/workspace/datasets/${dataset.id}`,
                     isShared: datasetShared.data?.organization === 'viewer' || datasetShared.data?.organization === 'editor',
                  });
                  if (datasetShared.data?.organization !== 'viewer' && datasetShared.data?.organization !== 'editor' && !showShareBtn) setShowShareBtn(true);

                  break;
               default:
                  datasetsResult.push({});
                  break;
            }
            if (datasetsResult.length === map.data.datasets.length) {
               setMapDetails({
                  ...mapDetailsObj,
                  datasets: datasetsResult.sort((a, b) => a.type.localeCompare(b.type)),
               });
            }
         });
      } catch (e) {}
   };

   return (
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg" scroll="paper">
         <DialogTitle>
            Financial Model {financialModel.modelId}
            <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
               <CloseIcon />
            </IconButton>
         </DialogTitle>
         <DialogContent sx={{ minWidth: '25vw' }}>
            <TableContainer>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>
                           <Typography variant="h6" gutterBottom>
                              Map Details
                           </Typography>
                        </TableCell>
                        <TableCell style={{ display: 'flex !important', justifyContent: 'end !important' }}>
                           {showShareBtn && !loading && (
                              <Button onClick={shareAssets} variant="outlined" color="error">
                                 SHARE MAP
                              </Button>
                           )}
                           {loading && <CircularProgress size={20} />}
                        </TableCell>
                     </TableRow>
                  </TableHead>

                  <TableBody>
                     <TableRow>
                        <TableCell>Map ID</TableCell>
                        <TableCell>
                           {!mapDetails?.mapId ? (
                              <Skeleton variant="text" />
                           ) : (
                              <a className="flex items-center" href={`https://studio.foursquare.com/map/${mapDetails?.mapId}`} target="_blank">
                                 <OpenInNewIcon sx={{ fontSize: '14px' }} />
                                 {mapDetails?.mapId}
                              </a>
                           )}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>Visibility</TableCell>
                        <TableCell>
                           {mapDetails?.mapId === undefined ? (
                              <Skeleton variant="text" />
                           ) : mapDetails?.isShared === true ? (
                              <div>Shared</div>
                           ) : (
                              <div className="flex gap-small">
                                 Private
                                 <Tooltip title="The datasets won't be displayed as the map has private visibility.">
                                    <LockIcon sx={{ fontSize: '17px' }} />
                                 </Tooltip>
                              </div>
                           )}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>Author</TableCell>
                        <TableCell>
                           {financialModel.user.firstname} {financialModel.user.lastname}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>
                           {new Date(financialModel.createdAt).toLocaleDateString()} - {new Date(financialModel.createdAt).toLocaleTimeString()}
                        </TableCell>
                     </TableRow>
                  </TableBody>

                  {mapDetails?.datasets?.length !== undefined && mapDetails?.datasets?.length > 0 && (
                     <>
                        <TableHead>
                           <TableRow>
                              <TableCell>
                                 <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Datasets
                                 </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {mapDetails?.datasets.map((dataset, index) => {
                              return (
                                 <TableRow key={index}>
                                    <TableCell>
                                       <div className="flex column">
                                          <div className="flex between gap-small">
                                             <span
                                                style={{
                                                   fontSize: '15px',
                                                   fontWeight: '500',
                                                }}>
                                                {dataset.label}
                                             </span>
                                             {dataset?.isShared !== true && (
                                                <Tooltip title="Private Dataset">
                                                   <LockIcon
                                                      sx={{
                                                         fontSize: '20px',
                                                      }}
                                                   />
                                                </Tooltip>
                                             )}
                                          </div>
                                          <span
                                             style={{
                                                fontSize: '11px',
                                                color: 'grey',
                                             }}>
                                             type: {dataset.type}
                                          </span>
                                       </div>
                                    </TableCell>
                                    <TableCell>
                                       {dataset.url !== undefined ? (
                                          <a className="flex items-center" href={dataset.url} target="_blank">
                                             <OpenInNewIcon sx={{ fontSize: '14px' }} />
                                             {dataset.id}
                                          </a>
                                       ) : (
                                          <>{dataset.id}</>
                                       )}
                                    </TableCell>
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </>
                  )}
               </Table>
            </TableContainer>
         </DialogContent>
      </Dialog>
   );
}
