import { useEffect, useState } from 'react';
import { getGoogleClientId, googleLogin } from '../../services/auth.service';

import { WarningMessage } from '../../models/warningMessage.model';
import { useNavigate } from 'react-router-dom';
import { useSnackBar } from '../../contexts/SnackbarContext';

declare global {
   interface Window {
      onSignIn: CallableFunction;
   }
}

export function GoogleLogin(props: { errorMsg: React.Dispatch<React.SetStateAction<WarningMessage>>; setAuthenticated: (state: boolean) => void }) {
   const [googleClientId] = useState(getGoogleClientId());

   const navigate = useNavigate();
   const snackbar = useSnackBar();

   useEffect(() => {
      window.onSignIn = onSignIn;
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://accounts.google.com/gsi/client';
      scriptElement.async = true;
      scriptElement.defer = true;
      document.head.appendChild(scriptElement);
   }, []);

   async function onSignIn(googleInfo: any) {
      try {
         const response = await googleLogin(googleInfo);
         if (response.token) {
            localStorage.setItem('token', response.token);
            props.setAuthenticated(true);
            navigate('/');
         } else {
            props.errorMsg({
               error: true,
               message: 'Error with your Google account',
            });
         }
      } catch (error: any) {
         snackbar.error('error: try another login method');
         props.errorMsg({ error: true, message: error.toString() });
      }
   }

   return (
      <>
         <div id="g_id_onload" data-client_id={googleClientId} data-ux_mode="popup" data-callback="onSignIn" data-auto_prompt="false"></div>
         <div className="g_id_signin" data-type="standard" data-shape="circle" data-theme="filled" data-text="Google" data-size="small" data-logo_alignment="left"></div>
      </>
   );
}
