import { fetchWithValidation } from './fetch.service';

const API_URL = process.env.REACT_APP_API as String;

export const getUserLogs = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}logs/user/${id}`, requestOptions);
};

export const getProjectLogs = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}logs/project/${id}`, requestOptions);
};
