import { Logout } from '@mui/icons-material';
import { Button, Menu, MenuItem, Divider, ListItemIcon, IconButton } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { authStatus } from '../../services/auth.service';
import { MyAccount } from './MyAccount';
import { MySettings } from './Settings';
import { AuthContext } from '../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

export function ProfileMenu() {
   const location = useLocation();
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const [myAccountMenuOpen, setMyAccountMenuOpen] = useState(false);
   const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
   const { isAuthenticated, setAuthenticated } = useContext(AuthContext);
   const [initials, setInitials] = useState('');
   const navigate = useNavigate();
   const projectId = location.pathname.split('/')[2];

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      var firstname = authStatus()?.content?.firstname;
      var lastname = authStatus()?.content?.lastname;
      if (firstname && lastname) {
         var initials = firstname.charAt(0) + lastname.charAt(0);
         setInitials(initials);
      }
   }, [isAuthenticated]);

   return (
      <div>
         <MyAccount open={myAccountMenuOpen} setOpen={setMyAccountMenuOpen} />
         <MySettings open={settingsMenuOpen} setOpen={setSettingsMenuOpen} />

         {/* <Button
            variant="outlined"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<AccountCircleIcon />}>
            {initials != '' ? initials : 'User'}
         </Button> */}
         <IconButton size="small" color="secondary" aria-label="My Profile" onClick={handleClick}>
            <AccountCircleIcon fontSize="large" />
         </IconButton>
         <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
               elevation: 0,
               sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& svg': {
                     width: 32,
                     height: 32,
                     ml: -0.5,
                     mr: 1,
                     color: 'grey',
                  },

                  '&:before': {
                     content: '""',
                     display: 'block',
                     position: 'absolute',
                     top: 0,
                     right: 14,
                     width: 10,
                     height: 10,
                     bgcolor: 'background.paper',
                     transform: 'translateY(-50%) rotate(45deg)',
                     zIndex: 0,
                  },
               },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={() => setMyAccountMenuOpen(true)}>
               <AccountCircleIcon fontSize="large" /> My account
            </MenuItem>
            {/* {authStatus().content?.roles.includes('ADMIN') && ( */}
            {['ADMIN', 'FINADMIN'].some((role) => authStatus().content?.roles.includes(role)) && (
               <MenuItem onClick={() => navigate(`/admin/projects${projectId ? `/${projectId}?tab=3` : ''}`)}>
                  <BuildCircleIcon fontSize="large" /> {projectId ? 'Edit project' : 'Admin panel'}
               </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={() => setAuthenticated(false)}>
               <ListItemIcon>
                  <Logout fontSize="small" />
               </ListItemIcon>
               Logout
            </MenuItem>
         </Menu>
      </div>
   );
}
