import { Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { SimpleModal } from '../../../shared/ui/modals/SimpleModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account } from '../../../../models/account.model';
import { useSnackBar } from '../../../../contexts/SnackbarContext';
import { CreateUserFromAdminModel, Role } from '../../../../models/user.model';
import { createUserLink } from '../../../../services/user.service';
import CopyToClipboardButton from '../../../shared/ui/CopyToClipboardButton';

export function NewUserModal(props: { open: boolean; onClose: () => void; accounts: Account[]; roles: Role[] }) {
   const [form, setForm] = useState<CreateUserFromAdminModel>({
      accountIds: ['98355490-056d-4e3a-b1cc-e63a4f459a6b'],
      isActive: true,
      roleIds: [2],
   });

   const [link, setLink] = useState<string | null>(null);

   const navigate = useNavigate();
   const snackbar = useSnackBar();

   const handleCreate = async (e: any) => {
      e.preventDefault();
      try {
         const response = await createUserLink(form);
         if (!response.error) {
            snackbar.success('Link successfully created.');
            // get current domain
            setLink(`${window.location.origin}/create-account?code=${response.inviteCode}`);
         } else {
            snackbar.error('Error while creating the link.');
         }
      } catch (e) {}
   };

   return (
      <SimpleModal
         open={props.open}
         setOpen={() => {
            props.onClose();
            setLink(null);
         }}>
         <div className="flex column">
            <div className="flex start">
               <Typography sx={{ mb: 1 }} variant="h6" component="div">
                  New User
               </Typography>
            </div>
            <div>
               {link === null ? (
                  <form onSubmit={handleCreate} className="">
                     <div className="flex between items-center gap-small">
                        <FormControl fullWidth className="mt-3">
                           <InputLabel id="roles-label">Status</InputLabel>
                           <Select
                              sx={{ textAlign: 'left' }}
                              labelId="roles-label"
                              value={form.isActive ? 'true' : 'false'}
                              label="Status"
                              onChange={(event: any, val: any) => {
                                 setForm({
                                    ...form,
                                    isActive: val.props.value == 'true',
                                 });
                              }}>
                              <MenuItem value={'true'}>Active</MenuItem>
                              <MenuItem value={'false'}>Inactive</MenuItem>
                           </Select>
                        </FormControl>

                        <FormControl fullWidth className="mt-3 w-100">
                           <InputLabel id="roles-label">Roles</InputLabel>
                           <Select
                              labelId="roles-label"
                              value={form.roleIds}
                              label="Status"
                              multiple
                              sx={{ textAlign: 'left' }}
                              renderValue={(selected: number[]) => selected.map((roleId: number) => props.roles.find((role: Role) => role.id === roleId)?.role).join(', ')}
                              onChange={(event: any, val: any) => {
                                 var newRoles: number[] = [];
                                 const changedRoleId = val.props.value;
                                 const exists = form.roleIds.includes(changedRoleId);

                                 if (!exists) {
                                    const roleToAdd = props.roles.filter((role: Role) => role.id === changedRoleId)[0];
                                    newRoles = [...form.roleIds, roleToAdd.id];
                                 } else {
                                    newRoles = form.roleIds.filter((id: number) => id !== changedRoleId);
                                 }

                                 setForm({ ...form, roleIds: newRoles });
                              }}>
                              {props.roles.map((role: Role, index: number) => (
                                 <MenuItem key={index} value={role.id}>
                                    <Checkbox checked={form.roleIds.indexOf(role.id) > -1} />
                                    <ListItemText primary={role.role} />
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                     </div>
                     <FormControl fullWidth className="mt-3 w-100">
                        <InputLabel id="roles-label">Accounts</InputLabel>
                        <Select
                           labelId="roles-label"
                           value={form.accountIds}
                           label="Accounts"
                           multiple
                           sx={{ textAlign: 'left' }}
                           renderValue={(selected: string[]) => selected.map((accountId: string) => props.accounts.find((account: Account) => account.id === accountId)?.name).join(', ')}
                           onChange={(event: any, val: any) => {
                              var newAccounts: string[] = [];
                              const changedAccountId = val.props.value;
                              const exists = form.accountIds.includes(changedAccountId);

                              if (!exists) {
                                 const accountToAdd = props.accounts.filter((account: Account) => account.id === changedAccountId)[0];
                                 newAccounts = [...form.accountIds, accountToAdd.id];
                              } else {
                                 newAccounts = form.accountIds.filter((id: string) => id !== changedAccountId);
                              }

                              setForm({ ...form, accountIds: newAccounts });
                           }}>
                           {props.accounts.map((account: Account, index: number) => (
                              <MenuItem key={index} value={account.id}>
                                 <Checkbox checked={form.accountIds.indexOf(account.id) > -1} />
                                 <ListItemText primary={account.name} />
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <div className="flex end">
                        <Button
                           className="mt-4 mr-2"
                           variant="text"
                           onClick={() => {
                              props.onClose();
                              setLink(null);
                           }}>
                           CANCEL
                        </Button>

                        <Button className="mt-4" color="primary" variant="contained" type="submit">
                           GENERATE LINK
                        </Button>
                     </div>
                  </form>
               ) : (
                  <div className="flex column gap items-start">
                     <div className="flex column items-start">
                        <p>Link successfully created!</p>
                        <p>Copy the one-time use link and send it to the user.</p>
                     </div>
                     <div>
                        <a href={link}>{link}</a>
                        <CopyToClipboardButton value={link} />
                     </div>
                  </div>
               )}
            </div>
         </div>
      </SimpleModal>
   );
}
