import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { createContext, useContext } from 'react';

type SnackBarContextActions = {
   showSnackBar: (text: string, typeColor: string) => void;
   success: (text: string) => void; // Add the success function
   error: (text: string) => void; // Add the error function
   info: (text: string) => void; // Add the info function
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
   children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({ children }) => {
   const [open, setOpen] = React.useState<boolean>(false);
   const [message, setMessage] = React.useState<string>('');
   const [typeColor, setTypeColor] = React.useState<string>('info');

   const showSnackBar = (text: string, color: string) => {
      setMessage(text);
      setTypeColor(color);
      setOpen(true);
   };

   const success = (text: string) => {
      setMessage(text);
      setTypeColor('success'); // Set the color for success messages
      setOpen(true);
   };

   const error = (text: string) => {
      setMessage(text);
      setTypeColor('error'); // Set the color for error messages
      setOpen(true);
   };

   const info = (text: string) => {
      setMessage(text);
      setTypeColor('info'); // Set the color for info messages
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
      // setTypeColor('info');
   };

   return (
      <SnackBarContext.Provider value={{ showSnackBar, success, error, info }}>
         <Snackbar open={open} autoHideDuration={4000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose} className="snackbar-context">
            <Alert onClose={handleClose} severity={typeColor as AlertColor} className={typeColor + '-alert'}>
               {message}
            </Alert>
         </Snackbar>
         {children}
      </SnackBarContext.Provider>
   );
};

const useSnackBar = (): SnackBarContextActions => {
   const context = useContext(SnackBarContext);

   if (!context) {
      throw new Error('useSnackBar must be used within an SnackBarProvider');
   }

   return context;
};

export { SnackBarProvider, useSnackBar };
