import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import logo from '../../static/assets/logo/logo-nav-long.png';
import { useEffect, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MapIcon from '@mui/icons-material/Map';
import { FinancialModel, Project } from '../../models/project.model';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ProjectAssets } from './ProjectAssets';
import LockIcon from '@mui/icons-material/Lock';
import HexagonIcon from '@mui/icons-material/Hexagon';
import AltRouteIcon from '@mui/icons-material/AltRoute';

export function ProjectNav(props: { project: Project; scenarios: FinancialModel[] | null; scenario: FinancialModel | null | undefined }) {
   const navigate = useNavigate();
   const location = useLocation();
   const projectPath = `/project/${props.project.id}${props.scenario?.id !== undefined ? `/scenario/${props.scenario?.id}` : ''}`;
   const currentPath = location.pathname;
   const categoryPath = currentPath.split('/')[props.scenario?.id !== undefined ? 5 : 3] || '';

   const [isOpen, setIsOpen] = useState<any>({});

   const navObj = [
      {
         name: 'Dashboard',
         icon: <DashboardIcon />,
         path: `${projectPath}/`,
         subNav: [
            {
               name: 'Summary',
               path: `${projectPath}/`,
            },
            {
               name: 'Market Assumptions',
               path: `${projectPath}#market-assumptions`,
               hide: props.scenario?.id === undefined,
            },
            {
               name: 'Market Profile',
               path: `${projectPath}#market-profile`,
               hide: !props.scenario?.instanceId,
            },
            {
               name: 'Financial',
               path: `${projectPath}#financial`,
               hide: props.scenario?.id === undefined,
            },
            {
               name: 'Scenarios',
               path: `${projectPath}#scenarios-comparison`,
               hide: !props.scenarios || props.scenarios.length <= 1,
            },
            // {
            //    name: 'Competition',
            //    path: `${projectPath}/competition`,
            // },
         ],
      },
      // {
      //    name: 'HexMP',
      //    icon: <HexagonIcon />,
      //    path: ``,
      //    isOpen: false,
      //    subNav: [
      //       {
      //          name: 'Summary',
      //          path: `${projectPath}/hexmp/summary`,
      //          hide: !props.scenario?.instanceId,
      //       },
      //       {
      //          name: 'Demographic',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Business',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Residential',
      //          path: ``,
      //          hide: true,
      //       },
      //    ],
      // },
      // {
      //    name: 'HexCS',
      //    icon: <HexagonIcon />,
      //    path: ``,
      //    isOpen: false,

      //    subNav: [
      //       {
      //          name: 'Speed',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Technology',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Price',
      //          path: ``,
      //          hide: true,
      //       },
      //    ],
      // },
      // {
      //    name: 'HexEA',
      //    icon: <HexagonIcon />,
      //    isOpen: false,

      //    path: ``,
      //    subNav: [
      //       {
      //          name: 'Physical makeup',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Roadway',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Underground/Aerial',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Design',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Central/Decentralized split',
      //          path: ``,
      //          hide: true,
      //       },
      //       {
      //          name: 'Bill of materials',
      //          path: ``,
      //          hide: true,
      //       },
      //    ],
      // },
      // {
      //    name: 'HexVAL',
      //    icon: <HexagonIcon />,
      //    isOpen: false,

      //    path: ``,
      //    subNav: [
      //       {
      //          name: 'Summary',
      //          path: `${projectPath}/hexval/summary`,
      //       },
      //       {
      //          name: 'Take rate curve',
      //          path: ``,
      //          hide: true,
      //       },
      //    ],
      // },

      {
         name: 'Maps',
         icon: <MapIcon />,
         path: `${projectPath}/`,
         subNav: [
            {
               name: props.project.name,
               path: `${projectPath}/map`,
               hide: props.scenario?.publishedMapId === null && props.scenario?.publishedMapId === null,
            },
         ],
      },
      {
         name: 'Downloads',
         icon: <CloudDownloadIcon />,
         path: `${projectPath}/downloads`,
         subNav: props.project.assets.map((asset) => {
            return {
               name: asset.name,
               path: `${projectPath}/downloads`,
            };
         }),
      },
   ];

   useEffect(() => {
      const initialIsOpenState: any = {};

      navObj.forEach((nav) => {
         initialIsOpenState[nav.name] = true;
      });
      setIsOpen(initialIsOpenState);
   }, []);

   const handleNavClick = (navName: string) => {
      const updatedIsOpen = { ...isOpen };
      const newState = !isOpen[navName];
      updatedIsOpen[navName] = newState;
      setIsOpen(updatedIsOpen);
   };

   return (
      <div className="flex column start items-center project-nav pr-1">
         <div className="flex column w-100 ">
            <Link to={'/'} className="flex center">
               <img src={logo} alt="" />
            </Link>
            <List className="flex column gap mt-5">
               {navObj.map((nav) => {
                  // if (nav.subNav.every((subNav) => subNav.hide)) return null;
                  return (
                     <div key={nav.name}>
                        <ListItemButton className="category" sx={{ color: 'white' }} onClick={() => handleNavClick(nav.name)}>
                           <ListItemIcon className="mr-2" sx={{ color: 'white', minWidth: 'auto' }}>
                              {nav.icon}
                           </ListItemIcon>
                           <ListItemText primary={nav.name} />
                           {isOpen[nav.name] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isOpen[nav.name]} timeout="auto" unmountOnExit>
                           {nav.name !== 'Downloads' ? (
                              <List component="div" disablePadding className="flex column gap-small">
                                 {nav.subNav.map((subNav) => {
                                    if (subNav.hide) return null;
                                    return (
                                       <ListItemButton className={`subcategory ${subNav.hide ? 'hidden' : ''} ${subNav.path.split('/')[props.scenario?.id !== undefined ? 5 : 3] === categoryPath ? 'active' : ''}`} key={subNav.name} onClick={() => !subNav.hide && navigate(subNav.path)}>
                                          <ListItemText sx={{ pl: 4 }} primary={subNav.name}></ListItemText>
                                       </ListItemButton>
                                    );
                                 })}
                              </List>
                           ) : (
                              <ProjectAssets project={props.project} scenarioId={props.scenario?.id} />
                           )}
                        </Collapse>
                     </div>
                  );
               })}
            </List>
         </div>
      </div>
   );
}
