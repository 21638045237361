import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { isEmail } from '../../helpers/helper';
import { SignUpModel } from '../../models/signup.model';
import { WarningMessage } from '../../models/warningMessage.model';
import { createUser } from '../../services/user.service';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export function SignUp(props: { setWarningMessage: React.Dispatch<React.SetStateAction<WarningMessage>> }) {
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);

   const [form, setForm] = useState({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      repeatPassword: '',
      inviteCode: undefined,
   } as SignUpModel);

   useEffect(() => {}, [queryParams]);

   useEffect(() => {
      if (!location.search) return;
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.get('code')) {
         setForm({ ...form, inviteCode: queryParams.get('code') ?? undefined });
      }
   }, [location.search]);

   const navigate = useNavigate();

   const submit = async (e: any) => {
      e.preventDefault();
      try {
         const response = await createUser(form);
         if (!response.error) {
            setForm({
               firstname: '',
               lastname: '',
               email: '',
               password: '',
               repeatPassword: '',
               inviteCode: undefined,
            } as SignUpModel);
            props.setWarningMessage({
               error: false,
               message: 'Account successfully created',
            });
            navigate('/login');
         } else {
            props.setWarningMessage({ error: true, message: response.error });
         }
      } catch (e: any) {
         props.setWarningMessage({ error: true, message: e.toString() });
      }
   };

   return (
      <form onSubmit={submit}>
         <h2>Create your account</h2>
         {form.inviteCode && (
            <div className="flex center">
               <div className="invite-code">Invite Code: {form.inviteCode}</div>
            </div>
         )}

         <div className="flex column mt-4">
            <div className="flex between mb-3">
               <TextField className="mr-1 w-100" label="First Name" variant="outlined" value={form.firstname} onChange={(event: any) => setForm({ ...form, firstname: event.target.value })} />
               <TextField className="ml-1 w-100" label="Last Name" variant="outlined" value={form.lastname} onChange={(event: any) => setForm({ ...form, lastname: event.target.value })} />
            </div>
            <TextField
               label="Email"
               type={'email'}
               variant="outlined"
               value={form.email}
               onChange={(event: any) => setForm({ ...form, email: event.target.value })}
               error={!isEmail(form.email) && form.email?.length > 0}
               helperText={!isEmail(form.email) && form.email?.length > 0 ? 'Please provide a valid email address.' : ''}
               required={true}
            />
            <div className="flex column mt-5">
               <TextField
                  className="mb-3"
                  label="Password"
                  type={'password'}
                  variant="outlined"
                  value={form.password}
                  onChange={(event: any) => setForm({ ...form, password: event.target.value })}
                  helperText={form.password?.length < 8 && form.password?.length > 0 ? 'Password needs to be at least 8 characters long.' : ''}
                  required={true}
               />
               <TextField
                  label="Repeat Password"
                  type={'password'}
                  variant="outlined"
                  value={form.repeatPassword}
                  onChange={(event: any) => setForm({ ...form, repeatPassword: event.target.value })}
                  error={form.password !== form.repeatPassword && form.repeatPassword?.length > 0}
                  helperText={form.password !== form.repeatPassword && form.repeatPassword?.length > 0 ? 'Passwords need to match.' : ''}
                  required={true}
               />
            </div>
            <Button className="mt-4" variant="contained" type="submit">
               SIGN UP
            </Button>
            <Link className="mt-4 small-link" to="/login">
               You already have an account? Sign In
            </Link>
         </div>
      </form>
   );
}
