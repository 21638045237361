import { ResponseModel } from '../models/response.model';

export const fetchWithValidation = async (url: string, options: RequestInit): Promise<any> => {
   const result = await fetch(url, options);
   return validateResponse({ status: result.status, data: await result.json() });
};

const validateResponse = (response: ResponseModel): any => {
   if (!response.status || !response.data) {
   }
   switch (response.status) {
      case 200:
      case 201:
      case 400:
      case 409:
      case 500:
         return response.data;
      case 401:
         return window.location.replace('/');
      case 404:
         return response.data;
      case 403:
         // console.log('403');
         // //check if logout param is set to true
         // if (window.location.href.indexOf('logout=true') === -1) {
         //    return window.location.replace('/?logout=true');
         // }
         return response.data;
      default:
         return response.data;
   }
};
