import { Box, FormControl, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import { UserAccount } from '../../../../../models/user.model';
import { Account } from '../../../../../models/account.model';

export function AccountsEdit(props: { params: GridRenderCellParams<UserAccount>; accounts: Account[] }) {
   const { id, field, value } = props.params;
   const currentAccounts = (value ?? []) as UserAccount[];
   const apiRef = useGridApiContext();

   return (
      <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
         <FormControl fullWidth className="no-round">
            <Select
               defaultOpen={true}
               value={currentAccounts}
               label="Status"
               multiple
               renderValue={(selected: UserAccount[]) => selected.map((userAccount: UserAccount) => userAccount.account.name).join(', ')}
               onChange={(_event: any, val: any) => {
                  var newAccounts: UserAccount[] = [];
                  const changedAccountId = val.props.value;
                  const exists = currentAccounts.map((userAccount: UserAccount) => userAccount.accountId).includes(changedAccountId);
                  if (!exists) {
                     const accountToAdd = props.accounts.filter((account: Account) => account.id === changedAccountId)[0];
                     newAccounts = [...currentAccounts, { accountId: accountToAdd.id, account: accountToAdd }];
                  } else {
                     newAccounts = currentAccounts.filter((userAccount: UserAccount) => userAccount.accountId !== changedAccountId);
                  }
                  apiRef.current.setEditCellValue({
                     id,
                     field,
                     value: newAccounts,
                  });
               }}>
               {props.accounts.map((account: Account, index: number) => (
                  <MenuItem key={index} value={account.id}>
                     <Checkbox checked={currentAccounts.map((userAccount: UserAccount) => userAccount.accountId).indexOf(account.id) > -1} />
                     <ListItemText primary={account.name} />
                  </MenuItem>
               ))}
            </Select>
         </FormControl>
      </Box>
   );
}
