import { Alert, TextField, Button, CardContent, Card, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { UpdatePassword } from '../../models/updatePassword.model';
import { WarningMessage } from '../../models/warningMessage.model';
import { authStatus, updatePassword } from '../../services/auth.service';
import { SimpleModal } from '../shared/ui/modals/SimpleModal';

export function MyAccount(props: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
   const [topMessage, setTopMessage] = useState({
      error: false,
      message: null,
   } as WarningMessage);
   const [form, setForm] = useState<UpdatePassword>({
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
   });

   const submit = async (e: any) => {
      e.preventDefault();
      try {
         if (form.newPasswordConfirm.length && form.newPasswordConfirm !== form.newPassword) {
            setTopMessage({ error: true, message: 'Passwords need to match.' });
            return;
         }
         if (authStatus().content?.id === undefined) return;
         const response = await updatePassword(authStatus().content?.id ?? '', form);
         if (!response.error) {
            setForm({ password: '', newPassword: '', newPasswordConfirm: '' });
            setTopMessage({ error: false, message: response.message });
         } else {
            setTopMessage({ error: true, message: response.error });
         }
      } catch (e: any) {
         setTopMessage({ error: true, message: e.toString() });
      }
   };

   useEffect(() => {
      if (topMessage.message) {
         setTimeout(() => setTopMessage({ error: false, message: null }), 30000);
      }
   }, [topMessage]);

   return (
      <SimpleModal open={props.open} setOpen={props.setOpen}>
         <div className="flex column gap-20">
            <Card>
               <CardContent>
                  <Typography sx={{ mb: 2 }} variant="h6" component="div">
                     My Account
                  </Typography>
                  <div className="flex column start items-start mt-4">
                     <p>
                        {authStatus().content?.firstname} {authStatus().content?.lastname}
                     </p>
                     <p>{authStatus().content?.email}</p>
                  </div>
               </CardContent>
            </Card>
            <Card sx={{ minWidth: '20vw' }}>
               <CardContent>
                  <Typography sx={{ mb: 2 }} variant="h6" component="div">
                     Update Password
                  </Typography>
                  <form onSubmit={submit} className="flex column mt-4">
                     {topMessage.message !== null && (
                        <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error' : 'success'}>
                           {topMessage.message}
                        </Alert>
                     )}
                     <TextField label="Current Password" type={'password'} variant="outlined" value={form.password} required onChange={(event: any) => setForm({ ...form, password: event.target.value })} />
                     <TextField className="mt-3" label="New Password" type={'password'} variant="outlined" required value={form.newPassword} onChange={(event: any) => setForm({ ...form, newPassword: event.target.value })} />
                     <TextField
                        className="mt-2 mb-4"
                        label="Confirm New Password"
                        type={'password'}
                        variant="outlined"
                        required
                        value={form.newPasswordConfirm}
                        onChange={(event: any) =>
                           setForm({
                              ...form,
                              newPasswordConfirm: event.target.value,
                           })
                        }
                        error={form.newPasswordConfirm.length > 0 && form.newPasswordConfirm !== form.newPassword}
                        helperText={form.newPasswordConfirm.length && form.newPasswordConfirm !== form.newPassword ? 'Passwords need to match.' : ''}
                     />
                     <Button className="mt-4" variant="contained" type="submit">
                        UPDATE PASSWORD
                     </Button>
                  </form>
               </CardContent>
            </Card>
         </div>
      </SimpleModal>
   );
}
