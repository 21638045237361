import { PatchModel } from '../models/path.model';
import { FIConfig, FinancialModel, Project } from '../models/project.model';
import { SearchModel } from '../models/search.model';
import { fetchWithValidation } from './fetch.service';

const API_URL = process.env.REACT_APP_API as String;

export const getProjects = async (searchModel: SearchModel, admin: boolean, accountIds?: string[] | undefined): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(
      `${API_URL}projects${admin ? '/detailed' : ''}?search=${searchModel?.search}&offset=${(searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100)}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${searchModel?.orderBy}&asc=${searchModel?.asc}&accounts=${accountIds}`,
      requestOptions
   );
};

export const getProject = async (id: string, admin: boolean = false): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}projects${admin ? '/detailed' : ''}/${id}`, requestOptions);
};

export const patchProject = async (patch: PatchModel): Promise<any> => {
   const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(patch),
   };
   return fetchWithValidation(`${API_URL}projects/${patch.id}`, requestOptions);
};

export const putProject = async (projectPut: Project): Promise<any> => {
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(projectPut),
   };
   return fetchWithValidation(`${API_URL}projects/${projectPut.id}`, requestOptions);
};

export const createProject = async (name: string): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({ name: name }),
   };
   return fetchWithValidation(`${API_URL}projects/`, requestOptions);
};

export const getFeasibilityInstances = async (projectId: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}feasibility-instances?projectId=` + projectId, requestOptions);
};

export const runFeasibilityInstance = async (projectId: string, config: FIConfig, boundaryType: string | null, boundaryName: string | null, boundaryContent: string | null, customBslContent: string | null, customBslType: string | null): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({
         projectId: projectId,
         boundaryType: boundaryType,
         boundaryName: boundaryName,
         boundaryContent: boundaryContent,
         configJSON: JSON.stringify(config),
         customBslContent: customBslContent,
         customBslType: customBslType,
      }),
   };

   return fetchWithValidation(`${API_URL}feasibility-instances/run`, requestOptions);
};

export const updateFeasibilityInstance = async (projectId: string, instanceId: number, status: string | null, notes: string | null): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({
         projectId: projectId,
         instanceId: instanceId,
         status: status,
         notes: notes,
      }),
   };

   return fetchWithValidation(`${API_URL}feasibility-instances/update`, requestOptions);
};

export const deleteFeasibilityInstance = async (projectId: string, instanceId: number): Promise<any> => {
   const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };

   return fetchWithValidation(`${API_URL}feasibility-instances?projectId=${projectId}&instanceId=${instanceId}`, requestOptions);
};

export const uploadThumbnail = async (projectId: string, data: FormData): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      credentials: 'include' as any,
      body: data,
   };

   return fetchWithValidation(`${API_URL}projects/${projectId}/thumbnail`, requestOptions);
};

export const getFinancialModels = async (projectId: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };

   return fetchWithValidation(`${API_URL}financial-models?projectId=` + projectId, requestOptions);
};

export const getScenarios = async (projectId: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };

   return fetchWithValidation(`${API_URL}financial-models/scenarios?projectId=` + projectId, requestOptions);
};

export const getScenarioData = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };

   return fetchWithValidation(`${API_URL}financial-models/scenario/` + id, requestOptions);
};

export const runFinancialModel = async (financial: FinancialModel): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(financial),
   };

   return fetchWithValidation(`${API_URL}financial-models/run`, requestOptions);
};

export const runCustomFinancialModel = async (financial: FinancialModel): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(financial),
   };

   return fetchWithValidation(`${API_URL}financial-models/run-custom`, requestOptions);
};

export const getAllFinancialModelCharts = async (projectId: string, id?: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}financial-models/charts-all?projectId=${projectId}${id !== undefined ? `&id=${id}` : ''}`, requestOptions);
};

export const getFinancialModelById = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}financial-models/${id}`, requestOptions);
};

export const updateFinancialModel = async (id: string, status: string | null, name: string | null, mapId: string | null, notes: string | null): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify({
         id: id,
         status: status,
         name: name,
         mapId: mapId,
         notes: notes,
      }),
   };

   return fetchWithValidation(`${API_URL}financial-models/update`, requestOptions);
};

export const getMarketAssumptionsById = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}financial-models/market-assumptions/${id}`, requestOptions);
};

export const getMarketProfileById = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}financial-models/market-profile/${id}`, requestOptions);
};

export const getScenarioComparison = async (projectId: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetchWithValidation(`${API_URL}financial-models/scenarios-comparison/?projectId=${projectId}`, requestOptions);
};
