import { Account } from "../models/account.model";
import { PatchModel } from "../models/path.model";
import { SearchModel } from "../models/search.model";
import { fetchWithValidation } from "./fetch.service";

const API_URL = process.env.REACT_APP_API as String;

export const getAccounts = async(searchModel: SearchModel, admin: boolean): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetchWithValidation(`${API_URL}accounts${admin ? '/detailed': ''}?search=${searchModel?.search}&offset=${((searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100))}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${searchModel?.orderBy}&asc=${searchModel?.asc}`, requestOptions);
}

export const getAccount = async(id: string): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetchWithValidation(`${API_URL}accounts/${id}`, requestOptions);
}

export const patchAccount = async(patch: PatchModel): Promise<any> => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(patch)
    };
    return fetchWithValidation(`${API_URL}accounts/${patch.id}`, requestOptions);
}

export const putAccount = async(accountPut: Account): Promise<any> => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(accountPut)
    };
    return fetchWithValidation(`${API_URL}accounts/${accountPut.id}`, requestOptions);
}

export const createAccount = async(): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
    };
    return fetchWithValidation(`${API_URL}accounts/`, requestOptions);
}