import { Fragment, useEffect, useState } from 'react';
import { getMeta } from '../../../helpers/helper';
import { FinancialCharts, FinancialModel, MarketAssumptionsModel, MarketProfileModel, Project, ScenarioComparison } from '../../../models/project.model';
import { PassingsCharts } from './PassingsCharts';
import { ScenarioBadges } from './ScenarioBadges';
import { DistributionByHexbinValue2 } from '../../admin/admin-content/projects/charts/DistributionByHexbinValue2';
import { MarketProfile } from './MarketProfile';
import { NetValuePerAreaCharts } from './NetValuePerAreaCharts';
import mapPlaceholder from '../../../static/assets/images/map-placeholder.png';
import { useNavigate } from 'react-router-dom';
import { MarketAssumptions } from './MarketAssumptions';
import { ScenariosComparison } from './ScenariosComparison';

export function Summary(props: { project: Project; scenariosComparison: ScenarioComparison[] | null; scenario?: FinancialModel | null; chartData?: FinancialCharts; marketProfile?: MarketProfileModel; marketAssumptions?: MarketAssumptionsModel | null }) {
   const [chartContainerWidth, setChartContainerWidth] = useState<number>(0);
   const navigate = useNavigate();

   useEffect(() => {
      function updateGridHeight() {
         const width = document.getElementById('chart-container')?.clientWidth;
         setChartContainerWidth(width ?? 0);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, []);

   useEffect(() => {
      const hash = window.location.hash;
      if (hash) {
         const element = document.getElementById(hash.substring(1));
         if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
               window.history.pushState('', document.title, window.location.pathname + window.location.search);
            }, 1000);
         }
      }
   }, [window.location.hash]);

   return (
      <div className="flex column center gap">
         <ScenarioBadges project={props.project} scenarioId={props.scenario?.id} />

         <div className="flex gap w-100">
            <div className={`flex ${props.scenario?.publishedMapId !== null || props.scenario?.publishedMapId !== null ? 'column' : ''} gap w-100`}>
               <div className="section-card no-height flex gap column">
                  <h2>EXECUTIVE SUMMARY</h2>
                  <p className="white">
                     {getMeta(props.project.projectMeta, 'description')
                        .split('\n')
                        .map((line: string, index: number) => (
                           <Fragment key={index}>
                              {line}
                              <br />
                           </Fragment>
                        ))}
                  </p>
               </div>
               <div className="section-card  no-height flex gap column ">
                  <h2>NEED HELP?</h2>
                  <p>
                     If you have any questions, please contact us at{' '}
                     <a className="yellow" href="mailto:info@hexvarium.com">
                        info@hexvarium.com
                     </a>
                     .
                  </p>
               </div>
            </div>
            {(props.scenario?.publishedMapId !== null || props.scenario?.publishedMapId !== null) && (
               <div
                  onClick={() => navigate(`/project/${props.project.id}${props.scenario?.id ? `/scenario/${props.scenario?.id}` : ''}/map`)}
                  className="section-card map-placeholder w-50"
                  style={{
                     backgroundImage: props.project.thumbnail !== undefined ? `url(${props.project.thumbnail})` : mapPlaceholder,
                  }}>
                  {' '}
                  <h2>MAP</h2>
               </div>
            )}
         </div>

         <MarketAssumptions project={props.project} marketAssumptions={props.marketAssumptions} />

         {props.marketProfile && <MarketProfile project={props.project} marketProfile={props.marketProfile} />}

         {props.chartData && props.chartData.passingsByHexbinValue && props.chartData.passingsByCompetitiveScore && (
            <div id="financial">
               <div id="chart-container" className="section-card flex column">
                  {/* <h2>PASSINGS</h2>
               <PassingsCharts project={props.project} chartData={props.chartData} /> */}
                  <h2>DISTRIBUTION BY HEXBIN VALUE</h2>
                  <div className="pt-5">
                     <DistributionByHexbinValue2 values={props.chartData.distributionByHexbinValue} isFullscreen={false} parentWidth={chartContainerWidth - 50} />
                  </div>
               </div>
            </div>
         )}

         {props.chartData && (
            <>
               {props.chartData.passingsByHexbinValue && props.chartData.passingsByCompetitiveScore && (
                  <div className="section-card flex column">
                     <h2>PASSINGS</h2>
                     <PassingsCharts project={props.project} chartData={props.chartData} />
                  </div>
               )}

               {props.chartData.netValuePerArea && props.chartData.netValuePerArea?.perCustomer?.length > 0 && props.chartData.netValuePerArea?.total?.length > 0 && (
                  <div className="section-card flex column ">
                     <h2>NET VALUE PER AREA</h2>
                     <NetValuePerAreaCharts chartData={props.chartData} />
                     {/* <div className="pt-5">
                        <NetValuePerAreaChart2 values={props.chartData.netValuePerArea.total} isFullscreen={false} parentWidth={chartContainerWidth - 50} />
                     </div> */}
                  </div>
               )}
            </>
         )}

         {props.scenariosComparison && props.scenariosComparison?.length > 1 && props.project && <ScenariosComparison projectId={props.project.id} selectedScenarioId={props.scenario?.id} scenariosComparison={props.scenariosComparison} />}
      </div>
   );
}
