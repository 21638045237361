import { Box, FormControl, Select, MenuItem } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';

export function StatusEdit(props: GridRenderCellParams<boolean>) {
   const { id, value, field } = props;
   const apiRef = useGridApiContext();
   return (
      <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
         <FormControl fullWidth className="no-round">
            <Select
               defaultOpen={true}
               value={value}
               label="Status"
               onChange={(event: any, val: any) => {
                  apiRef.current.setEditCellValue({
                     id,
                     field,
                     value: val.props.value == 'true',
                  });
               }}
            >
               <MenuItem value={'true'}>Active</MenuItem>
               <MenuItem value={'false'}>Inactive</MenuItem>
            </Select>
         </FormControl>
      </Box>
   );
}
