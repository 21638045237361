import { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FinancialCharts, Project } from '../../../models/project.model';
import { HistogramChart2 } from '../../admin/admin-content/projects/charts/HistogramChart2';

export function PassingsCharts(props: { project: Project; chartData: FinancialCharts }) {
   const [tabIndex, setTabIndex] = useState<string>('0');
   const [chartContainerWidth, setChartContainerWidth] = useState<number>(0);

   useEffect(() => {
      function updateGridHeight() {
         const width = document.getElementById('chart-container-passings')?.clientWidth;
         setChartContainerWidth(width ?? 0);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, []);

   return (
      <div className="w-100">
         <TabContext value={tabIndex}>
            <div className="flex column project-tabs">
               <TabList
                  onChange={(event: React.SyntheticEvent, newValue: string) => {
                     setTabIndex(newValue);
                  }}>
                  <Tab className="pr-3" label="BY HEXBIN VALUE (CLV)" value="0" />
                  <Tab className="px-3" label="BY COMPETITIVE SCORE" value="1" />
                  <Tab className="px-3" label="BY TAKE RATE" value="2" />
                  <Tab className="px-3" label="BY COST PER PASSING" value="3" />
                  <Tab className="px-3" label="BY FEET PER PASSING" value="4" />
                  <Tab className="px-3" label="BY FTTC Mile" value="5" />
               </TabList>
               <div id="chart-container-passings" className="flex column w-100 pt-5">
                  <TabPanel value="0">
                     <HistogramChart2 values={props.chartData.passingsByHexbinValue} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                  </TabPanel>
                  <TabPanel value="1">
                     <HistogramChart2 values={props.chartData.passingsByCompetitiveScore} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                  </TabPanel>
                  <TabPanel value="2">
                     <HistogramChart2 values={props.chartData.passingsByTakeRate} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                  </TabPanel>
                  <TabPanel value="3">
                     <HistogramChart2 values={props.chartData.passingsByCostPerPassing} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                  </TabPanel>
                  <TabPanel value="4">
                     <HistogramChart2 values={props.chartData.passingsByFeetPerPassing} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                  </TabPanel>
                  <TabPanel value="5">
                     <HistogramChart2 values={props.chartData.passingsByFTTCMile} isFullscreen={false} parentWidth={chartContainerWidth} labeled />
                  </TabPanel>
               </div>
            </div>
         </TabContext>
      </div>
   );
}
