import { useEffect, useState } from 'react';
import { AdminNav } from './AdminNav';
import { authStatus } from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';

export function Admin(props: { children?: React.ReactNode }) {
   const navigate = useNavigate();
   const [isAdmin, setIsAdmin] = useState<boolean>(false);

   useEffect(() => {
      console.log(authStatus());
      // if (!authStatus().content?.roles.includes('ADMIN')) {
      if (!['ADMIN', 'FINADMIN'].some((role) => authStatus().content?.roles.includes(role))) {
         navigate('/');
      } else {
         setIsAdmin(true);
      }
   }, []);

   if (!isAdmin) return null;

   return (
      <div className="admin-panel flex">
         <AdminNav />
         <div>{props.children}</div>
      </div>
   );
}
