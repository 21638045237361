import { PatchModel } from "../models/path.model";
import { SearchModel } from "../models/search.model";
import { SignUpModel } from "../models/signup.model";
import { FoursquareToken } from "../models/token.model";
import { User } from "../models/user.model";
import { fetchWithValidation } from "./fetch.service";

const API_URL = process.env.REACT_APP_API as String;

export const getFoursquareToken = async(): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetchWithValidation(`${API_URL}tokens/foursquare`, requestOptions)
}


export const saveFoursquareToken = async(refreshToken: string): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            refreshToken: refreshToken
        }),
        credentials: 'include' as any
    };

    return fetchWithValidation(`${API_URL}tokens/foursquare`, requestOptions);
}