import { useState } from 'react';

export function Pagination(props: PaginationProps) {
   const [hide, setHide] = useState<boolean>(false);
   return (
      <div className={`flex pagination ${hide ? 'hide' : ''}`}>
         {[...Array(props.pages)].map((_, index) => {
            return (
               <button
                  key={index}
                  className={`pagination-item ${props.page === index ? 'active' : ''} `}
                  onClick={() => {
                     setHide(true);
                     props.setPage(index);
                     setTimeout(() => {
                        setHide(false);
                     }, 500);
                  }}>
                  {index + 1}
               </button>
            );
         })}
      </div>
   );
}

export interface PaginationProps {
   pages: number;
   page: number;
   setPage: (page: number) => void;
}
