import { GridRenderCellParams } from '@mui/x-data-grid';
import { UserRole } from '../../../../../models/user.model';

export function RenderRoles(params: GridRenderCellParams<UserRole[]>) {
   return (
      <div className="flex gap-small">
         {params.row.userRoles.map((userRole: UserRole, index: number) => (
            <span key={index} className={`role-status ${userRole.role.role.toLocaleLowerCase()}`}>
               {userRole.role.role}
            </span>
         ))}
      </div>
   );
}
