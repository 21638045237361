import { Project } from '../../models/project.model';
import { getAsset } from '../../services/asset.service';
import { Asset } from '../../models/project.model';
import { List, ListItemText, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HelpIcon from '@mui/icons-material/Help';

export function ProjectAssets(props: { project: Project; scenarioId?: string }) {
   const downloadFile = async (fileId: string, fileName: string) => {
      try {
         await getAsset(fileId, fileName);
      } catch (e) {
         console.log(e);
      }
   };

   return (
      <List component="div" disablePadding className="flex column gap-small">
         {props.project?.assets
            .filter((asset: Asset) => !asset.financialModel?.id || (props.scenarioId && asset.financialModel.id === props.scenarioId))
            .map((asset: Asset, index: number) => {
               return (
                  <ListItemButton className={`subcategory flex items-center gap-small`} key={index} onClick={() => downloadFile(asset.id, asset.fileName)}>
                     <ListItemIcon sx={{ pl: 4 }}>
                        <InsertDriveFileIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText sx={{ pl: 0 }} primary={asset.name}></ListItemText>
                     <Tooltip title={`${asset.description}`} placement="bottom">
                        <HelpIcon />
                     </Tooltip>
                  </ListItemButton>
               );
            })}
      </List>
   );
}
