import { useEffect, useState } from 'react';
import { Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { DynamicMarketAssumption, MarketAssumptionsModel, ScenarioComparison } from '../../../models/project.model';
import { Link } from 'react-router-dom';

export function ScenariosComparison(props: { projectId: string; selectedScenarioId?: string; scenariosComparison: ScenarioComparison[] | null }) {
   const [orderedScenarios, setOrderedScenarios] = useState<ScenarioComparison[] | null>(null);

   useEffect(() => {
      if (props.scenariosComparison === null) return;

      const scenarios = [props.scenariosComparison.find((scenario: ScenarioComparison) => scenario.id === props.selectedScenarioId), ...props.scenariosComparison.filter((scenario: ScenarioComparison) => scenario.id !== props.selectedScenarioId)] as ScenarioComparison[];
      setOrderedScenarios(scenarios);
      console.log('scenarios', scenarios);
   }, [props.selectedScenarioId, props.scenariosComparison]);

   const formatAmount = (amount: number): string => {
      if (amount < 0) return `-$${Math.abs(amount).toLocaleString()}`;
      return `$${amount.toLocaleString()}`;
   };

   return (
      <div id="scenarios-comparison">
         <div className="section-card flex column gap market-assumptions scenarios-comparison">
            <div className="flex items-center between">
               <h2>SCENARIOS COMPARISON</h2>
            </div>
            <div className="w-100">
               {orderedScenarios !== null && (
                  <TableContainer>
                     <Table>
                        <TableHead>
                           <TableRow>
                              <TableCell key="empty-cell"></TableCell>
                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`scenario-${index}`}>{scenario.id !== props.selectedScenarioId ? <Link to={`/project/${props.projectId}${props.selectedScenarioId !== undefined ? `/scenario/${scenario.id}` : ''}`}>{scenario.name}</Link> : scenario.name}</TableCell>;
                              })}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {/* inputs */}
                           <TableRow>
                              <TableCell key="aerial-cost">Aerial Cost</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`aerial-cost-${index}`}>${Number(scenario.marketAssumption.financialModel.inputs.construction_cost_per_mile_aerial)?.toLocaleString()}</TableCell>;
                              })}
                           </TableRow>
                           <TableRow>
                              <TableCell key="underground-cost">Underground Cost</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`underground-cost-${index}`}>${Number(scenario.marketAssumption.financialModel.inputs.construction_cost_per_mile_underground)?.toLocaleString()}</TableCell>;
                              })}
                           </TableRow>
                           <TableRow>
                              <TableCell key="aerial-percentage">Aerial Percentage</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`aerial-percentage-${index}`}>{Math.round(Number(scenario.marketAssumption.financialModel.inputs.aerial_pct_fttc) * 100)}%</TableCell>;
                              })}
                           </TableRow>
                           <TableRow>
                              <TableCell key="underground-percentage">Underground Percentage</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`underground-percentage-${index}`}>{100 - Math.round(Number(scenario.marketAssumption.financialModel.inputs.aerial_pct_fttc) * 100)}%</TableCell>;
                              })}
                           </TableRow>

                           {orderedScenarios[0].marketAssumption.dynamicMarketAssumptions.map((market: DynamicMarketAssumption) => {
                              if (market.label !== 'Market Profile' && market.label !== 'Capex') return null;

                              return market.values.map((dynamicValue: { label: string; value: number; unit?: string; unitAfter?: boolean }, index: number) => {
                                 if (!orderedScenarios) return null;

                                 return (
                                    <TableRow key={`market-${index}`}>
                                       <TableCell key={`market-label-${index}`}>{dynamicValue.label}</TableCell>
                                       {orderedScenarios.map((scenario: ScenarioComparison, scenarioIndex: number) => {
                                          const data = scenario.marketAssumption.dynamicMarketAssumptions.find((innerMarket: DynamicMarketAssumption) => innerMarket.label === market.label);
                                          if (!data) return <TableCell key={`market-data-${index}-${scenarioIndex}`}>-</TableCell>;

                                          const value = data.values.find((value) => value.label === dynamicValue.label);
                                          if (!value) return <TableCell key={`market-value-${index}-${scenarioIndex}`}>-</TableCell>;

                                          if (market.label === 'Capex') {
                                             return (
                                                <TableCell key={`market-capex-${index}-${scenarioIndex}`}>
                                                   {Number(value.value) < 0 && !value.unitAfter && value.unit !== undefined ? `-` : ''}
                                                   {value.unit !== undefined && !value.unitAfter ? value.unit : ''}
                                                   {Number((!value.unitAfter && value.unit && Number(value.value < 0) ? -1 : 1) * Math.round(Number(value.value))).toLocaleString()}
                                                </TableCell>
                                             );
                                          } else {
                                             return <TableCell key={`market-other-${index}-${scenarioIndex}`}>{Math.round(Number(value.value)).toLocaleString()}</TableCell>;
                                          }
                                       })}
                                    </TableRow>
                                 );
                              });
                           })}

                           {/* info */}
                           <TableRow>
                              <TableCell key="capex">Unoptimized Capex</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`capex-${index}`}>{formatAmount(Number(scenario.scenarioInfo.capex))}</TableCell>;
                              })}
                           </TableRow>
                           <TableRow>
                              <TableCell key="revenue">Unoptimized Revenue</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`revenue-${index}`}>{formatAmount(Number(scenario.scenarioInfo.revenue))}</TableCell>;
                              })}
                           </TableRow>
                           <TableRow>
                              <TableCell key="npv">Unoptimized NPV</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`npv-${index}`}>{formatAmount(Number(scenario.scenarioInfo.npv))}</TableCell>;
                              })}
                           </TableRow>
                           <TableRow>
                              <TableCell key="irr">Unoptimized IRR</TableCell>

                              {orderedScenarios.map((scenario: ScenarioComparison, index: number) => {
                                 return <TableCell key={`irr-${index}`}>{Number(scenario.scenarioInfo.irr).toFixed(2)}%</TableCell>;
                              })}
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
               )}
            </div>
         </div>
      </div>
   );
}
