import { fetchWithValidation } from "./fetch.service";

const API_URL = process.env.REACT_APP_API as String;

export const getRoles = async(): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetchWithValidation(`${API_URL}roles`, requestOptions);
}
