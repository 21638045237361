import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Slider, Tab, TextField, Tooltip } from '@mui/material';
import { FeasibilityInstance, FinancialModel, FinancialModelInputUserAssumptions, Project } from '../../../../../models/project.model';
import { useState } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Clear } from '@mui/icons-material';
import { runCustomFinancialModel } from '../../../../../services/project.service';
import { useSnackBar } from '../../../../../contexts/SnackbarContext';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export function RunFinancialModelForm(props: { project: Project; open: boolean; setOpen: CallableFunction; approvedInstances: FeasibilityInstance[]; selectedInstance: FeasibilityInstance | undefined; setSelectedInstance: CallableFunction; onSubmit: (data: FinancialModel) => void }) {
   const financialInitialState: FinancialModel = {
      inputs: {
         arpu: {
            inferior: undefined,
            value: undefined,
            direct: undefined,
            superlative: undefined,
            gig: undefined,
            include_gigplus: true,
            business_multiplier: 1.5,
         },
         take_rate: {
            residential_override: 0,
            business_override: 0,
            fiber_points: 45,
         },
         aerial_pct_fttc: undefined,
         construction_cost_per_mile_aerial: undefined,
         construction_cost_per_mile_underground: undefined,
         construction_cost_subscriber_drop_residential: 448,
         construction_cost_subscriber_ont_residential: 132,
         construction_cost_subscriber_install_residential: 200,
         revenue_lag: 2,
         construction_contingency: 0,
         baseline_drop_footage: 150,
         maintenance_capex: 0.1,
         internet_penetration: {
            period_end: 36,
         },
         churn: {
            voluntary_move_own: 0.0018736374,
            voluntary_move_rent: 0.007392351,
            voluntary_nonmove_baseline: 0.00675,
            involuntary_receiving_snap: 0.0167504188,
            involuntary_not_receiving_snap: 0.0020938023,
         },
         discount_rate: 0.0675,
         tax_rate: 0.25,
         arpu_growth_annual: 0.02,
         expense_growth_annual: 0.02,
         depreciable_life_years: 20,
      } as FinancialModelInputUserAssumptions,
   } as FinancialModel;

   const [loading, setLoading] = useState(false);
   const [newFinancialModel, setNewFinancialModel] = useState(financialInitialState);
   const [selectedFileLabel, setSelectedFileLabel] = useState('');
   const [selectedFile, setSelectedFile] = useState(null);
   const snackbar = useSnackBar();
   const [tabIndex, setTabIndex] = useState('1');

   const handleFileChange = (event: any) => {
      if (event.target.files.length === 0) return;
      setSelectedFile(event.target.files[0]);
      setSelectedFileLabel(event.target.files[0].name);
   };

   const handleClose = () => {
      setTabIndex('1');
      setNewFinancialModel(financialInitialState);
      props.setOpen(false);
      setSelectedFile(null);
      setSelectedFileLabel('');
   };

   const handleSubmit = async (e: React.FormEvent) => {
      setLoading(true);
      e.preventDefault();
      if (
         newFinancialModel.inputs.aerial_pct_fttc === undefined ||
         newFinancialModel.inputs.construction_cost_per_mile_aerial === undefined ||
         newFinancialModel.inputs.construction_cost_per_mile_underground === undefined ||
         // newFinancialModel.inputs.arpu?.gig === undefined ||
         newFinancialModel.inputs.arpu?.inferior === undefined ||
         newFinancialModel.inputs.arpu?.value === undefined ||
         newFinancialModel.inputs.arpu?.direct === undefined ||
         newFinancialModel.inputs.arpu?.superlative === undefined
      ) {
         snackbar.error('Please fill all required fields');
         setLoading(false);
         return;
      }

      if (selectedFile) {
         // get file content as string
         const fileReader = new FileReader();
         const readFileAsText = (file: any): Promise<string | any> => {
            return new Promise((resolve, reject) => {
               fileReader.onload = (event) => {
                  resolve(event?.target?.result);
               };
               fileReader.onerror = (event) => {
                  reject(event?.target?.error);
               };
               fileReader.readAsText(file);
            });
         };
         try {
            const fileContent = await readFileAsText(selectedFile);
            // You can further process the content here
            if (!fileContent) return;
            try {
               var response = await runCustomFinancialModel({
                  ...newFinancialModel,
                  projectId: props.project.id,
                  inputs: {
                     ...newFinancialModel.inputs,
                     aerial_pct_fttc: newFinancialModel.inputs.aerial_pct_fttc / 100,
                     take_rate: {
                        ...newFinancialModel.inputs.take_rate,
                        residential_override: (newFinancialModel.inputs.take_rate?.residential_override || 0) / 100,
                        business_override: (newFinancialModel.inputs.take_rate?.business_override || 0) / 100,
                     },
                  },
                  customDatafeed: fileContent,
               } as FinancialModel);
               if (!response.error) {
                  snackbar.success('New Financial Model is running');
               } else {
                  snackbar.error('Error running new Financial Model');
               }
            } catch (e) {
               console.log(e);
            }
         } catch (error) {
            console.error('Error reading file:', error);
         }
      } else {
         props.onSubmit({
            ...newFinancialModel,
            inputs: {
               ...newFinancialModel.inputs,
               aerial_pct_fttc: newFinancialModel.inputs.aerial_pct_fttc / 100,
               take_rate: {
                  ...newFinancialModel.inputs.take_rate,
                  residential_override: (newFinancialModel.inputs.take_rate?.residential_override || 0) / 100,
                  business_override: (newFinancialModel.inputs.take_rate?.business_override || 0) / 100,
               },
            },
         });
      }
      setLoading(false);
      handleClose();
      setNewFinancialModel(financialInitialState);
   };

   function getTooltipText(): React.ReactNode {
      const fields: string[] = [];

      if (!props.selectedInstance && !selectedFile) fields.push('DMA Instance or Custom Inputs');
      if (newFinancialModel.inputs.arpu?.inferior === undefined) fields.push('Inferior ARPU');
      if (newFinancialModel.inputs.arpu?.value === undefined) fields.push('Value ARPU');
      if (newFinancialModel.inputs.arpu?.direct === undefined) fields.push('Direct ARPU');
      if (newFinancialModel.inputs.arpu?.superlative === undefined) fields.push('Superlative ARPU');
      if (newFinancialModel.inputs.construction_cost_per_mile_aerial === undefined) fields.push('Aerial $/mile');
      if (newFinancialModel.inputs.construction_cost_per_mile_underground === undefined) fields.push('Underground $/mile');
      if (newFinancialModel.inputs.aerial_pct_fttc === undefined) fields.push('Aerial % of mile');
      if (newFinancialModel.inputs.arpu?.include_gigplus && newFinancialModel.inputs.arpu?.gig === undefined) fields.push('Gig +');

      if (fields.length > 0) {
         return (
            <div className="flex column p-2">
               <span style={{ fontSize: '20px' }}>Missing required fields:</span>
               <ul style={{ fontSize: '15px' }}>
                  {fields.map((field) => (
                     <li key={field}>{field}</li>
                  ))}
               </ul>
            </div>
         );
      }

      return '';
   }

   return (
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="lg">
         <DialogTitle>New Financial Scenario</DialogTitle>
         <form onSubmit={handleSubmit}>
            <DialogContent sx={{ minWidth: '30vw' }}>
               <div className="flex column gap">
                  <div className="flex gap items-center between">
                     {props.approvedInstances.length > 0 && (
                        <>
                           <FormControl className="w-50" disabled={selectedFile !== null}>
                              <InputLabel id="instance_id" sx={{ background: 'white', paddingRight: '10px' }}>
                                 Select DMA Instance
                              </InputLabel>
                              <Select
                                 value={props.selectedInstance?.instanceId ?? ''}
                                 required
                                 labelId="instance_id"
                                 label="instance_id"
                                 onChange={(event) => {
                                    props.setSelectedInstance(props.approvedInstances.find((instance: FeasibilityInstance) => instance.instanceId === Number(event.target.value)));
                                 }}>
                                 {props.approvedInstances.map((instance: FeasibilityInstance) => (
                                    <MenuItem key={instance.instanceId} value={instance.instanceId}>
                                       DMA Instance {instance.instanceId}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                           <Divider orientation="vertical" flexItem>
                              OR
                           </Divider>
                        </>
                     )}

                     <div className="flex center items-center column">
                        <Tooltip title="This feature won't generate a map.">
                           <Button variant="contained" component="label" startIcon={<FileUploadIcon style={{ color: 'white' }} />} color="primary">
                              Upload INPUTS
                              <input hidden type="file" onChange={handleFileChange} />
                           </Button>
                        </Tooltip>
                        {selectedFile !== null && (
                           <span
                              className="mt-2"
                              style={{
                                 maxWidth: '300px',
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 whiteSpace: 'nowrap',
                              }}>
                              {selectedFileLabel.length > 20 ? `${selectedFileLabel.substring(0, 15)}...${selectedFileLabel.substring(selectedFileLabel.lastIndexOf('.'))}` : selectedFileLabel}
                              <IconButton
                                 onClick={() => {
                                    setSelectedFile(null);
                                    setSelectedFileLabel('');
                                 }}>
                                 <Clear fontSize="small" />
                              </IconButton>
                           </span>
                        )}
                     </div>
                  </div>
                  {/* <Divider className="mt-4" flexItem>
                     USER ASSUMPTIONS
                  </Divider> */}
                  <TabContext value={tabIndex}>
                     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                           onChange={(event: React.SyntheticEvent, newValue: string) => {
                              setTabIndex(newValue);
                           }}>
                           <Tab label="Revenue" value="1" />
                           <Tab label="Engineering / Construction" value="2" />
                           <Tab label="Customer" value="3" />
                           <Tab label="Financing" value="4" />
                        </TabList>
                     </Box>
                     <div className="pt-3 pr-1" style={{ height: '55vh', overflowY: 'auto' }}>
                        <TabPanel value="1">
                           <div className="flex column gap">
                              <h4>Pricing / ARPU</h4>

                              <TextField
                                 className="w-100"
                                 label="Inferior ARPU"
                                 type="number"
                                 value={newFinancialModel.inputs.arpu?.inferior ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          arpu: {
                                             ...prevState.inputs.arpu,
                                             inferior: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }))
                                 }
                                 required={true}
                              />
                              <TextField
                                 className="w-100"
                                 label="Value ARPU"
                                 type="number"
                                 value={newFinancialModel.inputs.arpu?.value ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          arpu: {
                                             ...prevState.inputs.arpu,
                                             value: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }))
                                 }
                                 required={true}
                              />
                              <TextField
                                 className="w-100"
                                 label="Direct ARPU"
                                 type="number"
                                 value={newFinancialModel.inputs.arpu?.direct ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          arpu: {
                                             ...prevState.inputs.arpu,
                                             direct: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }))
                                 }
                                 required={true}
                              />
                              <TextField
                                 className="w-100"
                                 label="Superlative ARPU"
                                 type="number"
                                 value={newFinancialModel.inputs.arpu?.superlative ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          arpu: {
                                             ...prevState.inputs.arpu,
                                             superlative: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }))
                                 }
                                 required={true}
                              />
                              <FormControl className="">
                                 <InputLabel id="instance_id" sx={{ background: 'white', paddingRight: '10px' }}>
                                    Gig +? (Y/N)
                                 </InputLabel>
                                 <Select
                                    value={newFinancialModel.inputs.arpu?.include_gigplus ? 'Y' : 'N'}
                                    required
                                    labelId="instance_id"
                                    label="instance_id"
                                    onChange={(event) => {
                                       setNewFinancialModel((prevState) => ({
                                          ...prevState,
                                          inputs: {
                                             ...prevState.inputs,
                                             arpu: {
                                                ...prevState.inputs.arpu,
                                                include_gigplus: event.target.value == 'Y' ? true : false,
                                             },
                                          },
                                       }));
                                    }}>
                                    <MenuItem value={'Y'}>YES</MenuItem>
                                    <MenuItem value={'N'}>NO</MenuItem>
                                 </Select>
                              </FormControl>

                              {newFinancialModel.inputs.arpu?.include_gigplus && (
                                 <TextField
                                    className="w-100"
                                    label="Gig +"
                                    type="text"
                                    value={newFinancialModel.inputs.arpu?.gig ?? ''}
                                    variant="outlined"
                                    onChange={(event) =>
                                       setNewFinancialModel((prevState) => ({
                                          ...prevState,
                                          inputs: {
                                             ...prevState.inputs,
                                             arpu: {
                                                ...prevState.inputs.arpu,
                                                gig: event.target.value !== '' ? Number(event.target.value) : undefined,
                                             },
                                          },
                                       }))
                                    }
                                    required={true}
                                 />
                              )}

                              <TextField
                                 className="w-100"
                                 label="Business Multiplier"
                                 type="number"
                                 value={newFinancialModel.inputs.arpu?.business_multiplier ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          arpu: {
                                             ...prevState.inputs.arpu,
                                             business_multiplier: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }))
                                 }
                                 required={false}
                              />
                              <TextField
                                 className="w-100"
                                 label="Revenue Lag (months)"
                                 type="number"
                                 value={newFinancialModel.inputs.revenue_lag ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;

                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          revenue_lag: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                 }}
                              />
                           </div>
                        </TabPanel>
                        <TabPanel value="2">
                           <div className="flex column gap">
                              <h4>Build</h4>

                              <TextField
                                 className="w-100"
                                 label="Aerial $/mile"
                                 type="number"
                                 value={newFinancialModel.inputs.construction_cost_per_mile_aerial ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          construction_cost_per_mile_aerial: event.target.value !== '' ? Number(event.target.value) : undefined,
                                       },
                                    }))
                                 }
                                 required={true}
                              />
                              <TextField
                                 className="w-100"
                                 label="Underground $/mile"
                                 type="number"
                                 value={newFinancialModel.inputs.construction_cost_per_mile_underground ?? ''}
                                 variant="outlined"
                                 required
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          construction_cost_per_mile_underground: event.target.value !== '' ? Number(event.target.value) : undefined,
                                       },
                                    }))
                                 }
                              />

                              <TextField
                                 className="w-100"
                                 label="Aerial % of mile"
                                 type="number"
                                 value={newFinancialModel.inputs.aerial_pct_fttc ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue > 100 || newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          aerial_pct_fttc: newValue,
                                       },
                                    }));
                                 }}
                                 required={true}
                                 inputProps={{
                                    min: 0,
                                    max: 100,
                                    step: 1,
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Contingency"
                                 type="number"
                                 value={newFinancialModel.inputs.construction_contingency ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          construction_contingency: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Baseline Drop Footage"
                                 type="number"
                                 value={newFinancialModel.inputs.baseline_drop_footage ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          baseline_drop_footage: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Maintenance Capex"
                                 type="number"
                                 value={newFinancialModel.inputs.maintenance_capex ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0 || newValue > 1) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          maintenance_capex: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    max: 1,
                                    step: 0.01,
                                 }}
                              />
                           </div>
                        </TabPanel>
                        <TabPanel value="3">
                           <div className="flex column gap">
                              <h4>Take Rate</h4>
                              <TextField
                                 className="w-100"
                                 label="Take Rate Res. Override"
                                 type="number"
                                 value={newFinancialModel.inputs.take_rate?.residential_override ?? 0}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue > 100 || newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          take_rate: {
                                             ...prevState.inputs.take_rate,
                                             residential_override: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }));
                                 }}
                              />

                              <TextField
                                 className="w-100"
                                 label="Take Rate Bus. Override"
                                 type="number"
                                 value={newFinancialModel.inputs.take_rate?.business_override ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          take_rate: {
                                             ...prevState.inputs.take_rate,
                                             business_override: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }))
                                 }
                              />

                              <TextField
                                 className="w-100"
                                 label="Fiber Points"
                                 type="number"
                                 value={newFinancialModel.inputs.take_rate?.fiber_points ?? ''}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          take_rate: {
                                             ...prevState.inputs.take_rate,
                                             fiber_points: event.target.value !== '' ? Number(event.target.value) : undefined,
                                          },
                                       },
                                    }))
                                 }
                              />
                              <TextField
                                 className="w-100"
                                 label="Period Points"
                                 type="number"
                                 value={newFinancialModel.inputs.internet_penetration?.period_end ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          internet_penetration: {
                                             ...prevState.inputs.internet_penetration,
                                             period_end: newValue,
                                          },
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />

                              <h4>Churn</h4>
                              <TextField
                                 className="w-100"
                                 label="Voluntary Move Own"
                                 type="number"
                                 value={newFinancialModel.inputs.churn?.voluntary_move_own ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0 || newValue > 1) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          churn: {
                                             ...prevState.inputs.churn,
                                             voluntary_move_own: newValue,
                                          },
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    max: 1,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Voluntary Move Rent"
                                 type="number"
                                 value={newFinancialModel.inputs.churn?.voluntary_move_rent ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0 || newValue > 1) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          churn: {
                                             ...prevState.inputs.churn,
                                             voluntary_move_rent: newValue,
                                          },
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    max: 1,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Voluntary Nonmove Baseline"
                                 type="number"
                                 value={newFinancialModel.inputs.churn?.voluntary_nonmove_baseline ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          churn: {
                                             ...prevState.inputs.churn,
                                             voluntary_nonmove_baseline: newValue,
                                          },
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Involuntary Receiving SNAP"
                                 type="number"
                                 value={newFinancialModel.inputs.churn?.involuntary_receiving_snap ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0 || newValue > 1) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          churn: {
                                             ...prevState.inputs.churn,
                                             involuntary_receiving_snap: newValue,
                                          },
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    max: 1,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Involuntary Not Receiving SNAP"
                                 type="number"
                                 value={newFinancialModel.inputs.churn?.involuntary_not_receiving_snap ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0 || newValue > 1) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          churn: {
                                             ...prevState.inputs.churn,
                                             involuntary_not_receiving_snap: newValue,
                                          },
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    max: 1,
                                    step: 'any',
                                 }}
                              />
                              <h4>Aquisition</h4>
                              <TextField
                                 className="w-100"
                                 label="Construction Cost Subscriber Drop"
                                 type="number"
                                 value={newFinancialModel.inputs.construction_cost_subscriber_drop_residential}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          construction_cost_subscriber_drop_residential: event.target.value !== '' ? Number(event.target.value) : undefined,
                                       },
                                    }))
                                 }
                              />
                              <TextField
                                 className="w-100"
                                 label="Construction Cost Subscriber ONT"
                                 type="number"
                                 value={newFinancialModel.inputs.construction_cost_subscriber_ont_residential}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          construction_cost_subscriber_ont_residential: event.target.value !== '' ? Number(event.target.value) : undefined,
                                       },
                                    }))
                                 }
                              />

                              <TextField
                                 className="w-100"
                                 label="Construction Cost Subscriber Install"
                                 type="number"
                                 value={newFinancialModel.inputs.construction_cost_subscriber_install_residential}
                                 variant="outlined"
                                 onChange={(event) =>
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          construction_cost_subscriber_install_residential: event.target.value !== '' ? Number(event.target.value) : undefined,
                                       },
                                    }))
                                 }
                              />
                           </div>
                        </TabPanel>
                        <TabPanel value="4">
                           <div className="flex column gap">
                              <TextField
                                 className="w-100"
                                 label="Discount Rate"
                                 type="number"
                                 value={newFinancialModel.inputs.discount_rate ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          discount_rate: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Tax Rate"
                                 type="number"
                                 value={newFinancialModel.inputs.tax_rate ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          tax_rate: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="ARPU Growth Annual"
                                 type="number"
                                 value={newFinancialModel.inputs.arpu_growth_annual ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          arpu_growth_annual: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Expense Growth Annual"
                                 type="number"
                                 value={newFinancialModel.inputs.expense_growth_annual ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          expense_growth_annual: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                              <TextField
                                 className="w-100"
                                 label="Depreciable Life Years"
                                 type="number"
                                 value={newFinancialModel.inputs.depreciable_life_years ?? ''}
                                 variant="outlined"
                                 onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    if (newValue < 0) return;
                                    setNewFinancialModel((prevState) => ({
                                       ...prevState,
                                       inputs: {
                                          ...prevState.inputs,
                                          depreciable_life_years: newValue,
                                       },
                                    }));
                                 }}
                                 required={false}
                                 inputProps={{
                                    min: 0,
                                    step: 'any',
                                 }}
                              />
                           </div>
                        </TabPanel>
                     </div>
                  </TabContext>
               </div>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Cancel</Button>

               <Tooltip
                  title={getTooltipText()}
                  placement="left-start"
                  onMouseEnter={() => {
                     const requiredFields = document.querySelectorAll('.MuiInputBase-root:has(input[required])');
                     if (requiredFields.length === 0) return;
                     requiredFields.forEach((field: any) => {
                        const value = field.querySelector('input')?.value;
                        if (value) return;

                        field.style.backgroundColor = '#ec646473';
                        field.style.zIndex = '999';
                     });
                  }}
                  onMouseLeave={() => {
                     const requiredFields = document.querySelectorAll('.MuiInputBase-root:has(input[required])');
                     if (requiredFields.length === 0) return;
                     requiredFields.forEach((field: any) => {
                        field.style.backgroundColor = 'unset';
                        field.style.zIndex = 'unset';
                     });
                  }}>
                  <div>
                     <Button
                        type="submit"
                        variant="contained"
                        disabled={
                           loading ||
                           (!props.selectedInstance && !selectedFile) ||
                           newFinancialModel.inputs.arpu?.inferior === undefined ||
                           newFinancialModel.inputs.arpu?.value === undefined ||
                           newFinancialModel.inputs.arpu?.direct === undefined ||
                           newFinancialModel.inputs.arpu?.superlative === undefined ||
                           newFinancialModel.inputs.construction_cost_per_mile_aerial === undefined ||
                           newFinancialModel.inputs.construction_cost_per_mile_underground === undefined ||
                           newFinancialModel.inputs.aerial_pct_fttc === undefined
                        }
                        color="primary">
                        Run
                     </Button>
                  </div>
               </Tooltip>
            </DialogActions>
         </form>
      </Dialog>
   );
}
