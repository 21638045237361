import { GridRenderCellParams } from '@mui/x-data-grid';
import { Account, AccountProject } from '../../../../../models/account.model';

export function RenderProjectAccounts(props: { params: GridRenderCellParams<Account[]>; accounts: Account[] }) {
   return (
      <div className="flex gap-small">
         {props.params.row.accountProjects?.map((accountProject: AccountProject, index: number) => {
            return (
               <span key={index} className="status">
                  {accountProject?.account?.name}
               </span>
            );
         })}
      </div>
   );
}
