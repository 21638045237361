import { Project } from '../../models/project.model';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardContent, Typography } from '@mui/material';
import { getMeta } from '../../helpers/helper';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ProjectStatus } from './ProjectStatus';
import { useState } from 'react';

export function ProjectCard(props: { project: Project }) {
   const navigate = useNavigate();

   // const rows: { key: string; value: number }[] = [
   //    {
   //       key: 'Capex',
   //       value: getMeta(props.project.projectMeta, 'capex'),
   //    },
   //    {
   //       key: 'Revenue',
   //       value: getMeta(props.project.projectMeta, 'revenue'),
   //    },
   //    {
   //       key: 'NPV',
   //       value: getMeta(props.project.projectMeta, 'NPV'),
   //    },
   //    {
   //       key: 'IRR',
   //       value: getMeta(props.project.projectMeta, 'IRR'),
   //    },
   // ];

   return (
      <Card className={`project-card ${!props.project.thumbnail ? 'no-background' : ''}`} sx={{ backgroundImage: `url(${props.project.thumbnail})` }}>
         <CardActionArea
            sx={{
               height: '100%',
               alignItems: 'normal',
            }}
            className="flex column start card-action"
            onClick={() => navigate(`/project/${props.project.id}/`)}>
            <CardContent className="card-content flex column between">
               <div
                  className={`green-section gradient-purple ${props.project.thumbnail ? 'with-image' : 'no-image'}`}
                  style={{
                     backgroundImage: `url(${props.project.thumbnail})`,
                  }}></div>
               <div className="flex between w-100">
                  <h3>{props.project.name}</h3>
               </div>
               {props.project.createdAt && <p className="small-text hidden">{new Date(props.project.createdAt).toLocaleDateString()}</p>}
               {/* <div className="flex gap start">
                  {rows.map(
                     (row, index: number) =>
                        row.value > 0 && (
                           <div key={index} className="project-card-meta flex column gap-small">
                              <span>{row.key}</span>
                              <span>{row.value.toLocaleString()}</span>
                           </div>
                        )
                  )}
               </div> */}
            </CardContent>
         </CardActionArea>
      </Card>
   );
}
