import { Button, TextField, Typography } from '@mui/material';
import { SimpleModal } from '../../../shared/ui/modals/SimpleModal';
import { createProject } from '../../../../services/project.service';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account } from '../../../../models/account.model';
import { useSnackBar } from '../../../../contexts/SnackbarContext';

export function NewProjectModal(props: { open: boolean; onClose: () => void; accounts: Account[] }) {
   const [projectName, setProjectName] = useState('');
   const navigate = useNavigate();
   const snackbar = useSnackBar();

   const handleCreate = async (e: any) => {
      e.preventDefault();
      try {
         const responseProject = await createProject(projectName);
         if (!responseProject.error) {
            snackbar.success('Project successfully created.');
            navigate(`/admin/projects/${responseProject.projectId}`);
         } else {
            snackbar.success("Project couldn't be created.");
         }
      } catch (e) {
         console.log(e);
      }
   };

   return (
      <SimpleModal open={props.open} setOpen={props.onClose}>
         <div className="flex column">
            <div className="flex start">
               <Typography sx={{ mb: 1 }} variant="h6" component="div">
                  New Project
               </Typography>
            </div>
            <div>
               <form onSubmit={handleCreate} className="flex column mt-4 gap">
                  <TextField autoFocus label="Name" variant="outlined" value={projectName} required onChange={(event: any) => setProjectName(event.target.value)} />
                  <div className="flex items-center gap end">
                     <Button variant="text" color="primary" onClick={props.onClose}>
                        CANCEL
                     </Button>

                     <Button type="submit" variant="contained" color="primary">
                        SAVE
                     </Button>
                  </div>
               </form>
            </div>
         </div>
      </SimpleModal>
   );
}
